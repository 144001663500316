import { put, fork, takeLatest } from "redux-saga/effects";
import { GET_CURRENT_USER, GET_CURRENT_USER_SUCCESS, EDIT_CURRENT_USER } from "../actions/currentUserActions";

import { getUserId, setUserLang } from "../../services/localStorage.service";
import history from "../../utils/history";
import axiosClient, { GET, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";

import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";
import { DEFAULT_LANGUAGE } from "../../constants/enums";

const PATH = "/users";

//NOTE: There are 2 ways of setting current user
// If data is passed - user is set for user registration
// If data is not passed - user is set after login
function* getCurrentUser({ data }) {
    try {
        if (data) {
            yield put({ type: GET_CURRENT_USER_SUCCESS, data })
        } else {
            const userId = getUserId();
            if (userId) {
                const url = formatUrl(`${PATH}/${userId}`, { include: "role,avatar,language,currency,notificationTypes", hasActivePackage: true });
                const response = yield axiosClient(GET, url);
                const data = formatResponseData(response.data);
                setUserLang(data?.data?.language?.code || DEFAULT_LANGUAGE)
                yield put({ type: GET_CURRENT_USER_SUCCESS, data });
            }
        }
    } catch (error) {
        yield errorHandler(error);
        localStorage.clear();
        history.push("/login")
    }
};

function* editCurrentUser({ data }) {
    try {
        const preparedData = formatRequestData(ENTITY.USER, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedUser");
        yield getCurrentUser({ data: null });
    } catch (error) {
        yield errorHandler(error);
    }
};

const getCurrentUserAsync = fork(function* () { yield takeLatest(GET_CURRENT_USER, getCurrentUser) });
const editCurrentUserAsync = fork(function* () { yield takeLatest(EDIT_CURRENT_USER, editCurrentUser) });

export default [
    getCurrentUserAsync,
    editCurrentUserAsync
];