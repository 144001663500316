import {
    GET_UNREAD_NOTIFICATIONS,
    GET_UNREAD_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_TYPES,
    GET_NOTIFICATION_TYPES_SUCCESS,
} from "../actions/notificationActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const unreadNotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_UNREAD_NOTIFICATIONS:
            return { data: null, meta: null, isLoading: true };
        case GET_UNREAD_NOTIFICATIONS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return { data: null, meta: null, isLoading: true };
        case GET_NOTIFICATIONS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION:
            return { data: null, meta: null, isLoading: true };
        case GET_NOTIFICATION_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const notificationTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_TYPES:
            return { data: null, meta: null, isLoading: true };
        case GET_NOTIFICATION_TYPES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

export {
    unreadNotificationsReducer,
    notificationsReducer,
    notificationReducer,
    notificationTypesReducer
};