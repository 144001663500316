/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import Button from '../../components/Button';
import { BUTTON_TYPES } from '../../constants/enums';
import ButtonGroup from '../../components/ButtonGroup';
import Backdrop from '../../components/Backdrop';
import Icon from '../../components/Icon';
import { extractValueFromService } from '../../utils/checkValue';
import { calculatePriceWithCurrency } from '../../utils/calculatePrice';

const PackageModal = ({
    open,
    packageOptions,
    selectedPackage,
    setPackage,
    handleCancel,
    handleSubmit,
    submitLabel,
    clickOutside = false
}) => {
    const translate = useTranslate();

    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(selectedPackage);

    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (open && modalRef && modalRef.current && !modalRef.current.contains(event.target)) {
            handleCancel();
        }
    };

    useEffect(() => {
        if (clickOutside) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [clickOutside, handleClickOutside, open]);

    const options = packageOptions.map(option => ({ ...option, priceCurrency: extractValueFromService(option, "price-currency") }));

    if (!selectedPackage) return null;

    return (
        <Fragment>
            <div className={`modal ${open ? 'show' : ''}`}>
                <Backdrop handleClose={handleCancel} />
                <div className="modal-container">
                    <span onClick={handleCancel} className='close'>&times;</span>
                    <div ref={modalRef} className='wrapper modal-special-content'>
                        <ButtonGroup options={options} selectedOption={selectedPackage} setOption={setPackage} />
                        <ul className="addition-sublist">
                            {selectedPackage?.planItems.map(({ data }) => (
                                <li key={data.id}>
                                    <Icon name="check" />
                                    <p className="-regular">{translate(`${data.type}.${data.id}.name`)}</p>
                                </li>
                            ))}
                        </ul>
                        <div className='modal-special-footer'>
                            <span className="title">{translate(`${selectedPackage.type}.${selectedPackage.id}.name`)}</span>
                            <div className="modal-special-footer-container">
                                <span className="currency">{discount ? priceWithDiscount : price}</span>
                                {discount &&
                                    <div className="a-discount-container">
                                        <span className="price">{price}</span>
                                        <span className="a-discount">{`- ${discount}%`}</span>
                                    </div>
                                }
                                <Button
                                    type='submit'
                                    btnClass={BUTTON_TYPES.FILLED}
                                    onClick={() => handleSubmit(selectedPackage)}
                                    label={submitLabel}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

PackageModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitLabel: PropTypes.string.isRequired,
    clickOutside: PropTypes.bool
}

export default PackageModal;