import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import { IMAGE_TYPES } from '../../constants/enums';
import { useRenderRoleLabel } from '../../constants/enums';

const Avatar = ({
    firstName,
    lastName,
    avatarImg,
    role,
}) => {
    return (
        <div className="m-avatar-container">
            <Image firstName={firstName} lastName={lastName} image={avatarImg} imageClass={IMAGE_TYPES.AVATAR} />
            <span className="m-avatar-info">
                <span className="m-avatar-info -name">{`${firstName} ${lastName}`}</span>
                <span className="m-avatar-info -title">{useRenderRoleLabel(role)}</span>
            </span>
        </div>
    )
}

Avatar.propTypes = {
    avatarImg: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Avatar
