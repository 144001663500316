import React from 'react';
import useFetchItemFromApi from './fetchItemFromApi';
import { formatDateTime } from '../../../../../services/moment.service';
import { BASE_URL } from '../../../../../utils/axiosClient';
import ENTITY from '../../../../../constants/entities';
import { useTranslate } from "react-polyglot";
import Icon from '../../../../../components/Icon';

const BookingItem = ({ 
    item, 
    index,
    handleChangeStatus
}) => {
    const translate = useTranslate();
    const { data } = useFetchItemFromApi(
        ENTITY.SERVICE_PLAN,
        item?.relationships?.servicePlan?.data?.id,
        { include: "image" },
        item
    )

    const status = useFetchItemFromApi(
        ENTITY.BOOKING_ITEM_STATUSES,
        item?.relationships?.status?.data?.id,
        {},
        item
    )
    if (!data) return null;

    return (
        <div  className="item-container" key={index}>
            <div className="item">
                <div className="info">
                    <div>
                        <img src={`${BASE_URL}${data.image?.path}`} alt="" className="image"/>
                    </div>
                    <div className="info-container">
                        <span className="name">{data.name}</span>
                        <span className="row">
                            {translate('general.fromDate')} <span className="date-time">{formatDateTime(item.fromDate)}</span>
                        </span>
                        <span className="row">
                            {translate('general.toDate')} <span className="date-time">{formatDateTime(item.toDate)}</span>
                        </span>
                    </div>
                </div>
                <div className="price-container">
                    <span className="price-row">
                        {translate('general.price')} <span className="price">{item.price}</span>
                    </span>
                    <span className="price-row">
                        {translate('general.discount')} <span className="price">{`- ${item.discount}`}</span>
                    </span>
                    <span className="price-discount-row">
                        {translate('general.priceWithDiscount')} <span className="price">{item.priceWithDiscount}</span>
                    </span>
                </div>
            </div>
            <div className="status">
                <span title={translate('table.header.status')}>{status?.data?.name}</span>
                <span className="edit" title={translate('general.edit')} onClick={() => handleChangeStatus(item?.id)}>
                    <Icon name="pencil" />
                </span>
            </div>
        </div>
    );
}

export default BookingItem;
