/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from "react-redux";

import ServicePlanForm from "../ServicePlanForm";

import { getServicePlanAction, editServicePlanAction } from "../../../../redux/actions/serviceActions";
import { getItemSelector, getIsServiceSelector } from "../../../../utils/selectors";

const EditServicePlan = ({
    initialValues,
    getServicePlanAction,
    editServicePlanAction,
    tableParams,
    match,
    history,
    isService
}) => {

    useEffect(() => {
        const { id } = match.params;
        getServicePlanAction(id);
    }, [])

    const onSubmit = (data) => {
        if (data.totalAvailable) {
            data.totalAvailable = Number(data.totalAvailable);
        }
        editServicePlanAction(data, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    if (!initialValues) return null;

    return (
        <ServicePlanForm
            title="general.editServicePlan"
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitLabel="general.editServicePlan"
            handleCancel={handleCancel}
            isService={isService}
        />
    );
};

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.servicePlan),
    isService: getIsServiceSelector(state),
    tableParams: state.tableParams
});

const mapDispatchToProps = {
    getServicePlanAction,
    editServicePlanAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditServicePlan);
