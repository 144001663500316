import React from 'react';
import PropTypes from 'prop-types';
import PackageCard from './PackageCard';

const PackageCards = ({ 
    packages, 
    handleSeeMore, 
    handleBookAPackage, 
    mode
}) => {

    return (
        <ul className="wrap m-list-container">
            {packages.map(item => <PackageCard 
                                        key={item.id} 
                                        packageItem={item} 
                                        handleSeeMore={handleSeeMore} 
                                        handleBookAPackage={handleBookAPackage}
                                        mode={mode} />)}
        </ul>
    )
}

PackageCards.propTypes = {
    packages: PropTypes.array.isRequired,
    handleSeeMore: PropTypes.func,
    handleBookAPackage: PropTypes.func,
    mode: PropTypes.string
}


export default PackageCards
