import React from 'react'
import PropTypes from "prop-types";

import TicketCommentForm from './TicketCommentForm';

const TicketComment = ({ handleSubmit, handleCloseComment }) => {

    return (
        <div className="ticket-middle mv-10">
            <div className="ticket-middle-comments">
                <TicketCommentForm onSubmit={handleSubmit} handleClose={handleCloseComment} />
            </div>
        </div>
    )
}

TicketComment.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCloseComment: PropTypes.func.isRequired
}

export default TicketComment;