import React, { useEffect } from "react";
import { connect } from "react-redux";

import UserForm from "../UserForm";

import { getUserAction, editUserAction } from "../../../../redux/actions/usersActions";
import { getItemSelector } from "../../../../utils/selectors";

const EditUser = ({
    getUserAction,
    editUserAction,
    initialValues,
    tableParams,
    match,
    history
}) => {

    useEffect(() => {
        getUserAction(match.params.id)
    }, [getUserAction, match.params.id]);

    const onSubmit = data => {
        const formData = { ...initialValues, ...data };
        if (formData.gender) {
            formData.gender = Number(formData.gender);
        }
        editUserAction(formData, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    if (!initialValues) return null;

    return (
        <UserForm
            title="general.editUser"
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitLabel="general.editUser"
            handleCancel={handleCancel}
        />
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.user),
    tableParams: state.tableParams
})

const mapDispatchToProps = {
    getUserAction,
    editUserAction
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);