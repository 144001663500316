import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_DOCUMENT_TYPES,
    GET_DOCUMENT_TYPES_SUCCESS,
    GET_DOCUMENT_TYPE,
    GET_DOCUMENT_TYPE_SUCCESS,
    CREATE_DOCUMENT_TYPE,
    EDIT_DOCUMENT_TYPE
} from "../actions/documentTypeActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history";
import errorHandler from "./errorHandler";

import ENTITY from "../../constants/entities";

const PATH = "/document_types";

function* getDocumentTypes({ params }) {
    try {
        const url = formatUrl(PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_DOCUMENT_TYPES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getDocumentType({ id, params }) {
    try {
        const url = formatUrl(`${PATH}/${id}`, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_DOCUMENT_TYPE_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createDocumentType({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.DOCUMENT_TYPES, data);
        yield axiosClient(POST, PATH, preparedData);
        // yield successHandler("notification.message.successCreatedDiscount");
        history.goBack();
        yield getDocumentTypes({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editDocumentType({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.DOCUMENT_TYPES, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        // yield successHandler("notification.message.successEditedDiscount");
        history.goBack();
        yield getDocumentTypes({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

const getDocumentTypesAsync = fork(function* () { yield takeLatest(GET_DOCUMENT_TYPES, getDocumentTypes) });
const getDocumentTypeAsync = fork(function* () { yield takeLatest(GET_DOCUMENT_TYPE, getDocumentType) });
const createDocumentTypeAsync = fork(function* () { yield takeLatest(CREATE_DOCUMENT_TYPE, createDocumentType) });
const editDocumentTypeAsync = fork(function* () { yield takeLatest(EDIT_DOCUMENT_TYPE, editDocumentType) });

export default [
    getDocumentTypesAsync,
    getDocumentTypeAsync,
    createDocumentTypeAsync,
    editDocumentTypeAsync,
];

