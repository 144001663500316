import { SET_ALERT } from "../actions/alertActions"

const alertReducer = (state = null, action) => {
    switch (action.type) {
        case SET_ALERT:
            return action.data;
        default:
            return state;
    }
}

export {
    alertReducer
};