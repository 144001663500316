import React from 'react';
import { connect } from "react-redux";

import { useTranslate } from "react-polyglot";
import Button from '../Button';
import { BUTTON_TYPES } from '../../constants/enums';
import { formatDateTimeServer } from '../../services/moment.service';
import { createBookingAction, removePackageFromShoppingCartAction, removeAdditionFromShoppingCartAction, removeServiceFromShoppingCartAction } from "../../redux/actions/shoppingCartActions";
import { extractValueFromService } from '../../utils/checkValue';
import { calculatePrice, calculatePriceWithCurrency } from '../../utils/calculatePrice';

const getPrice = (packages, additions, services) => {

    let price = 0;
    //NOTE: Need default currency
    let currency = "€";

    packages.forEach(pack => {
        price = Number(price) + calculatePrice(pack);
        currency = extractValueFromService(pack, "currency") || currency;
    });

    additions.forEach(addition => {
        price = Number(price) + calculatePrice(addition, addition.filter);
        currency = extractValueFromService(addition, "currency") || currency;
    });

    services.forEach(service => {
        price = Number(price) + calculatePrice(service);
        currency = extractValueFromService(service, "currency") || currency;
    });
    return `${price} ${currency}`;
}

const AdditionItem = ({ id, item, label, handleRemove }) => {

    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(item, item.filter);

    return (
        <li>
            <p className="-regular item-name">
                <div>
                    <span className="remove" onClick={handleRemove}>x</span>
                    <span className="package-addition">{item?.name}</span>
                </div>
                <span className="currency">{discount ? priceWithDiscount : price}</span>
            </p>
            <p className="-small from">
                <span className="starting-from">{label}</span>
                <span className="date-time">{`${extractValueFromService(item, "datetime")}`}</span>
            </p>
        </li>
    );
}

const ShoppingCartItem = ({ id, item, label, handleRemove }) => {

    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(item);

    return (
        <li>
            <p className="-regular">
                <span className="remove" onClick={handleRemove}>x</span>
                <span className="package-addition">{item?.name}</span>
                <span className="currency">{discount ? priceWithDiscount : price}</span>
            </p>
            <p className="-small from">
                <span className="starting-from">{label}</span>
                <span className="date-time">{`${extractValueFromService(item, "datetime")}`}</span>
            </p>
        </li>
    )
}

const transformData = (inputData) => {
    return {
        id: inputData.id,
        // isFree: true,
        priceListId: inputData.priceListId,
        discountId: inputData.discount?.id,
        fromDate: formatDateTimeServer(inputData.fromDate)
    }
}

const ShoppingCart = ({
    packages,
    additions,
    services,
    createBookingAction,
    removePackageFromShoppingCartAction,
    removeAdditionFromShoppingCartAction,
    removeServiceFromShoppingCartAction
}) => {
    const translate = useTranslate();

    const handleCreateBooking = () => {
        let data = [];
        let packagesData = [];
        let servicesData = [];
        let additionsData = [];

        if (packages.length > 0) {
            packagesData = packages.map(pack => transformData(pack));
        }
        if (services.length > 0) {
            servicesData = services.map(service => transformData(service))
        }
        if (additions.length > 0) {
            additionsData = additions.map(addition => transformData(addition))
        }

        data = [...packagesData, ...servicesData, ...additionsData];
        createBookingAction({ servicePlans: data });
    }

    if (!(packages.length > 0 || additions.length > 0 || services.length > 0)) return null;

    return (
        <div className="m-shopping-cart">
            <div className="content">
                <ul className="m-shopping-cart-list">
                    {packages.map((item, index) => <ShoppingCartItem key={index} item={item} label={translate('general.startingFrom')} handleRemove={() => removePackageFromShoppingCartAction(index)} />)}
                    {services.map((item, index) => <ShoppingCartItem key={index} item={item} label={translate('general.startingFrom')} handleRemove={() => removeServiceFromShoppingCartAction(index)} />)}
                    {additions.map((item, index) => <AdditionItem key={index} item={item} label={translate('general.startingFrom')} handleRemove={() => removeAdditionFromShoppingCartAction(index)} />)}
                </ul>
                <div className="content-action">
                    <span className="currency">{getPrice(packages, additions, services)}</span>
                    <Button label="general.book" btnClass={BUTTON_TYPES.FILLED} onClick={handleCreateBooking} />
                </div>
            </div>
            <div className="description">
                {translate('general.shoppingCartMessage')}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    packages: state.shoppingCart.packages,
    additions: state.shoppingCart.additions,
    services: state.shoppingCart.services
})

const mapDispatchToProps = {
    createBookingAction,
    removePackageFromShoppingCartAction,
    removeAdditionFromShoppingCartAction,
    removeServiceFromShoppingCartAction
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
