/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import moment from "moment";

import CalendarHeader from './CalendarHeader';
import CalendarDayLabels from './CalendarDayLabels';
import CalendarCells from './CalendarCells';
import { CALENDAR_YEARS_PERIOD } from '../../constants/enums';

const Calendar = ({
    size,
    label,
    onChange,
    value,
    defaultDateFormat,
    closeCalendar,
    display,
    yearsPeriod,
    setDateForChecking
}) => {
    const [currentMonth, setCurrentMonth] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    var today = new Date();
    today.setHours(0,0,0,0);
    
    useEffect(() => {
        setCurrentMonth(value ? moment(value) : moment());
        setSelectedDate(value ? moment(value) : moment());
    }, [])

    useEffect(() => {
        if (selectedDate) {
            onChange(selectedDate.format(defaultDateFormat));
        }
    }, [selectedDate]);

    const handleSelectDate = date => {
        date.set({
            hour: selectedDate.get('hour'),
            minute: selectedDate.get('minute')
        })
        setSelectedDate(date.startOf(date));
        date.startOf(date) >= today ? setDateForChecking(date.startOf(date)) : setDateForChecking("");
    }

    
    if (closeCalendar) {
        closeCalendar();
    }
    
    if (
        !display ||
        !currentMonth ||
        !selectedDate
    ) return null;


    return (
        <div className={`m-calendar -${size}`}>
            <CalendarHeader
                size={size}
                label={label}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                selectedDate={selectedDate}
                yearsPeriod={yearsPeriod}
            />
            <CalendarDayLabels
                size={size}
                currentMonth={currentMonth} />
            <CalendarCells
                currentMonth={currentMonth}
                selectedDate={selectedDate}
                setSelectedDate={handleSelectDate}
            />
        </div>
    )
}

Calendar.propTypes = {
    size: PropTypes.oneOf(['big', 'small']),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    defaultDateFormat: PropTypes.string,
    closeCalendar: PropTypes.func,
    display: PropTypes.bool,
    yearsPeriod: PropTypes.string
}

Calendar.defaultProps = {
    size: "small",
    display: true,
    yearsPeriod: CALENDAR_YEARS_PERIOD.NEXT
}

export default Calendar;
