/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import axiosClient, { GET } from '../../../utils/axiosClient';
import { formatResponseData, formatUrl } from '../../../utils/jsonApiHelpers';


/**
 * Custom Hook for SelectRelationship and MultipleRelationship components
 *
 * @param {string} entityType Represents path for api.
 * @param {object} params Must contain page property!
 * @param {bool} multiselect Flag for SelectMultipleFieldRelationship onScorll functionality.
 * @return {obj} Returns object that contains options and isLoading flag 
 */
const useFetchOptionsFromApi = (entityType, params = {}, multiselect = false) => {

    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { page, itemsPerPage, search } = params;

    // Custom hook that fetches data from api on every page change
    // NOTE:: This hook is used for SelectMultipleFieldRelationships onMount and onScroll event,
    // For SelectFieldRelationship, just pass { page: 1 }, and bigger itemsPerPage property 
    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const PATH = `/${entityType}`;
                const url = formatUrl(PATH, params);
                const response = await axiosClient(GET, url);
                const { data } = formatResponseData(response.data);
                // Spread old loaded data with new for multiselect
                // if (multiselect) {
                //     setOptions(options => [...options, ...data]);
                // } else {
                //     setOptions(data);
                // }

                // if (page >= 1 && data.length < itemsPerPage) {
                //     setIsLoading(false);
                // }

                if (page === 1) {
                    setOptions(data)
                } else {
                    setOptions([...options, ...data])
                }
                if (data.length < itemsPerPage) {
                    setIsLoading(false)
                }

            } catch (error) {
                setOptions([]);
                setIsLoading(false);
            }
        }
        getData();
    }, [page, search])

    // Clean options on entityType change
    useEffect(() => {
        return setOptions([]);
    }, [entityType])

    return { options, isLoading };
}

export default useFetchOptionsFromApi
