export const GET_SERVICE_PLAN_ITEMS = '@SRV/GET_SERVICE_PLAN_ITEMS';
export const GET_SERVICE_PLAN_ITEMS_SUCCESS = '@SRV/GET_SERVICE_PLAN_ITEMS_SUCCESS';
export const getServicePlanItemsAction = params => ({
    type: GET_SERVICE_PLAN_ITEMS,
    params
});

export const GET_SERVICE_PLAN_ITEM = '@SRV/GET_SERVICE_PLAN_ITEM';
export const GET_SERVICE_PLAN_ITEM_SUCCESS = '@SRV/GET_SERVICE_PLAN_ITEM_SUCCESS';
export const getServicePlanItemAction = id => ({
    type: GET_SERVICE_PLAN_ITEM,
    id
})

export const CREATE_SERVICE_PLAN_ITEM = '@SRV/CREATE_SERVICE_PLAN_ITEM';
export const createServicePlanItemAction = (data, params) => ({
    type: CREATE_SERVICE_PLAN_ITEM,
    data,
    params
});

export const EDIT_SERVICE_PLAN_ITEM = '@SRV/EDIT_SERVICE_PLAN_ITEM';
export const editServicePlanItemAction = (data, params) => ({
    type: EDIT_SERVICE_PLAN_ITEM,
    data,
    params
});

export const GET_SERVICE_PLANS = '@SRV/GET_SERVICE_PLANS';
export const GET_SERVICE_PLANS_SUCCESS = '@SRV/GET_SERVICE_PLANS_SUCCESS';
export const getServicePlansAction = params => ({
    type: GET_SERVICE_PLANS,
    params
});

export const GET_SERVICE_PLAN = '@SRV/GET_SERVICE_PLAN';
export const GET_SERVICE_PLAN_SUCCESS = '@SRV/GET_SERVICE_PLAN_SUCCESS';
export const getServicePlanAction = id => ({
    type: GET_SERVICE_PLAN,
    id
})

export const CREATE_SERVICE_PLAN = '@SRV/CREATE_SERVICE_PLAN';
export const createServicePlanAction = (data, params) => ({
    type: CREATE_SERVICE_PLAN,
    data,
    params
});

export const EDIT_SERVICE_PLAN = '@SRV/EDIT_SERVICE_PLAN';
export const editServicePlanAction = (data, params) => ({
    type: EDIT_SERVICE_PLAN,
    data,
    params
});

export const GET_SERVICE_PLAN_LISTS = '@SRV/GET_SERVICE_PLAN_LISTS';
export const GET_SERVICE_PLAN_LISTS_SUCCESS = '@SRV/GET_SERVICE_PLAN_LISTS_SUCCESS';
export const getServicePlanListsAction = params => ({
    type: GET_SERVICE_PLAN_LISTS,
    params
});

export const GET_SERVICE_PLAN_LIST = '@SRV/GET_SERVICE_PLAN_LIST';
export const GET_SERVICE_PLAN_LIST_SUCCESS = '@SRV/GET_SERVICE_PLAN_LIST_SUCCESS';
export const getServicePlanListAction = (id, params) => ({
    type: GET_SERVICE_PLAN_LIST,
    id,
    params
})

export const RESET_SERVICE_PLAN_LISTS = '@SRV/RESET_SERVICE_PLAN_LISTS';
export const resetServicePlanListsAction = () => ({
    type: RESET_SERVICE_PLAN_LISTS
});

export const RESET_SERVICE_PLAN_LIST = '@SRV/RESET_SERVICE_PLAN_LIST';
export const resetServicePlanListAction = () => ({
    type: RESET_SERVICE_PLAN_LIST
});