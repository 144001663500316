import React from 'react'
import PropTypes from 'prop-types';
import Icon from '../Icon';

const ACTIONS = [
    {
        label: 'Action 1',
        icon: <Icon name="users" />,
        action: () => console.log('action 1')
    },
];

const ActionTab = ({
    display,
    actions = ACTIONS
}) => {
    return (
        <div className={`table-action-bar ${display ? 'show' : 'hide'}`}>
            {
                actions.map((item, index) =>
                    <div
                        key={index}
                        className="table-action-bar-item"
                        onClick={item.action}
                    >
                        {item.icon}
                    </div>
                )
            }
        </div>
    )
};

ActionTab.propTypes = {
    display: PropTypes.bool.isRequired,
    actions: PropTypes.array
};

export default ActionTab
