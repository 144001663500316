/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslate } from "react-polyglot";

import dropdownHOC from '../../../wrappers/dropdownHOC';
import Avatar from '../../../Avatar';
import Icon from '../../../Icon';

const AvatarDropdown = ({
    avatarImg,
    firstName,
    lastName,
    roleName,
    open,
    handleLogout
}) => {

    const translate = useTranslate();

    return (
        <div className="m-avatar-container">
            <Avatar firstName={firstName} lastName={lastName} avatarImg={avatarImg} role={roleName} />
            <i className={`m-avatar-chevron ${open ? "-up" : "-down"}`} />
            <div className={`m-avatar-content ${open ? "-active" : ""}`}>
                <Link className="m-avatar-content-item" to="/user-profile"><Icon name="user-profile" />{translate('general.userProfile')}</Link>
                <Link className="m-avatar-content-item" to="#" onClick={handleLogout}><Icon name="logout" />{translate('general.logout')}</Link>
            </div>

        </div >
    )
}

AvatarDropdown.propTypes = {
    avatarImg: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired,
    open: PropTypes.bool,
    handleLogout: PropTypes.func
}

export default dropdownHOC(AvatarDropdown);