import React from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";
import UserProfileForm from "./UserProfileForm";

import { editCurrentUser } from "../../redux/actions/currentUserActions";
import { getItemSelector } from "../../utils/selectors";

const UserProfile = ({
    currentUser,
    editCurrentUser
}) => {
    const translate = useTranslate();

    const onSubmit = data => {
        data.gender = Number(data.gender);
        editCurrentUser(data);
    }

    return (
        <div>
            <h1>{translate("general.userProfile")}</h1>
            <UserProfileForm
                initialValues={currentUser}
                onSubmit={onSubmit}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    currentUser: getItemSelector(state.currentUser)
})

const mapDispatchToProps = {
    editCurrentUser
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);