import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

const Select = ({
    label,
    name,
    value,
    onChange,
    options,
    readOnly,
    defaultValue,
    required
}) => {
    const translate = useTranslate();

    if (!options) return null;

    if (!required) {
        options = [{ value: '', label: '' }].concat(options)
    }

    // TODO implement basic and cusom select classes
    return (
        <Fragment>
            {label && <label htmlFor={name}>{translate(label)}</label>}
            <select
                className="select"
                name={name}
                value={value || defaultValue}
                disabled={readOnly}
                onChange={onChange}
            >
                {options.map(({ value, label }) => (
                    <option
                        className="select-option"
                        key={value}
                        value={value}
                    >
                        {label}
                    </option>
                ))}
            </select>
        </Fragment>
    )
};

Select.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ])
}

export default Select;