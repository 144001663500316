/* eslint-disable react-hooks/rules-of-hooks */
import { useTranslate } from 'react-polyglot';

// NOTE: This must be synced with backend
const ROLES = {
    GUEST: {
        ID: 1,
        NAME: "ROLE_GUEST"
    },
    USER: {
        ID: 2,
        NAME: "ROLE_USER"
    },
    MANAGER: {
        ID: 3,
        NAME: "ROLE_MANAGER"
    },
    ADMIN: {
        ID: 4,
        NAME: "ROLE_ADMIN"
    },
};

// NOTE: This must be synced with backend
const TICKET_STATUSES = {
    "OPEN": 1,
    "CLOSED": 2,
    "IN_PROGRESS": 3
}

// NOTE: This must be synced with backend
const SERVICE_TYPES = {
    "PACKAGE": 1,
    "SERVICE": 2,
    "ADDITION": 3
}

// NOTE: This must be synced with backend
const PRICE_TYPES = {
    "PER_HOUR": 1,
    "PER_DAY": 2,
    "PER_MONTH": 3
}

// NOTE: This must be synced with backend
const DISCOUNT_TYPES = {
    "USER": 1,
    "SERVICE": 2
}

// NOTE: This must be synced with backend
const APP_SETTING_KEYS = {
    "DEFAULT_CURRENCY": 1,
    "EXCHANGE_RATE": 2,
    "LIMIT_FOR_CANCEL": 3,
    "DEFAULT_LANGUAGE": 4
}

// NOTE: This must be synced with backend
const BOOKING_ITEM_STATUSES = {
    "PENDING": 1,
    "IN_PROGRESS": 2,
    "COMPLETE": 3,
    "CANCELED": 4
}

// NOTE: This must be synced with backend
const EVENT_ANSWERS = {
    "YES": "Yes",
    "NO": "No",
    "MAYBE": "Maybe"
}

const SORT_TYPES = {
    ASC: 1,
    DESC: -1
}

const ALERT_TYPES = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
};

const BUTTON_TYPES = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    FILLED: 'filled',
    DISABLED: 'disabled'
};

const BUTTON_SIZE = {
    REGULAR: 'regular',
    SMALL: 'small'
};

const BUTTON_ICON_POSITIONS = {
    LEFT: "left",
    RIGHT: "right",
    CENTER: "center"
};

const IMAGE_TYPES = {
    REGULAR: "image",
    AVATAR: "avatar",
    USER_UPLOAD: "user",
    GUEST: "guest"
};

const FILE_TYPES = {
    IMAGE: "image",
    FILE: "file"
};

const INPUT_MODE = {
    DARK: "dark",
    LIGHT: "light"
};

const CALENDAR_YEARS_PERIOD = {
    PREVIOUS: 'previous',
    NEXT: 'next'
}

const DEFAULT_LANGUAGE = "en";

// NOTE:: roleIdentifier can be role id or ROLE_NAME, both comes from BE
const useRenderRoleLabel = (roleIdentifier) => {
    const translate = useTranslate();
    switch (roleIdentifier) {
        case ROLES.GUEST.ID:
        case ROLES.GUEST.NAME:
            return translate('role.guest');
        case ROLES.USER.ID:
        case ROLES.USER.NAME:
            return translate('role.user');
        case ROLES.MANAGER.ID:
        case ROLES.MANAGER.NAME:
            return translate('role.manager');
        case ROLES.ADMIN.ID:
        case ROLES.ADMIN.NAME:
            return translate('role.admin');
        default:
            return ""
    }
};

export {
    ROLES,
    TICKET_STATUSES,
    SERVICE_TYPES,
    PRICE_TYPES,
    DISCOUNT_TYPES,
    APP_SETTING_KEYS,
    BOOKING_ITEM_STATUSES,
    EVENT_ANSWERS,
    SORT_TYPES,
    ALERT_TYPES,
    BUTTON_TYPES,
    BUTTON_SIZE,
    BUTTON_ICON_POSITIONS,
    CALENDAR_YEARS_PERIOD,
    DEFAULT_LANGUAGE,
    IMAGE_TYPES,
    FILE_TYPES,
    INPUT_MODE,
    useRenderRoleLabel
};