import { put } from 'redux-saga/effects';
import { SET_ALERT } from '../actions/alertActions';
import { ALERT_TYPES } from '../../constants/enums';

function* successHandler(title, message) {
    yield put({
        type: SET_ALERT,
        data: {
            type: ALERT_TYPES.SUCCESS,
            title,
            message,
            shouldTranslate: true
        }
    });
}

export default successHandler;
