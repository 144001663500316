import React from "react"
import { Field, reduxForm } from "redux-form";

import ModalForm from "../../../../components/ModalForm";
import InputField from "../../../../components/formFields/InputField";

import { required } from "../../../../utils/validation";

const DocumentTypeForm = ({
    title,
    handleSubmit,
    onSubmit,
    submitLabel,
    handleCancel,
}) => {
    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
        >
            <div className="wrap">
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.name"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="description"
                        label="form.fieldLabel.description"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
            </div>
        </ModalForm>
    )
}

export default reduxForm({
    form: "DocumentTypeForm",
})(DocumentTypeForm)
