import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getNotificationsAction, editNotificationAction, getUnreadNotificationsAction, getNotificationAction } from "../../redux/actions/notificationActions";
import { getItemSelector, getListSelector, getTotalItemsSelector } from "../../utils/selectors";

import Table from "../../components/Table";
import { ROLES } from "../../constants/enums";
import { hitsNotificationAction } from "../../utils/hitsNotificationAction";
import history from "../../utils/history";
import axiosClient, { GET } from "../../utils/axiosClient";
import errorHandler from "../../redux/saga/errorHandler";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
        sortable: false
    },
    {
        key: "creator.email",
        title: "table.header.sender",
    },
    {
        key: "message",
        title: "table.header.message",
    },
    {
        key: "created",
        title: "table.header.dateCreated",
        valueType: "notice-datetime"
    },
    {
        key: "seen",
        title: "table.header.seen",
        valueType: "boolean"
    }
];

// TODO: Implement pagination throught query params
// Get data after every delete and edit user
const Notifications = ({
    getNotificationsAction,
    editNotificationAction,
    getUnreadNotificationsAction,
    tableParams,
    isLoading,
    data,
    totalItems,
    currentUser
}) => {

    const urlString = history.location.pathname.split('/');

    useEffect(() => {
        if(urlString.length === 3 && urlString[2] !== '') {
            isNotificationFromEmail();
        }
    }, [])

    const isNotificationFromEmail = async() => {
        try{
            const response = await axiosClient(GET, `/notifications/${Number(urlString[2])}`, Number(urlString[2]))
            const notificationFromEmail = response.data.data.attributes;
            handleMarkNotificationAsSeen(Number(urlString[2]), notificationFromEmail)
        }catch(error){
            errorHandler(error);
        }     
    }

    const handleMarkNotificationAsSeen = (id, notificationFromEmail) => {
        
        const notification = notificationFromEmail || data.find(item => item.id === id);
        editNotificationAction({ ...notification, seen: true }, {...tableParams, "recipient.id": currentUser.id});
        setTimeout(() => getUnreadNotificationsAction({ "recipient.id": currentUser.id, seen: false }), 500);

        const { action } = notification;
        const isUser = currentUser?.roleName === ROLES.USER.NAME;
        setTimeout(() => {
            hitsNotificationAction(action, isUser);
        }, 1000);

    }

    const handleGetNotifications = (params) => {
        getNotificationsAction({ "recipient.id": currentUser.id, ...params });
    }

    if(urlString.length === 3 && urlString[2] !== '') {
        return null
    }

    return (
        <div>
            <Table
                title="general.notifications"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={handleGetNotifications}
                actions={{
                    viewAction: handleMarkNotificationAsSeen,
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.notifications.isLoading,
    data: getListSelector(state.notifications),
    totalItems: getTotalItemsSelector(state.notifications),
    tableParams: state.tableParams,
    currentUser: getItemSelector(state.currentUser)
});

const mapDispatchToProps = {
    getNotificationsAction,
    editNotificationAction,
    getUnreadNotificationsAction,
    getNotificationAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)