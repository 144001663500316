import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USER,
    GET_USER_SUCCESS,
    GET_USERS_DOCUMENTS,
    GET_USERS_DOCUMENTS_SUCCESS,
    GET_USER_INVOICES,
    GET_USER_INVOICES_SUCCESS
} from "../actions/usersActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return { data: null, meta: null, isLoading: true };
        case GET_USERS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER:
            return { data: null, meta: null, isLoading: true };
        case GET_USER_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const usersDocumentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_DOCUMENTS:
            return { data: null, meta: null, isLoading: true };
        case GET_USERS_DOCUMENTS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const userInvoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_INVOICES:
            return { data: null, meta: null, isLoading: true };
        case GET_USER_INVOICES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

export {
    usersReducer,
    userReducer,
    usersDocumentsReducer,
    userInvoicesReducer
};