import { GET_TICKETS, GET_TICKETS_SUCCESS, GET_TICKET, GET_TICKET_SUCCESS } from "../actions/ticketsActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const ticketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TICKETS:
            return { data: null, meta: null, isLoading: true };
        case GET_TICKETS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const ticketReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TICKET:
            return { data: null, meta: null, isLoading: true };
        case GET_TICKET_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

export {
    ticketsReducer,
    ticketReducer,
};