import { GET_LANGUAGES, GET_LANGUAGES_SUCCESS, SET_TRANSLATION_ASYNC } from "../actions/translationActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
}

//TODO: Think about other solutions
const initialTranslation = require("../../translations/en.json");

const languagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LANGUAGES:
            return { data: null, meta: null, isLoading: true };
        case GET_LANGUAGES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
}

const translationReducer = (state = initialTranslation, action) => {
    switch (action.type) {
        case SET_TRANSLATION_ASYNC:
            return action.data;
        default:
            return state;
    }
}

export { languagesReducer, translationReducer };