import React from "react";
import { Field, reduxForm } from "redux-form";
import { useTranslate } from "react-polyglot";

import ModalForm from "../../../../components/ModalForm";
import InputField from "../../../../components/formFields/InputField";
import InputDate from "../../../../components/formFields/InputDate";
import CheckboxField from "../../../../components/formFields/CheckboxField";
import SelectFieldRelationship from "../../../../components/formFields/SelectFieldRelationship";
import SelectMultipleFieldRelationship from "../../../../components/formFields/SelectMultipleFieldRelationship";
import RadioGroupRelationship from "../../../../components/formFields/RadioGroupRelationship";

import { required, isPhoneNumber, isValidEmail } from "../../../../utils/validation";
import { CALENDAR_YEARS_PERIOD, DISCOUNT_TYPES } from "../../../../constants/enums";
import ENTITY from "../../../../constants/entities";

const UserForm = ({
    title,
    handleSubmit,
    handleCancel,
    onSubmit,
    submitLabel,
    change,
}) => {
    const translate = useTranslate();
    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
        >
            <div className="wrap">
                <div className="_m12 mv-10">
                    <Field
                        name="active"
                        label="form.fieldLabel.active"
                        component={CheckboxField}
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="role.id"
                        label="form.fieldLabel.role"
                        entityType={ENTITY.ROLE}
                        onSelect={change}
                        component={SelectFieldRelationship}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="language.id"
                        label="form.fieldLabel.language"
                        entityType={ENTITY.LANGUAGE}
                        onSelect={change}
                        component={SelectFieldRelationship}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="firstName"
                        label="form.fieldLabel.firstName"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="lastName"
                        label="form.fieldLabel.lastName"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="email"
                        name="email"
                        label="form.fieldLabel.email"
                        validate={[required, isValidEmail]}
                        component={InputField}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="tel"
                        name="phoneNumber"
                        label="form.fieldLabel.phoneNumber"
                        validate={[required, isPhoneNumber]}
                        component={InputField}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="birthDate"
                        label="form.fieldLabel.birthDate"
                        component={InputDate}
                        yearsPeriod={CALENDAR_YEARS_PERIOD.PREVIOUS}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="gender"
                        label="form.fieldLabel.gender"
                        entityType={ENTITY.USER_GENDER}
                        component={RadioGroupRelationship}
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="education"
                        label="form.fieldLabel.education"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="profession"
                        label="form.fieldLabel.profession"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="notificationTypes"
                        label="form.fieldLabel.notificationTypes"
                        onSelect={change}
                        entityType={ENTITY.NOTIFICATION_TYPES}
                        component={SelectMultipleFieldRelationship}
                    />
                </div>
            </div>
            <hr className="horizontal-line" />
            <h3>{translate("general.userPriceManagement")}</h3>
            <div className="wrap _aic">
                <div className="_m6 mv-10">
                    <Field
                        name="currency.id"
                        label="form.fieldLabel.currency"
                        entityType={ENTITY.CURRENCY}
                        onSelect={change}
                        component={SelectFieldRelationship}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="discounts"
                        label="form.fieldLabel.usersDiscounts"
                        onSelect={change}
                        entityType={ENTITY.DISCOUNT}
                        component={SelectMultipleFieldRelationship}
                        params={{ discountType: DISCOUNT_TYPES.USER }}
                    />
                </div>
            </div>

        </ModalForm>
    )
}

export default reduxForm({
    form: "UserForm",
    enableReinitialize: true
})(UserForm)