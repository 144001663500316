export const GET_TICKETS = "@TCK/GET_TICKETS";
export const GET_TICKETS_SUCCESS = "@TCK/GET_TICKETS_SUCCESS";
export const getTicketsAction = params => ({
    type: GET_TICKETS,
    params
});

export const GET_TICKET = "@TCK/GET_TICKET";
export const GET_TICKET_SUCCESS = "@TCK/GET_TICKET_SUCCESS";
export const getTicketAction = id => ({
    type: GET_TICKET,
    id
});

export const CREATE_TICKET = "@TCK/CREATE_TICKET";
export const createTicketAction = (data, params) => ({
    type: CREATE_TICKET,
    data,
    params
});

export const EDIT_TICKET = "@TCK/EDIT_TICKET";
export const editTicketAction = (data, params) => ({
    type: EDIT_TICKET,
    data,
    params
});

export const CREATE_TICKET_COMMENT = "@TCK/CREATE_TICKET_COMMENT";
export const createTicketCommentAction = (id, data, params) => ({
    type: CREATE_TICKET_COMMENT,
    id,
    data,
    params
});