/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";
import { getListSelector, getItemSelector, getIsLoading } from "../../utils/selectors";
import { getServicePlanListsAction, resetServicePlanListsAction } from "../../redux/actions/serviceActions";
import { SERVICE_TYPES } from '../../constants/enums';
import Loader from '../../components/Loader';
import ServiceCards from './ServiceCards';
import NoActivePackage from '../../components/NoActivePackage';
import NoContent from '../../components/NoContent';

export const ServiceCatalog = ({ 
    services, 
    getServicePlanListsAction, 
    resetServicePlanListsAction,
    isLoading,
    currentUser,
    history, 
    match }) => {
    const translate = useTranslate();

    const { language, hasActivePackage } = currentUser;

    useEffect(() => {
        if (hasActivePackage) {
            getServicePlanListsAction({include: 'userCurrency,image,discount,relatedServicePlans', planType: SERVICE_TYPES.SERVICE});
        }
        return () => {
            resetServicePlanListsAction();
        }
    }, [language.code])

    const handleBookAService = (data) => {
        history.push(`${match.url}/book-a-service/${data.id}/${data?.priceLists[0]?.data?.id}`);
    }

    if (!hasActivePackage) return (
        <div>
            <div className="content-container-header">
                <div className="title">
                    <h1>{translate("general.menuItems.services")}</h1>
                    <p className="-regular">{translate('general.servicesTitle')}</p>
                </div>
            </div>
            <NoActivePackage label="general.noActivePackagesServicesDescription" history={history}/>
        </div>
    );

    if (isLoading) return <Loader />;

    return (
        <Fragment>
            <div>
                <div className="wrap">
                    <div className="_12 content-container-header">
                        <div className="title">
                            <h1>{translate("general.menuItems.services")}</h1>
                            <p className="-regular">{translate('general.servicesTitle')}</p>
                        </div>
                    </div>
                </div>
                { services.length > 0 ?
                    <ServiceCards 
                        services={services}
                        handleBookAService={handleBookAService} /> :
                    <NoContent label='general.noServices' />
                }
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    services: getListSelector(state.servicePlanLists),
    isLoading: getIsLoading(state.servicePlanLists),
    currentUser: getItemSelector(state.currentUser)
})

const mapDispatchToProps = {
    getServicePlanListsAction,
    resetServicePlanListsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCatalog)
