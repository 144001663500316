import React from "react";
import { Field, reduxForm } from "redux-form";

import InputField from "../../components/formFields/InputField";
import InputDate from "../../components/formFields/InputDate";
import RadioGroupRelationship from "../../components/formFields/RadioGroupRelationship";
import ImageUpload from "../../components/formFields/ImageUpload";
import SelectMultipleFieldRelationshipHoc from "../../components/formFields/SelectMultipleFieldRelationship";
import Button from "../../components/Button";
import { BUTTON_TYPES, CALENDAR_YEARS_PERIOD } from "../../constants/enums";
import { required, isPhoneNumber } from "../../utils/validation";
import { IMAGE_TYPES } from '../../constants/enums';
import ENTITY from "../../constants/entities";

const UserProfileForm = ({ handleSubmit, onSubmit, initialValues, change }) => {

    if (!initialValues) return null;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap mt-25">
                <div className="_xl2 _l3 _m3 _s4 _xs12 mv-10">
                    <ImageUpload
                        image={initialValues?.avatar?.path}
                        onImageChange={change}
                        firstName={initialValues.firstName}
                        lastName={initialValues.lastName}
                        imageType={IMAGE_TYPES.AVATAR}
                        imageClass={IMAGE_TYPES.USER_UPLOAD}
                    />
                </div>
                <div className="_xl10 _l9 _m9 _s8 _xs12">
                    <div className="wrap _aic">
                        <div className="_l6 _12 mv-10">
                            <Field
                                type="text"
                                name="firstName"
                                label="form.fieldLabel.firstName"
                                validate={required}
                                component={InputField}
                            />
                        </div>
                        <div className="_l6 _12 mv-10">
                            <Field
                                type="text"
                                name="lastName"
                                label="form.fieldLabel.lastName"
                                validate={required}
                                component={InputField}
                            />
                        </div>
                        <div className="_l6 _12 mv-10">
                            <Field
                                name="birthDate"
                                label="form.fieldLabel.birthDate"
                                validate={required}
                                yearsPeriod={CALENDAR_YEARS_PERIOD.PREVIOUS}
                                component={InputDate}
                            />
                        </div>
                        <div className="_l6 _12 mv-10">
                            <Field
                                name="gender"
                                label="form.fieldLabel.gender"
                                entityType={ENTITY.USER_GENDER}
                                component={RadioGroupRelationship}
                            />
                        </div>
                        <div className="_l6 _12 mv-10">
                            <Field
                                type="text"
                                name="education"
                                label="form.fieldLabel.education"
                                validate={required}
                                component={InputField}
                            />
                        </div>
                        <div className="_l6 _12 mv-10">
                            <Field
                                type="text"
                                name="profession"
                                label="form.fieldLabel.profession"
                                validate={required}
                                component={InputField}
                            />
                        </div>
                        <div className="_l6 _12 mv-10">
                            <Field
                                type="tel"
                                name="phoneNumber"
                                label="form.fieldLabel.phoneNumber"
                                validate={isPhoneNumber}
                                component={InputField}
                            />
                        </div>
                        <div className="_l6 _12 mv-10">
                            <Field
                                type="text"
                                name="websiteLink"
                                label="form.fieldLabel.websiteLink"
                                component={InputField}
                            />
                        </div>
                        <div className="_l6 _12 mv-10">
                            <Field
                                name="notificationTypes"
                                label="form.fieldLabel.notificationTypes"
                                onSelect={change}
                                entityType={ENTITY.NOTIFICATION_TYPES}
                                component={SelectMultipleFieldRelationshipHoc}
                            />
                        </div>
                        <div className="_m12 mv-10" style={{ textAlign: "right" }}>
                            <Button
                                type="submit"
                                btnClass={BUTTON_TYPES.FILLED}
                                label="form.button.updateProfile"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: "UserProfileForm"
})(UserProfileForm)