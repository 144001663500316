import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_BOOKINGS,
    GET_BOOKINGS_SUCCESS,
    GET_BOOKING,
    GET_BOOKING_SUCCESS,
    CREATE_BOOKING,
    CREATE_BOOKING_SUCCESS,
    CREATE_BOOKING_FAIL,
    GET_BOOKING_STATUSES,
    GET_BOOKING_STATUSES_SUCCESS,
    GET_BOOKING_ITEMS,
    GET_BOOKING_ITEMS_SUCCESS,
    GET_BOOKING_ITEM,
    GET_BOOKING_ITEM_SUCCESS,
    CHANGE_BOOKING_ITEM_STATUS
} from "../actions/bookingsActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history"
import successHandler from "./successHandler";
import errorHandler from "./errorHandler";

import ENTITY from "../../constants/entities";

const PATH = "/booking_list";
const BOOKING_PATH = "/bookings"
const BOOKING_ITEMS_PATH = "/booking_items";
const BOOKING_ITEM_STATUSES_PATH = "/booking_item_statuses";

function* getBookings({ params }) {
    try {
        const url = formatUrl(PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_BOOKINGS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getBooking({ id, params }) {
    try {
        const url = formatUrl(`${BOOKING_PATH}/${id}`, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_BOOKING_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createBooking({ data, params }) {
    try {
        yield axiosClient(POST, BOOKING_PATH, data);
        yield setTimeout(() => {
            put({ type: CREATE_BOOKING_SUCCESS });
            history.goBack();
        }, 2000);
        yield successHandler("notification.message.successCreateBooking");
    } catch (error) {
        yield put({ type: CREATE_BOOKING_FAIL });
        yield errorHandler(error);
    }
};


//NOTE:: This will probablly go through hook 
function* getBookingStatuses({ params }) {
    try {
        const url = formatUrl(BOOKING_ITEM_STATUSES_PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data)
        yield put({ type: GET_BOOKING_STATUSES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

export function* getBookingItems({ params }) {
    try {
        const url = formatUrl(BOOKING_ITEMS_PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_BOOKING_ITEMS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getBookingItem({ id, params }) {
    try {
        const url = formatUrl(`${BOOKING_ITEMS_PATH}/${id}`, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_BOOKING_ITEM_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* changeBookingItemStatus({ id, data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.BOOKING_ITEM, data);
        yield axiosClient(PATCH, `${BOOKING_ITEMS_PATH}/${data.id}`, preparedData);
        yield successHandler("notification.message.successEditedServiceItem");
        history.goBack();
        yield getBooking({ id, params: {include: "user,bookingItems"} });
    } catch (error) {
        yield errorHandler(error);
    }
};

const getBookingsAsync = fork(function* () { yield takeLatest(GET_BOOKINGS, getBookings) });
const getBookingAsync = fork(function* () { yield takeLatest(GET_BOOKING, getBooking) });
const createBookingAsync = fork(function* () { yield takeLatest(CREATE_BOOKING, createBooking) });
const getBookingStatusesAsync = fork(function* () { yield takeLatest(GET_BOOKING_STATUSES, getBookingStatuses) });
const getBookingItemsAsync = fork(function* () { yield takeLatest(GET_BOOKING_ITEMS, getBookingItems) });
const getBookingItemAsync = fork(function* () { yield takeLatest(GET_BOOKING_ITEM, getBookingItem) });
const changeBookingItemStatusAsync = fork(function* () { yield takeLatest(CHANGE_BOOKING_ITEM_STATUS, changeBookingItemStatus) });

export default [
    getBookingsAsync,
    getBookingAsync,
    createBookingAsync,
    getBookingStatusesAsync,
    getBookingItemsAsync,
    getBookingItemAsync,
    changeBookingItemStatusAsync
];