import React, { useEffect, Fragment } from "react"
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";

import TicketForm from "../TicketForm";
import TicketItem from "../TicketList/TicketItem";

import { getTicketAction, editTicketAction } from "../../../../redux/actions/ticketsActions"
import { getItemSelector } from "../../../../utils/selectors";
import Breadcrumbs from "../../../../components/Breadcrumbs";

const EditTicket = ({
    getTicketAction,
    editTicketAction,
    initialValues,
    tableParams,
    match,
    history
}) => {
    const translate = useTranslate();

    useEffect(() => {
        getTicketAction(match.params.id);
    }, [getTicketAction, match.params.id]);

    const onSubmit = data => {
        editTicketAction(data, tableParams);
    };

    if (!initialValues) return null;
    return (
        <Fragment>
            <div className="wrap ticket">
                <div className="_12">
                    <Breadcrumbs label="general.backToTickets" />
                    <h1>{translate("general.editTicket")}</h1>
                </div>
            </div>
            <TicketForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitLabel="general.editTicket"
            />
            <ul className="m-list-container -column">
                <TicketItem
                    key={initialValues?._id}
                    ticket={initialValues}
                    selectedTicketId={initialValues?.id}
                />
            </ul>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.ticket),
    tableParams: state.tableParams
});

const mapDispatchToProps = {
    getTicketAction,
    editTicketAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTicket);
