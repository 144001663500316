import React from "react";
import { Field, reduxForm } from "redux-form";

import InputField from "../../../components/formFields/InputField";
import { required, isValidEmail } from "../../../utils/validation";
import Button from "../../../components/Button";
import { BUTTON_TYPES, INPUT_MODE } from "../../../constants/enums";

const RegisterEmailForm = ({
    handleSubmit,
    onSubmit,
}) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap">
                <div className="_12 mv-10">
                    <Field
                        type="email"
                        name="email"
                        label="form.fieldLabel.email"
                        validate={[required, isValidEmail]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m12 mv-10 text-center">
                    <Button
                        type="submit"
                        btnClass={BUTTON_TYPES.FILLED}
                        label="form.button.createAnAccount"
                    />
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: "RegisterEmailForm",
    persistentSubmitErrors: true,
})(RegisterEmailForm)