import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserToken } from "../../services/localStorage.service";

const AuthModalRoute = ({ path, component }) => {
    const token = getUserToken();
    if (token) {
        return <Route path={path} component={component} />;
    }
    return <Redirect to="/login" />;
}

export default AuthModalRoute;