import React from "react";
import { connect } from "react-redux";
import { getItemSelector } from "../../utils/selectors";
import { ROLES } from "../../constants/enums";
import { Redirect } from "react-router-dom";
import Loader from "../../components/Loader";

const Dashboard = ({ user }) => {

    if (!user) return <Loader />

    const isUser = user?.roleName === ROLES.USER.NAME;

    if (isUser) {
        return <Redirect to="/package-catalog" />
    }

    return (
        <h1>Dashboard</h1>
    )
}

const mapStateToProps = state => ({
    user: getItemSelector(state.currentUser),
})

export default connect(mapStateToProps, null)(Dashboard);