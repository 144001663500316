export const LOGIN_ACTION = '@AUTH/LOGIN_ACTION';
export const loginAction = data => ({
    type: LOGIN_ACTION,
    data
});

export const GOOGLE_AUTHENTICATION = '@AUTH/GOOGLE_AUTHENTICATION';
export const googleAuthenticationAction = data => ({
    type: GOOGLE_AUTHENTICATION,
    data
});

export const LOGOUT_ACTION = '@AUTH/LOGOUT_ACTION';
export const logoutAction = () => ({
    type: LOGOUT_ACTION,
});

export const CHECK_REGISTER_TOKEN = '@AUTH/CHECK_REGISTER_TOKEN';
export const checkRegisterTokenAction = token => ({
    type: CHECK_REGISTER_TOKEN,
    token
});

export const REGISTER_EMAIL = '@AUTH/REGISTER_EMAIL';
export const registerEmailAction = data => ({
    type: REGISTER_EMAIL,
    data
});

export const REGISTER_USER = '@AUTH/REGISTER_USER';
export const registerUserAction = data => ({
    type: REGISTER_USER,
    data
});

export const CHECK_PASSWORD_TOKEN = '@AUTH/CHECK_PASSWORD_TOKEN';
export const checkPasswordTokenAction = token => ({
    type: CHECK_PASSWORD_TOKEN,
    token
});

export const FORGOT_PASSWORD_ACTION = '@AUTH/FORGOT_PASSWORD_ACTION';
export const forgotPasswordAction = data => ({
    type: FORGOT_PASSWORD_ACTION,
    data
});

export const RESET_PASSWORD_ACTION = '@AUTH/RESET_PASSWORD_ACTION'
export const resetPasswordAction = (id, data) => ({
    type: RESET_PASSWORD_ACTION,
    id,
    data
});