/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from "react-redux";

import ServiceItemForm from "../ServiceItemForm";

import { getServicePlanItemAction, editServicePlanItemAction } from "../../../../redux/actions/serviceActions"
import { getItemSelector } from "../../../../utils/selectors";
import { getSettingsItemAction } from '../../../../redux/actions/settingsActions';
import { APP_SETTING_KEYS } from '../../../../constants/enums';

const EditServiceItem = ({
    initialValues,
    getServicePlanItemAction,
    editServicePlanItemAction,
    getSettingsItemAction,
    defaultLanguage,
    tableParams,
    match,
    history
}) => {

    useEffect(() => {
        const { id } = match.params;
        getServicePlanItemAction(id);
        getSettingsItemAction(APP_SETTING_KEYS.DEFAULT_LANGUAGE);
    }, [])

    const onSubmit = (data) => {
        if (data.servicePlans) {
            delete data.servicePlans;
        }
        editServicePlanItemAction(data, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    if (!initialValues) return null;

    return (
        <ServiceItemForm
            title="general.editServiceItem"
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitLabel="general.editServiceItem"
            handleCancel={handleCancel}
            defaultLanguage={defaultLanguage}
        />
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.servicePlanItem),
    tableParams: state.tableParams,
    defaultLanguage: getItemSelector(state.settingsItem)
});

const mapDispatchToProps = {
    getServicePlanItemAction,
    editServicePlanItemAction,
    getSettingsItemAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditServiceItem);
