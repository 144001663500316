import React from 'react';
import { useTranslate } from "react-polyglot";
import moment from 'moment';
import { formatDateTime } from '../../../../services/moment.service';
import ProgressRing from '../../../ProgressRing';
import Button from '../../../Button';
import { BUTTON_TYPES } from '../../../../constants/enums';

const ActivePackage = ({ activePackage, setRenewModal }) => {

    const translate = useTranslate();
    let periodLeftLabel;

    const calculateDaysLeft = () => {
        let today = moment.utc().add(60, 'm').toDate();
        let toDate = moment(activePackage.toDate);
        let daysLeft = toDate.diff(today, 'days');
        if (daysLeft === 0) {
            let hoursLeft = toDate.diff(today, 'hours');
            if (hoursLeft === 0) {
                let minutesLeft = toDate.diff(today, 'minutes');
                periodLeftLabel = "general.minutesLeft";
                if (minutesLeft < 0) {
                    return 0;
                }
                return minutesLeft;
            }
            periodLeftLabel = "general.hoursLeft";
            return hoursLeft;
        }
        periodLeftLabel = "general.daysLeft";
        return daysLeft;
    }

    const calculateTotalDays = () => {
        let fromDate = moment(activePackage.fromDate);
        let toDate = moment(activePackage.toDate);
        let total = toDate.diff(fromDate, 'days');

        let today = moment.utc().add(60, 'm').toDate();
        let daysLeft = toDate.diff(today, 'days');
        if (daysLeft === 0) {
            let hoursLeft = toDate.diff(today, 'hours');
            if (hoursLeft === 0) {
                return 60;
            }
            return 24;
        }
        return total;
    }

    const handleRenewPackage = () => {
        setRenewModal(true);
    }

   
    const { type, id } = activePackage.servicePlan;
    return (
        <div className="m-active-package">
            <div className="info">
                <p className="title">{translate('general.currentActivePackage')}</p>
                <p className="package-name">{translate(`${type}.${id}.name`)}</p>
                <p className="start-date"> <span>{translate('general.startDate')}</span> {formatDateTime(activePackage.fromDate)}</p>
            </div>
            <div className="progress">
                <ProgressRing
                    radius={90}
                    stroke={15}
                    part={calculateDaysLeft()}
                    total={calculateTotalDays()}
                />
                <div className="days-left">
                    <span className="days">{calculateDaysLeft()}</span>
                    <span className="label">{translate(periodLeftLabel)}</span>
                </div>
            </div>

            <Button 
                label={`general.renew`} 
                btnClass={BUTTON_TYPES.SECONDARY} 
                onClick={handleRenewPackage}
            />
        </div>
    )
}

export default ActivePackage;
