import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";

import RegisterUserForm from "./RegisterUserForm";
import { checkRegisterTokenAction, registerUserAction } from "../../../redux/actions/authActions";

const RegisterUser = ({
    checkRegisterTokenAction,
    registerUserAction,
    match,
    currentUser
}) => {
    const translate = useTranslate();

    useEffect(() => {
        checkRegisterTokenAction(match.params.token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = data => {
        const formData = {
            ...currentUser.attributes,
            ...data,
            ...match.params,
        }
        if (formData.gender) {
            formData.gender = Number(formData.gender);
        }
        registerUserAction(formData);
    }

    return (
        <div className="no-auth-content">
            <div className="wrapper auth-container">
                <h2>{translate("general.welcome")},</h2>
                <h1>{translate("general.createAnAccount")}</h1>
                <RegisterUserForm
                    onSubmit={onSubmit}
                    translate={translate}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = {
    checkRegisterTokenAction,
    registerUserAction
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);