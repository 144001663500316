import { put, fork, takeLatest } from 'redux-saga/effects';
import { GET_LANGUAGES, GET_LANGUAGES_SUCCESS, SET_TRANSLATION, SET_TRANSLATION_ASYNC } from '../actions/translationActions';
import { setUserLang } from '../../services/localStorage.service';

import axiosClient, { GET } from "../../utils/axiosClient";
import { formatUrl, formatResponseData } from "../../utils/jsonApiHelpers";
import errorHandler from "./errorHandler";

const PATH = "/languages"

function* getLanguages({ language }) {
    try {
        const url = formatUrl(PATH);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_LANGUAGES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);

    }
}

function* setTranslation({ language }) {
    try {
        setUserLang(language);
        const translations = require(`../../translations/${language}`);
        yield put({ type: SET_TRANSLATION_ASYNC, data: { language, ...translations } });
    } catch (error) {
        console.error(error);
    }
}

const getLanguagesAsync = fork(function* () { yield takeLatest(GET_LANGUAGES, getLanguages) });
const setTranslationAsync = fork(function* () { yield takeLatest(SET_TRANSLATION, setTranslation) });

export default [getLanguagesAsync, setTranslationAsync];