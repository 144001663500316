import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_PRICES,
    GET_PRICES_SUCCESS,
    GET_PRICE,
    GET_PRICE_SUCCESS,
    CREATE_PRICE,
    EDIT_PRICE,
    GET_PRICE_TYPES,
    GET_PRICE_TYPES_SUCCESS,
} from "../actions/priceActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history"

import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";

const PATH = "/price_lists";
const PRICE_TYPE_PATH = "/price_list_types";

function* getPrices({ params }) {
    try {
        const url = formatUrl(PATH, { include: "priceListType", ...params });
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_PRICES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getPrice({ id, params }) {
    try {
        const url = formatUrl(`${PATH}/${id}`, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_PRICE_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createPrice({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.PRICE, data);
        yield axiosClient(POST, PATH, preparedData);
        yield successHandler("notification.message.successCreatedPrice");
        history.goBack();
        yield getPrices({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editPrice({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.PRICE, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedPrice");
        history.goBack();
        yield getPrices({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getPriceTypes({ params }) {
    try {
        const url = formatUrl(PRICE_TYPE_PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_PRICE_TYPES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

const getPricesAsync = fork(function* () { yield takeLatest(GET_PRICES, getPrices) });
const getPriceAsync = fork(function* () { yield takeLatest(GET_PRICE, getPrice) });
const createPriceAsync = fork(function* () { yield takeLatest(CREATE_PRICE, createPrice) });
const editPriceAsync = fork(function* () { yield takeLatest(EDIT_PRICE, editPrice) });
const getPriceTypesAsync = fork(function* () { yield takeLatest(GET_PRICE_TYPES, getPriceTypes) });

export default [
    getPricesAsync,
    getPriceAsync,
    createPriceAsync,
    editPriceAsync,
    getPriceTypesAsync,
];