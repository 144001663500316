import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_SERVICE_PLAN_ITEMS,
    GET_SERVICE_PLAN_ITEMS_SUCCESS,
    GET_SERVICE_PLAN_ITEM,
    GET_SERVICE_PLAN_ITEM_SUCCESS,
    CREATE_SERVICE_PLAN_ITEM,
    EDIT_SERVICE_PLAN_ITEM,
    GET_SERVICE_PLANS,
    GET_SERVICE_PLANS_SUCCESS,
    GET_SERVICE_PLAN,
    GET_SERVICE_PLAN_SUCCESS,
    CREATE_SERVICE_PLAN,
    EDIT_SERVICE_PLAN,
    GET_SERVICE_PLAN_LISTS,
    GET_SERVICE_PLAN_LISTS_SUCCESS,
    GET_SERVICE_PLAN_LIST,
    GET_SERVICE_PLAN_LIST_SUCCESS
} from "../actions/serviceActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history"
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";

const SERVICE_PLAN_PATH = "/service_plans";
const SERVICE_PLAN_ITEM_PATH = "/service_plan_items";
const SERVICE_PLAN_LIST_PATH = "/service_plan_lists";

function* getServicePlanItems({ params }) {
    try {
        const url = formatUrl(SERVICE_PLAN_ITEM_PATH, { include: 'itemType', ...params });
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_SERVICE_PLAN_ITEMS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getServicePlanItem({ id }) {
    try {
        const response = yield axiosClient(GET, `${SERVICE_PLAN_ITEM_PATH}/${id}`);
        const data = formatResponseData(response.data);
        yield put({ type: GET_SERVICE_PLAN_ITEM_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createServicePlanItem({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.SERVICE_PLAN_ITEM, data);
        yield axiosClient(POST, SERVICE_PLAN_ITEM_PATH, preparedData);
        yield successHandler("notification.message.successCreatedServiceItem");
        history.goBack();
        yield getServicePlanItems({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editServicePlanItem({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.SERVICE_PLAN_ITEM, data);
        yield axiosClient(PATCH, `${SERVICE_PLAN_ITEM_PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedServiceItem");
        history.goBack();
        yield getServicePlanItems({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getServicePlans({ params }) {
    try {
        const url = formatUrl(SERVICE_PLAN_PATH, { include: 'planType', ...params });
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_SERVICE_PLANS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getServicePlan({ id }) {
    try {
        const url = formatUrl(`${SERVICE_PLAN_PATH}/${id}`, { include: 'discounts,priceLists,planItems,planType,relatedServicePlans,image' })
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_SERVICE_PLAN_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createServicePlan({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.SERVICE_PLAN, data);
        yield axiosClient(POST, SERVICE_PLAN_PATH, preparedData);
        yield successHandler("notification.message.successCreatedServicePlan");
        history.goBack();
        yield getServicePlans({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editServicePlan({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.SERVICE_PLAN, data);
        yield axiosClient(PATCH, `${SERVICE_PLAN_PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedServicePlan");
        history.goBack();
        yield getServicePlans({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getServicePlanLists({ params }) {
    try {
        const url = formatUrl(SERVICE_PLAN_LIST_PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_SERVICE_PLAN_LISTS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getServicePlanList({ id, params }) {
    try {
        const url = formatUrl(`${SERVICE_PLAN_LIST_PATH}/${id}`, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_SERVICE_PLAN_LIST_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

const getServicePlanItemsAsync = fork(function* () { yield takeLatest(GET_SERVICE_PLAN_ITEMS, getServicePlanItems) });
const getServicePlanItemAsync = fork(function* () { yield takeLatest(GET_SERVICE_PLAN_ITEM, getServicePlanItem) });
const createServicePlanItemAsync = fork(function* () { yield takeLatest(CREATE_SERVICE_PLAN_ITEM, createServicePlanItem) });
const editServicePlanItemAsync = fork(function* () { yield takeLatest(EDIT_SERVICE_PLAN_ITEM, editServicePlanItem) });
const getServicePlansAsync = fork(function* () { yield takeLatest(GET_SERVICE_PLANS, getServicePlans) });
const getServicePlanAsync = fork(function* () { yield takeLatest(GET_SERVICE_PLAN, getServicePlan) });
const createServicePlanAsync = fork(function* () { yield takeLatest(CREATE_SERVICE_PLAN, createServicePlan) });
const editServicePlanAsync = fork(function* () { yield takeLatest(EDIT_SERVICE_PLAN, editServicePlan) });
const getServicePlanListsAsync = fork(function* () { yield takeLatest(GET_SERVICE_PLAN_LISTS, getServicePlanLists) });
const getServicePlanListAsync = fork(function* () { yield takeLatest(GET_SERVICE_PLAN_LIST, getServicePlanList) });

export default [
    getServicePlanItemsAsync,
    getServicePlanItemAsync,
    createServicePlanItemAsync,
    editServicePlanItemAsync,
    getServicePlansAsync,
    getServicePlanAsync,
    createServicePlanAsync,
    editServicePlanAsync,
    getServicePlanListsAsync,
    getServicePlanListAsync
];