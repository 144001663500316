import React from "react"
import { Field, reduxForm } from "redux-form";
import { useTranslate } from "react-polyglot";
import TextAreaField from '../../../../../components/formFields/TextAreaField';
import Button from "../../../../../components/Button";
import { required } from "../../../../../utils/validation";
import { BUTTON_TYPES } from "../../../../../constants/enums";

const TicketCommentForm = ({
    handleSubmit,
    onSubmit,
    handleClose
}) => {
    const translate = useTranslate();
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap">
                <div className="_12">
                    <Field
                        type="text"
                        name="text"
                        label="form.fieldLabel.ticketComment"
                        component={TextAreaField}
                        validate={required}
                    />
                </div>
            </div>
            <div className="wrap ticket">
                <div className="wrap _12 flex _jcfe mv-10">
                    <span className="btn-cancel" onClick={handleClose}>{translate("general.cancel")}</span>
                    <Button
                        type='submit'
                        onClick={handleSubmit}
                        btnClass={BUTTON_TYPES.FILLED}
                        label="form.button.sendReply"
                    />
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: "TicketCommentForm"
})(TicketCommentForm)
