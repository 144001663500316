import React, { useRef, useEffect } from 'react';
import PropTypes, { string, node } from 'prop-types';
import { useTranslate } from 'react-polyglot';

import Button from '../Button';
import { BUTTON_TYPES } from '../../constants/enums';
import Icon from '../Icon';

const Modal = ({
    open,
    text,
    handleCancel,
    handleSubmit,
    submitLabel,
    cancelLabel,
    clickOutside = false,
}) => {
    const modalRef = useRef(null);
    const translate = useTranslate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClickOutside = (event) => {
        if (open && modalRef && modalRef.current && !modalRef.current.contains(event.target)) {
            handleCancel();
        }
    };

    useEffect(() => {
        if (clickOutside) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [clickOutside, handleClickOutside, open]);


    return (
        <div className={`modal ${open ? 'show' : ''}`}>
            <div className="modal-container">
                <span onClick={handleCancel} className='close'>
                    <Icon name="close" />
                </span>
                <div ref={modalRef} className='wrapper'>
                    <div className='modal-content'>
                        <div className='modal-body'>
                            <h2>{text}</h2>
                        </div>
                        <div className='modal-footer'>
                            <span className="btn-cancel" onClick={handleCancel}>{translate(cancelLabel)}</span>
                            <Button
                                type='submit'
                                btnClass={BUTTON_TYPES.FILLED}
                                onClick={handleSubmit}
                                label={submitLabel}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    text: PropTypes.oneOfType([string, node]).isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string.isRequired,
    clickOutside: PropTypes.bool,
}

export default Modal;