import React from 'react'
import history from "../../utils/history";

const Logo = () => {
    return (
        <div onClick={() => history.push("/")} className="a-logo">
        </div>
    )
}

export default Logo
