import React, { useEffect } from "react"
import { connect } from "react-redux";

import DocumentTypeForm from "../DocumentTypeForm";

import { getDocumentTypeAction, editDocumentTypeAction } from "../../../../redux/actions/documentTypeActions"
import { getItemSelector } from "../../../../utils/selectors";

const EditDocumentType = ({
    getDocumentTypeAction,
    editDocumentTypeAction,
    initialValues,
    tableParams,
    match,
    history,
}) => {

    useEffect(() => {
        getDocumentTypeAction(match.params.id);
    }, [getDocumentTypeAction, match.params.id]);

    const onSubmit = data => {
        editDocumentTypeAction(data, tableParams);
    };

    const handleCancel = () => {
        history.goBack();
    };

    if (!initialValues) return null;

    return (
        <DocumentTypeForm
            title="general.editDocumentType"
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitLabel="general.editDocumentType"
            handleCancel={handleCancel}
        />
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.documentType),
    tableParams: state.tableParams,
});

const mapDispatchToProps = {
    getDocumentTypeAction,
    editDocumentTypeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentType);
