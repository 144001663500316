/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getUsersAction } from "../../../redux/actions/usersActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";

import AuthModalRoute from "../../../components/routes/AuthModalRoute";
import CreateUser from "./CreateUser";
import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "roleName",
        title: "table.header.roleName",
        valueType: "roleName"
    },
    {
        key: "email",
        title: "table.header.email",
        sortable: true
    },
    {
        key: "firstName",
        title: "table.header.firstName",
        sortable: true
    },
    {
        key: "lastName",
        title: "table.header.lastName",
        sortable: true
    },
    {
        key: "phoneNumber",
        title: "table.header.phoneNumber",
    },
];

// TODO: Implement pagination throught query params
// Get data after every delete and edit user
const UserManagement = ({
    getUsersAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleCreateModal = () => {
        history.push(`${match.url}/create`);
    }

    const handleDeleteModal = id => {
        history.push(`${match.url}/delete/${id}`)
    }

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`)
    }

    const handleShowInvoices = id => {
        history.push(`/user/invoices/${id}`)
    }

    const handleShowDocuments = id => {
        history.push(`/user/documents/${id}`)
    }

    return (
        <Fragment>
            <Table
                title="general.menuItems.users"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getUsersAction}
                actions={{
                    deleteAction: handleDeleteModal,
                    editAction: handleEditModal,
                    addAction: handleCreateModal,
                    generateFileAction: handleShowInvoices,
                    showDocumentsAction: handleShowDocuments
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create`}
                component={CreateUser}
            />
            <AuthModalRoute
                path={`${match.url}/delete/:id`}
                component={DeleteUser}
            />
            <AuthModalRoute
                path={`${match.url}/edit/:id`}
                component={EditUser}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    isLoading: state.users.isLoading,
    data: getListSelector(state.users),
    totalItems: getTotalItemsSelector(state.users)
});

const mapDispatchToProps = {
    getUsersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)