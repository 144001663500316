/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getDocumentTypesAction } from "../../../redux/actions/documentTypeActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";
import AuthModalRoute from "../../../components/routes/AuthModalRoute";
import CreateDocumentType from "./CreateDocumentType";
import EditDocumentType from "./EditDocumentType";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "name",
        title: "table.header.name",
    },
    {
        key: "description",
        title: "table.header.description",
    },
];

const DocumentTypesManagement = ({
    getDocumentTypesAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleCreateModal = () => {
        history.push(`${match.url}/create`);
    };

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`)
    };

    return (
        <Fragment>
            <Table
                title="general.menuItems.documentTypes"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getDocumentTypesAction}
                actions={{
                    editAction: handleEditModal,
                    addAction: handleCreateModal,
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create`}
                component={CreateDocumentType}
            />
            <AuthModalRoute
                path={`${match.url}/edit/:id`}
                component={EditDocumentType}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    isLoading: state.documentTypes.isLoading,
    data: getListSelector(state.documentTypes),
    totalItems: getTotalItemsSelector(state.documentTypes)
});

const mapDispatchToProps = {
    getDocumentTypesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypesManagement)
