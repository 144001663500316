import moment from "moment";

const DEFAULT_MOMENT_FORMATS = {
    DATE: "DD.MM.YYYY",
    TIME: "HH:mm",
    TIME12HOUR: "HH:mm A",
    DATETIME: "DD.MM.YYYY. HH:mm",
    DATETIME24HOUR: "YYYY-MM-DD HH:mm:ss",
    DATETIME12HOUR: "DD.MM.YYYY HH:mm A"
}

const formatDate = date => {
    return moment.utc(date).format(DEFAULT_MOMENT_FORMATS.DATE);
}

const formatTime = time => {
    return moment.utc(time).format(DEFAULT_MOMENT_FORMATS.TIME);
}

const formatDateTime = (date, time) => {
    if (time) {
        return moment.utc(`${formatDate(date)} ${formatTime(time)}`, DEFAULT_MOMENT_FORMATS.DATETIME);
    }
    return moment.utc(date).format(DEFAULT_MOMENT_FORMATS.DATETIME)
}

const createTime = (hours, minutes) => {
    return moment.utc(`${hours}:${minutes}`, DEFAULT_MOMENT_FORMATS.TIME);
}

const createDateAndTime = (date, time) => {
    const newDate = moment.utc(date).format(DEFAULT_MOMENT_FORMATS.DATE);
    return moment.utc(`${newDate} ${time}`, DEFAULT_MOMENT_FORMATS.DATETIME);
}

const getHours = (time) => {
    return moment.utc(time).format('HH');
}

const getMinutes = (time) => {
    return moment.utc(time).format('mm');
}

const getAmPm = (time) => {
    return (moment.utc(time).format('a')).toUpperCase();
}

const formatDateTimeServer = (date) => {
    return moment.utc(date).format(DEFAULT_MOMENT_FORMATS.DATETIME24HOUR)
}

const formatDateTimeFromServer = (date) => {
    return moment(date).format(DEFAULT_MOMENT_FORMATS.DATETIME)
}

export {
    DEFAULT_MOMENT_FORMATS,
    formatDate,
    formatTime,
    formatDateTime,
    createTime,
    createDateAndTime,
    getHours,
    getMinutes,
    getAmPm,
    formatDateTimeServer,
    formatDateTimeFromServer
}