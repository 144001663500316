import React from 'react';
import { connect } from "react-redux";

import ServicePlanForm from '../ServicePlanForm';

import { createServicePlanAction } from "../../../../redux/actions/serviceActions";
import { getIsServiceSelector } from "../../../../utils/selectors";

const CreateServicePlan = ({
    createServicePlanAction,
    tableParams,
    history,
    isService
}) => {

    const onSubmit = data => {
        if (data.totalAvailable) {
            data.totalAvailable = Number(data.totalAvailable);
        }
        createServicePlanAction(data, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <ServicePlanForm
            title="general.createServicePlan"
            onSubmit={onSubmit}
            submitLabel="general.createServicePlan"
            handleCancel={handleCancel}
            isService={isService}
        />
    );
};

const mapStateToProps = state => ({
    tableParams: state.tableParams,
    isService: getIsServiceSelector(state)
});

const mapDispatchToProps = {
    createServicePlanAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateServicePlan);
