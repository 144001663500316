/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, Fragment } from 'react';
import PropTypes, { string, node } from 'prop-types';
import { useTranslate } from 'react-polyglot';

import Modal from '../Modal';
import Backdrop from '../Backdrop';
import Icon from '../Icon';
import Button from '../Button';
import { BUTTON_TYPES } from '../../constants/enums';

const ModalForm = ({
    open,
    title,
    children,
    handleSubmit,
    handleCancel,
    submitLabel,
    cancelLabel,
    areYouSure = false,
    clickOutside = false,
    closeOnEnter = true,
}) => {
    const modalRef = useRef(null);
    const translate = useTranslate();

    const [aysModal, setAysModal] = useState(false);

    useEffect(() => {
        document.addEventListener("keyup", handleCloseOnEscape);
        return () => {
            document.removeEventListener("keyup", handleCloseOnEscape)
        }
    }, [])

    const handleCloseOnEscape = event => {
        if (closeOnEnter && event.key === "Escape") {
            handleClose();
        }
    }

    const handleClickOutside = (event) => {
        if (open && modalRef && modalRef.current && !modalRef.current.contains(event.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        if (clickOutside) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [clickOutside, handleClickOutside, open]);

    const handleClose = () => {
        if (areYouSure) {
            setAysModal(true);
        } else {
            handleCancel();
        }
    };


    const handleSubmitAysModal = () => {
        setAysModal(false);
        handleCancel();
    };

    // TODO: SEPARATE AND MEMOIZE HEADER AND FOOTER 
    return (
        <Fragment>
            <div className={`modal ${open ? 'show' : ''} -admin`}>
                <Backdrop handleClose={handleClose} />
                <div className="modal-container">
                    <span onClick={handleClose} className='close'>
                        <Icon name="close" />
                    </span>
                    <form className="wrapper" onSubmit={handleSubmit}>
                        <div ref={modalRef} className='modal-content'>
                            <div className='modal-header'>
                                <span className="title">{translate(title)}</span>
                            </div>

                            <div className='modal-body'>
                                {children}
                            </div>
                            <hr className="horizontal-line" />
                            <div className='modal-footer'>
                                <span className="btn-cancel" onClick={handleClose}>{translate(cancelLabel)}</span>
                                <Button
                                    type='submit'
                                    // onClick={handleSubmit}
                                    btnClass={BUTTON_TYPES.FILLED}
                                    label={submitLabel}
                                />
                            </div>
                        </div>
                    </form>
                    <Modal
                        open={aysModal}
                        text={translate("general.ays")}
                        handleSubmit={handleSubmitAysModal}
                        handleCancel={() => setAysModal(false)}
                        submitLabel='general.yes'
                        cancelLabel='general.no'
                    />
                </div>
            </div>
        </Fragment>
    )
};

ModalForm.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([string, node]).isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string.isRequired,
    areYouSure: PropTypes.bool,
    clickOutside: PropTypes.bool,
    closeOnEnter: PropTypes.bool
}

export default ModalForm;