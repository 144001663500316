/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

import { getHours, getMinutes, createDateAndTime } from '../../services/moment.service';

const TimeField = ({ value, onChange, min, max, step }) => {

    const valueNum = Number(value);

    const handleClickMore = () => {
        if (valueNum + step <= max) {
            if (valueNum + step < 10) {
                onChange({ value: `0${valueNum + step}` })
            } else {
                onChange({ value: valueNum + step });
            }
        }
    }

    const handleClickLess = () => {
        if (valueNum - step >= min) {
            if (valueNum - step < 10) {
                onChange({ value: `0${valueNum - step}` })
            } else {
                onChange({ value: valueNum - step });
            }
        }
    }

    return (
        <div className="select-time">
            <span className="more" onClick={handleClickMore}></span>
            <input
                className="time-value"
                value={value}
                type="number"
                onChange={onChange}
                min={min}
                max={max}
                step={step}
            />
            <span className="less" onClick={handleClickLess}></span>
        </div>
    )
}

TimeField.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired
}

const TimePicker = ({
    input
}) => {

    const translate = useTranslate();

    const [minutes, setMinutes] = useState('');
    const [hours, setHours] = useState('');

    useEffect(() => {
        if (input.value) {
            setHours(getHours(input.value));
            setMinutes(getMinutes(input.value));
        }
    }, [input.value])

    const handleHourChange = event => {
        const { value } = event.target || event;
        let time = createDateAndTime(input.value, `${value}:${minutes}`);
        setHours(value);
        input.onChange(time.format());
    }

    const handleMinuteChange = event => {
        const { value } = event.target || event;
        let time = createDateAndTime(input.value, `${hours}:${value}`);
        setMinutes(value);
        input.onChange(time.format());
    }

    return (
        <div className="a-input -time">
            <div className="select-time-container">
                <TimeField
                    value={parseInt(Number(hours)).toString()}
                    name="hours"
                    label={translate("general.from")}
                    min={0}
                    max={23}
                    step={1}
                    onChange={handleHourChange}
                />
                <span className="space">:</span>
                <TimeField
                    value={parseInt(Number(minutes)).toString()}
                    name="minutes"
                    label={translate("general.to")}
                    min={0}
                    max={59}
                    step={5}
                    onChange={handleMinuteChange}
                />
            </div>
        </div>
    )
}

TimePicker.propTypes = {
    input: PropTypes.object
}

TimePicker.defaultProps = {
    input: {}
}

export default TimePicker;
