import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import { INPUT_MODE } from '../../constants/enums';

const SelectField = ({
    input,
    label,
    options,
    readOnly,
    defaultValue,
    mode,
    required,
    meta: { touched, error }
}) => {
    const hasError = touched && error && required;
    const translate = useTranslate();

    const filled = input.value && input.value !== translate(label);

    return (
        <div className='a-input-group'>
            <select
                {...input}
                className={`a-select -${mode} ${filled ? "-filled" : ""} ${hasError ? "-error" : ""}`}
                disabled={readOnly}
                value={input.value || defaultValue || translate(label)}
                id={input.name}
            >
                <option
                    value={null}
                    // disabled
                    hidden
                >

                </option>
                {options.map(({ id, name, disabled }) => (
                    <option
                        key={id}
                        value={id}
                        disabled={disabled}
                    >
                        {name}
                    </option>
                ))}
            </select>
            <label htmlFor={input.name} className={`-${mode} ${required ? "-required" : ""}`}>{translate(label)}</label>
            <i className="select-arrow"></i>
            {hasError && <span className="a-input-error">{translate(error)}</span>}
        </div>
    )
};

SelectField.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    options: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    mode: PropTypes.oneOf([
        INPUT_MODE.DARK,
        INPUT_MODE.LIGHT
    ]),
}

SelectField.defaultProps = {
    input: {},
    meta: {},
    mode: INPUT_MODE.DARK,
    required: false
}

export default SelectField;