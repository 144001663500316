/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import { connect } from "react-redux";

import { getServicePlansAction } from "../../../redux/actions/serviceActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";
import AuthModalRoute from "../../../components/routes/AuthModalRoute";
import CreateServicePlan from "./CreateServicePlan";
import EditServicePlan from "./EditServicePlan";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "planType.name",
        title: "table.header.planType",
    },
    {
        key: "name",
        title: "table.header.name",
    },
    {
        key: "totalAvailable",
        title: "table.header.totalAvailable",
    }
]

const ServicePlanManagement = ({
    getServicePlansAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleCreateModal = () => {
        history.push(`${match.url}/create`);
    };

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`);
    };

    return (
        <Fragment>
            <Table
                title="general.menuItems.servicePlans"
                headerItems={TABLE_HEADER}
                isLoading={isLoading}
                totalItems={totalItems}
                data={data}
                getData={getServicePlansAction}
                actions={{
                    editAction: handleEditModal,
                    addAction: handleCreateModal,
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create`}
                component={CreateServicePlan}
            />
            <AuthModalRoute
                path={`${match.url}/edit/:id`}
                component={EditServicePlan}
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    isLoading: state.servicePlans.isLoading,
    data: getListSelector(state.servicePlans),
    totalItems: getTotalItemsSelector(state.servicePlans)
});

const mapDispatchToProps = {
    getServicePlansAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePlanManagement);
