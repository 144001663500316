import React from 'react'
import PropTypes from 'prop-types';
import { useTranslate } from "react-polyglot";

const PageSelector = ({
    options,
    itemsPerPage,
    setItemsPerPage,
    totalItems
}) => {
    const translate = useTranslate();

    const handleGetData = event => {
        const value = Number(event.target.value);
        setItemsPerPage(value);
    }

    return (
        <div className="wrap _aic _jcsb">
            <span className=" mr-20">{translate("general.showing")} {itemsPerPage < totalItems ? itemsPerPage : totalItems} {translate("general.outOf")} {totalItems}</span>
            <div>
                {translate("general.show")}
                <select className="select-page" defaultValue={itemsPerPage} onClick={handleGetData}>
                    {options.map(item => <option key={item}>{item}</option>)}
                </select >
                {translate("general.items")}
            </div>
        </div>
    )
}

PageSelector.propTypes = {
    options: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    setItemsPerPage: PropTypes.func.isRequired
}

export default PageSelector;