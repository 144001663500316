import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import history from "../../utils/history";

import { LOGOUT_ACTION } from "../actions/authActions";
import { deleteUserId, deleteUserToken, deleteUserShoppingCart } from "../../services/localStorage.service";

import { currentUserReducer } from "./currentUser.reducers";
import { alertReducer } from "./alert.reducers";
import { unreadNotificationsReducer, notificationsReducer, notificationTypesReducer } from "./notifications.reducers";
import { languagesReducer, translationReducer } from "./translation.reducers";
import { tableParamsReducer } from "./tableParams.reducers";

import { usersReducer, userReducer, usersDocumentsReducer, userInvoicesReducer } from "./users.reducers";
import { rolesReducer } from "./roles.reducers";
import { servicePlanItemsReducer, servicePlanItemReducer, servicePlansReducer, servicePlanReducer, servicePlanListsReducer, servicePlanListReducer } from "./services.reducers";
import { ticketsReducer, ticketReducer } from "./tickets.reducers";
import { bookingsReducer, bookingReducer, bookingStatusesReducer, bookingItemsReducer, bookingItemReducer } from "./bookings.reducers";
import { discountsReducer, discountReducer } from "./discounts.reducers";
import { inventoriesReducer, inventoryReducer } from "./inventory.reducers";
import { pricesReducer, priceReducer, priceTypesReducer } from "./prices.reducers";
import { documentTypesReducer, documentTypeReducer } from "./documentTypes.reducers";
import { settingsReducer, settingReducer } from "./settings.reducers";
import { shoppingCartReducer } from "./shoppingCart.reducers";
import { eventsReducer, myEventsReducer, eventReducer, eventAnswersReducer } from './events.reducers';

const appReducer = combineReducers({
    form: formReducer,
    currentUser: currentUserReducer,
    unreadNotifications: unreadNotificationsReducer,
    notifications: notificationsReducer,
    notificationTypes: notificationTypesReducer,
    alert: alertReducer,
    languages: languagesReducer,
    translation: translationReducer,
    users: usersReducer,
    user: userReducer,
    usersDocuments: usersDocumentsReducer,
    userInvoices: userInvoicesReducer,
    roles: rolesReducer,
    servicePlanItems: servicePlanItemsReducer,
    servicePlanItem: servicePlanItemReducer,
    servicePlans: servicePlansReducer,
    servicePlan: servicePlanReducer,
    servicePlanLists: servicePlanListsReducer,
    servicePlanList: servicePlanListReducer,
    tableParams: tableParamsReducer,
    tickets: ticketsReducer,
    ticket: ticketReducer,
    bookings: bookingsReducer,
    booking: bookingReducer,
    bookingStatuses: bookingStatusesReducer,
    bookingItems: bookingItemsReducer,
    bookingItem: bookingItemReducer,
    discounts: discountsReducer,
    discount: discountReducer,
    inventories: inventoriesReducer,
    inventory: inventoryReducer,
    prices: pricesReducer,
    price: priceReducer,
    priceTypes: priceTypesReducer,
    documentTypes: documentTypesReducer,
    documentType: documentTypeReducer,
    settings: settingsReducer,
    settingsItem: settingReducer,
    shoppingCart: shoppingCartReducer,
    events: eventsReducer,
    myEvents: myEventsReducer,
    event: eventReducer,
    eventAnswers: eventAnswersReducer
});

export const logoutUser = () => {
    deleteUserId();
    deleteUserToken();
    deleteUserShoppingCart();
    history.push("/login");
};

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_ACTION) {
        logoutUser();
        state.form = undefined;
        state.currentUser = undefined;
        state.alert = undefined;
        state.translation = undefined;
        state.roles = undefined;
        state.users = undefined;
        state.user = undefined;
        state.userInvoices = undefined;
        state.tickets = undefined;
        state.ticket = undefined;
        state.servicePlans = undefined;
        state.servicePlan = undefined;
        state.servicePlanItems = undefined;
        state.servicePlanItem = undefined;
        state.servicePlanLists = undefined;
        state.servicePlanList = undefined;
        state.bookings = undefined;
        state.booking = undefined;
        state.bookingStatuses = undefined;
        state.bookingItems = undefined;
        state.bookingItem = undefined;
        state.discounts = undefined;
        state.discount = undefined;
        state.inventories = undefined;
        state.inventory = undefined;
        state.prices = undefined;
        state.price = undefined;
        state.priceTypes = undefined;
        state.documentTypes = undefined;
        state.documentType = undefined;
        state.settings = undefined;
        state.settingsItem = undefined;
        state.shoppingCart = { packages: [], additions: [], services: [] };
        state.events = undefined;
        state.myEvents = undefined;
        state.event = undefined;
    }
    return appReducer(state, action);
}

export default rootReducer;