import { 
    GET_BOOKINGS, 
    GET_BOOKINGS_SUCCESS, 
    GET_BOOKING, 
    GET_BOOKING_SUCCESS, 
    CREATE_BOOKING,
    CREATE_BOOKING_SUCCESS,
    CREATE_BOOKING_FAIL,
    GET_BOOKING_STATUSES, 
    GET_BOOKING_STATUSES_SUCCESS,
    GET_BOOKING_ITEMS,
    GET_BOOKING_ITEMS_SUCCESS,
    GET_BOOKING_ITEM,
    GET_BOOKING_ITEM_SUCCESS
} from "../actions/bookingsActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const bookingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOOKINGS:
            return { data: null, meta: null, isLoading: true };
        case GET_BOOKINGS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOOKING:
            return { data: null, meta: null, isLoading: true };
        case GET_BOOKING_SUCCESS:
            return { ...action.data, isLoading: false };
        case CREATE_BOOKING:
            return { isLoading: true };
        case CREATE_BOOKING_SUCCESS:
            return { isLoading: false };
        case CREATE_BOOKING_FAIL:
            return { isLoading: false };
        default:
            return state;
    }
};

const bookingStatusesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOOKING_STATUSES:
            return { data: null, meta: null, isLoading: true };
        case GET_BOOKING_STATUSES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const bookingItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOOKING_ITEMS:
            return { data: null, meta: null, isLoading: true };
        case GET_BOOKING_ITEMS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const bookingItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOOKING_ITEM:
            return { data: null, meta: null, isLoading: true };
        case GET_BOOKING_ITEM_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};


export {
    bookingsReducer,
    bookingReducer,
    bookingStatusesReducer,
    bookingItemsReducer,
    bookingItemReducer
};