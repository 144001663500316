import React from "react"
import { Field, reduxForm } from "redux-form";
import InputField from "../../../../components/formFields/InputField";
// import RadioGroupRelationship from '../../../../components/formFields/RadioGroupRelationship';
import TextAreaField from '../../../../components/formFields/TextAreaField';
import FileUploadDnD from "../../../../components/formFields/FileUploadDnD";
import SelectFieldRelationship from '../../../../components/formFields/SelectFieldRelationship';
import Button from "../../../../components/Button";
import { required } from "../../../../utils/validation";
import { BUTTON_TYPES, TICKET_STATUSES, FILE_TYPES } from "../../../../constants/enums";
import ENTITY from "../../../../constants/entities";

const TicketForm = ({
    handleSubmit,
    onSubmit,
    submitLabel,
    initialValues,
    change,
}) => {

    const isEditDisabled = initialValues && initialValues?.status?.id === TICKET_STATUSES.CLOSED;
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap ticket">
                <div className="_m6">
                    <Field
                        name="category.id"
                        label="form.fieldLabel.ticketCategory"
                        onSelect={change}
                        entityType={ENTITY.TICKET_CATEGORIES}
                        component={SelectFieldRelationship}
                        validate={required}
                        required
                    />
                    <Field
                        type="text"
                        name="subject"
                        label="form.fieldLabel.subject"
                        component={InputField}
                        readOnly={!!initialValues}
                        validate={required}
                        required
                    />
                    {/* <div className="mt-25">
                        <Field
                            name="notification"
                            label="form.fieldLabel.hywNotification"
                            component={RadioGroupRelationship}
                            options={[{ value: 1, label: "via e-mail" }, { value: 2, label: "via phone" }]}
                            readOnly={!!initialValues}
                        // validate={required}
                        />
                    </div> */}
                    <Field
                        name="file"
                        component={FileUploadDnD}
                        onImageChange={change}
                        type={FILE_TYPES.IMAGE}
                    />
                    {initialValues &&
                        <div className="mv-25">
                            <Field
                                name="status.id"
                                entityType={ENTITY.TICKET_STATUS}
                                label="form.fieldLabel.ticketStatus"
                                component={SelectFieldRelationship}
                                onSelect={change}
                                readOnly={isEditDisabled}
                                required
                            />
                        </div>
                    }
                </div>
                <div className="_m6 mb-25">
                    <Field
                        name="text"
                        label="form.fieldLabel.describeYourIssue"
                        component={TextAreaField}
                        readOnly={!!initialValues}
                        validate={required}
                        required
                    />
                </div>
            </div>
            <div className="wrap ticket">
                <div className="_12">
                    <Button
                        type='submit'
                        btnClass={BUTTON_TYPES.SECONDARY}
                        label={submitLabel}
                        disabled={isEditDisabled}
                    />
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: "TicketForm"
})(TicketForm)
