import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ type }) => (
    <div className={`loader ${type}`}>Loading...
    </div>
)

Loader.propTypes = {
    type: PropTypes.oneOf([
        "regular",
        "mini"
    ]).isRequired
}

Loader.defaultProps = {
    type: "regular"
};

export default Loader;