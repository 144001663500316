import React from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";

import { getTicketsAction } from "../../../../redux/actions/ticketsActions";
import { getIsLoading, getListSelector, getTotalItemsSelector } from "../../../../utils/selectors";

import Button from "../../../../components/Button";
import List from "../../../../components/List";
import { BUTTON_TYPES } from "../../../../constants/enums";

const MyTickets = ({
    getTicketsAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const translate = useTranslate();

    const goToCreateUser = () => {
        history.push(`${match.url}/create`)
    };

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`)
    };

    return (
        <div>
            <div className="content-container-header">
                <div className="title">
                    <h1>{translate("general.tickets")}</h1>
                    <p className="-regular">{translate('general.ticketTitle')}</p>
                </div>
                <Button
                    onClick={goToCreateUser}
                    label="general.createTicket"
                    btnClass={BUTTON_TYPES.SECONDARY}
                />
            </div>
            <List
                type="tickets"
                data={data}
                getData={getTicketsAction}
                totalItems={totalItems}
                isLoading={isLoading}
                actions={{
                    editAction: handleEditModal,
                }}
                label='general.noTickets'
            />
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: getIsLoading(state.tickets),
    data: getListSelector(state.tickets),
    totalItems: getTotalItemsSelector(state.tickets)
});

const mapDispatchToProps = {
    getTicketsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTickets)