import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import { Field } from 'redux-form';
import ArrayField from './ArrayField';
import Button from '../Button';
import DateTimePicker from './DateTimePicker';
import SelectField from './SelectField';
import ENTITY from '../../constants/entities';
import { required, isValidEmail } from "../../utils/validation";

const renderField = (entityType, item, index, fields, discounts, statuses) => {
    switch (entityType) {
        case ENTITY.USER:
            return (
                <Fragment>
                    <Field
                        type="email"
                        name={`${item}.email`}
                        label="form.fieldLabel.email"
                        validate={[required, isValidEmail]}
                        component={ArrayField}
                        required
                        entityType={entityType}
                        index={index}
                        fields={fields}
                    />
                    {fields.length > 1 ?
                        <span className="remove" onClick={() => fields.remove(index)}></span> :
                        null
                    }
                </Fragment>
            );
        case ENTITY.SERVICE_PLAN:
            return (
                <div className="booking-container">
                    <div className="booking-item">
                        <Field
                            type="text"
                            name={`${item}.servicePlan`}
                            label="form.fieldLabel.name"
                            validate={required}
                            component={ArrayField}
                            entityType={entityType}
                            required
                            index={index}
                            fields={fields}
                            item={item}
                        />
                        <div className="fld">
                            <div className="date-time">
                                <Field
                                    name={`${item}.fromDate`}
                                    component={DateTimePicker}
                                    validate={required}
                                    required
                                    index={index}
                                    fields={fields}
                                    item={item}
                                />
                            </div>
                            <div className="discount">
                                <Field
                                    name={`${item}.discountId`}
                                    label="form.fieldLabel.discount"
                                    component={SelectField}
                                    index={index}
                                    fields={fields}
                                    item={item}
                                    options={discounts}
                                />
                            </div>
                        </div>

                    </div>
                    {fields.length > 1 ?
                        <span className="remove" onClick={() => fields.remove(index)}></span> :
                        null
                    }
                </div>
            );
            case ENTITY.SERVICE_PLAN_LISTS:
            return (
                <div className="booking-container">
                    <div className="booking-item">
                        <Field
                            type="text"
                            name={`${item}.servicePlan`}
                            label="form.fieldLabel.name"
                            validate={required}
                            component={ArrayField}
                            entityType={entityType}
                            required
                            index={index}
                            fields={fields}
                            item={item}
                            isAdditions={true}
                        />
                        <div className="fld">
                            <div className="date-time">
                                <Field
                                    name={`${item}.toDate`}
                                    component={DateTimePicker}
                                    validate={required}
                                    required
                                    index={index}
                                    fields={fields}
                                    item={item}
                                />
                            </div>
                        </div>

                    </div>
                    {fields.length > 0 ?
                        <span className="remove" onClick={() => fields.remove(index)}></span> :
                        null
                    }
                </div>
            );
        default:
            return null;
    }
}

const InputFieldArray = ({
    fields,
    entityType,
    btnLabel,
    allowMoreFields,
    discounts,
    statuses
}) => {

    return (
        <ul className={`m-array-field -array`}>
            {fields.map((item, index) => (
                // Ovo "ili" sam dodala iz razloga sto za prvo renderovanje addition-a koji vec postoji nemam key vec id,
                // a za svaki naredni "prazan" koji dodajem imam key 
                <li key={fields.get(index).key || fields.get(index).id}>    
                    {renderField(entityType, item, index, fields, discounts, statuses)}
                </li>
            ))}
            { allowMoreFields &&
                <li>
                    <Button type="button" label={btnLabel} onClick={() => fields.push({ key: Math.random() * 1000 })} />
                </li>
            }
        </ul>
    )
}

InputFieldArray.propTypes = {
    fields: PropTypes.object.isRequired,
    entityType: PropTypes.string.isRequired,
    btnLabel: PropTypes.string,
    allowMoreFields: PropTypes.bool,
    discounts: PropTypes.array,
    statuses: PropTypes.array
}

InputFieldArray.defaultProps = {
    allowMoreFields: true
}

export default InputFieldArray;
