import React from 'react';
import PropTypes from 'prop-types';
import AvatarDropdown from '../common/AvatarDropdown';
import NotificationDropdown from '../common/NotificationDropdown';
import { ROLES } from '../../../constants/enums';
import UserActiveBookings from './UserActiveBookings';
import LanguagePicker from '../../LanguagePicker';
import Logo from '../../Logo';

const UserDashboard = ({
    handleLogout,
    avatarImg,
    firstName,
    lastName,
    roleName,
}) => {

    const isUser = roleName === ROLES.USER.NAME;

    if (!isUser) return null;

    return (
        <div className="navbar">
            <Logo />
            <div className="user-dashboard-container">
                <div className="user-dashboard">
                    <AvatarDropdown
                        avatarImg={avatarImg}
                        firstName={firstName}
                        lastName={lastName}
                        roleName={roleName}
                        handleLogout={handleLogout}
                    />
                    <LanguagePicker />
                    <NotificationDropdown />
                </div>
                <UserActiveBookings />
            </div>
        </div>
    )
}

UserDashboard.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    avatarImg: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    openSidebar: PropTypes.bool,
    toggleSidebar: PropTypes.func,
    roleName: PropTypes.string.isRequired
}

export default UserDashboard;