import React, { useState } from "react";
import { useRef } from "react";
import PropTypes from 'prop-types';
import { useEffect } from "react";
import ENTITY from '../../constants/entities';
import { Field, reduxForm } from "redux-form";
import ModalForm from "../ModalForm";
import { connect } from "react-redux";
import SelectSearchField from '../../components/formFields/SelectSearchField';
import errorHandler from "../../redux/saga/errorHandler";
import axiosClient, { GET } from "../../utils/axiosClient";
import { checkPackageAction } from "../../redux/actions/shoppingCartActions";
import Loader from "../Loader";
import { getIsLoading } from "../../utils/selectors";


const CheckDateModal = ({
    open,
    date,
    handleSubmit,
    handleCancel,
    clickOutside = false,
    required,
    checkPackageAction,
    isLoading,
}) => {
    const modalRef = useRef(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClickOutside = (event) => {
        if (open && modalRef && modalRef.current && !modalRef.current.contains(event.target)) {
            handleCancel();
        }
    };

    useEffect(() => {
        if (clickOutside) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [clickOutside, handleClickOutside, open]);

    const handleCheckPackage = async(data) => {
        try{ 
            const choosenPackage = await axiosClient(GET, `/service_plans/${data.servicePlans}}`, {}, true);
            checkPackageAction(
                { ...choosenPackage.data.data, fromDate: date, priceListId: choosenPackage.data.data.relationships.priceLists.data[0].id }
            );
        }catch(error){
            errorHandler(error);
        }
    }

    return (
        <ModalForm
            open
            title="general.checkAvailability"
            handleSubmit={handleSubmit(handleCheckPackage)}
            handleCancel={handleCancel}
            submitLabel="form.button.checkPackage"
            cancelLabel="form.button.cancel"
        >   
            <div className="_12">
                <Field
                    type="text"
                    name="servicePlans"
                    label="form.fieldLabel.package"
                    validate={required}
                    component={SelectSearchField}
                    entityType={ENTITY.SERVICE_PLAN_LISTS}
                    required
                />
            </div>
            {isLoading && <Loader type="mini"/>}         

        </ModalForm>        
    )
};

CheckDateModal.propTypes = {
    open: PropTypes.bool,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitLabel: PropTypes.string.isRequired,
    clickOutside: PropTypes.bool
}

const mapStateToProps = (state) => ({
    isLoading: getIsLoading(state.shoppingCart),
})

const mapDispatchToProps = {
    checkPackageAction
};


export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "ModalForm", enableReinitialize: true })(CheckDateModal));
