/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { connect } from "react-redux";

import DiscountForm from "../DiscountForm";

import { createDiscountAction } from "../../../../redux/actions/discountActions";
import { getDiscountTypeSelector } from "../../../../utils/selectors";

const CreateBookingStatus = ({
    createDiscountAction,
    tableParams,
    change,
    history,
    selectedDiscountType
}) => {

    const onSubmit = data => {
        data.percentDiscount = Number(data.percentDiscount);
        createDiscountAction(data, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <DiscountForm
            title="general.createDiscount"
            change={change}
            onSubmit={onSubmit}
            submitLabel="general.createDiscount"
            handleCancel={handleCancel}
            selectedDiscountType={selectedDiscountType}
        />
    )
}

const mapStateToProps = state => ({
    tableParams: state.tableParams,
    selectedDiscountType: getDiscountTypeSelector(state)
});

const mapDispatchToProps = {
    createDiscountAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBookingStatus);