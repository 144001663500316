import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getUserToken } from "../../services/localStorage.service";
import { getCurrentUser } from '../../redux/actions/currentUserActions';
import Layout from "../Layout";
import { getItemSelector } from "../../utils/selectors";

const AuthRoute = ({ 
    path, 
    component,
    exact, 
    allowedRoles,
    user,
    getCurrentUser
 }) => {

    useEffect(() => {
        getCurrentUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const token = getUserToken();

    const allowed = allowedRoles.some(item => item.NAME === user?.role.name);

    if (token) {

        if (!user) return null;

        if (allowed) {
            return (
                <Layout location={path}>
                    <Route path={path} component={component} exact={exact} />
                </Layout>
            )
        }
        return <Redirect to="/" />
    }
    return <Redirect to="/login" />
}

const mapStateToProps = state => ({
    user: getItemSelector(state.currentUser)
})

const mapDispatchToProps = {
    getCurrentUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);