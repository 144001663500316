import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_TICKETS,
    GET_TICKETS_SUCCESS,
    GET_TICKET,
    GET_TICKET_SUCCESS,
    CREATE_TICKET,
    EDIT_TICKET,
    CREATE_TICKET_COMMENT,
} from "../actions/ticketsActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history";
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";

const PATH = "/tickets";
const COMMENTS_PATH = '/ticket_comments';

function* getTickets({ params }) {
    try {
        params = Object.assign(params, { include: "author,status,ticketComments,category,file" })
        const url = formatUrl(PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_TICKETS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getTicket({ id }) {
    try {
        const url = formatUrl(`${PATH}/${id}`, { include: "author,status,ticketComments,category,file" });
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_TICKET_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createTicket({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.TICKET, data);
        yield axiosClient(POST, PATH, preparedData);
        yield successHandler("notification.message.successCreatedTicket");
        yield getTickets({ params });
        history.goBack();
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editTicket({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.TICKET, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedTicket");
        yield getTickets({ params });
        history.goBack();
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createTicketComment({ id, data }) {
    try {
        data = Object.assign(data, { ticket: { id, type: ENTITY.TICKET } })
        const preparedData = formatRequestData(ENTITY.TICKET_COMMENTS, data);
        yield axiosClient(POST, COMMENTS_PATH, preparedData);
        yield successHandler("notification.message.successCreatedComment");
    } catch (error) {
        yield errorHandler(error);
    }
};

const getTicketsAsync = fork(function* () { yield takeLatest(GET_TICKETS, getTickets) });
const getTicketAsync = fork(function* () { yield takeLatest(GET_TICKET, getTicket) });
const createTicketAsync = fork(function* () { yield takeLatest(CREATE_TICKET, createTicket) });
const editTicketAsync = fork(function* () { yield takeLatest(EDIT_TICKET, editTicket) });
const createTicketCommentAsync = fork(function* () { yield takeLatest(CREATE_TICKET_COMMENT, createTicketComment) });

export default [
    getTicketsAsync,
    getTicketAsync,
    createTicketAsync,
    editTicketAsync,
    createTicketCommentAsync,
];