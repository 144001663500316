import React from 'react'
import { Field, reduxForm } from "redux-form";
import ModalForm from "../../../../components/ModalForm";

import InputField from "../../../../components/formFields/InputField";
import { required } from "../../../../utils/validation";
import SelectFieldRelationship from '../../../../components/formFields/SelectFieldRelationship';

import ENTITY from "../../../../constants/entities";

const SettingsForm = ({
    title,
    handleSubmit,
    handleCancel,
    onSubmit,
    submitLabel,
    change
}) => {
    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
            clickOutside={true}
        >
            <div className="wrap">
                <div className="_m4 mv-10">
                    <Field
                        name="key.id"
                        label="form.fieldLabel.key"
                        onSelect={change}
                        entityType={ENTITY.APP_SETTING_KEY}
                        component={SelectFieldRelationship}
                        required
                        validate={required}
                    />
                </div>
                <div className="_m4 mv-10">
                    <Field
                        type="text"
                        name="value"
                        label="form.fieldLabel.value"
                        component={InputField}
                        validate={required}
                    />
                </div>
                <div className="_m4 mv-10">
                    <Field
                        type="text"
                        name="pattern"
                        label="form.fieldLabel.pattern"
                        component={InputField}
                    />
                </div>
            </div>
        </ModalForm>
    )
}

export default reduxForm({
    form: "SettingsForm"
})(SettingsForm);