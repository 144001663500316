/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react';
import { connect } from "react-redux";

import { getUserInvoicesAction, getUserAction } from "../../../../redux/actions/usersActions";
import { getItemSelector, getListSelector, getTotalItemsSelector } from "../../../../utils/selectors";
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Table from "../../../../components/Table";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "bookingItems",
        title: "table.header.bookingItems",
        valueType: 'array'
    }
];

const UserInvoices = ({
    getUserAction,
    getUserInvoicesAction,
    user,
    invoices,
    isLoading,
    totalItems,
    match,
    history
}) => {

    const { id } = match.params;

    useEffect(() => {
        getUserAction(id);
    }, []);

    const getData = (params) => {
        getUserInvoicesAction({user: id, ...params});
    }

    const handleGoToView = id => {
        history.push(`${match.url}/view/${id}`);
    };

    const handleGenerateInvoice = (id) => {
        const BASE_URL = process.env.REACT_APP_API_HOST;
        const { download } = invoices.find(item => item.id === id)
        if (download) {
            window.open(`${BASE_URL}${download}`, '_blank');
        }
    }

    if (!user) return null;

    return (
        <Fragment>
            <div className="wrap ticket">
                <div className="_12">
                    <Breadcrumbs label="general.backToUsers" />
                    <h1>{`${user?.firstName} ${user?.lastName}`}</h1>
                </div>
            </div>
            <Table
                title="general.invoices"
                headerItems={TABLE_HEADER}
                data={invoices}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getData}
                actions={{
                    viewAction: handleGoToView,
                    generateFileAction: handleGenerateInvoice
                }}
            />
    </Fragment>
    )
}

const mapStateToProps = state => ({
    user: getItemSelector(state.user),
    invoices: getListSelector(state.userInvoices),
    isLoading: state.userInvoices.isLoading,
    totalItems: getTotalItemsSelector(state.userInvoices)
})

const mapDispatchToProps = {
    getUserInvoicesAction,
    getUserAction
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInvoices);
