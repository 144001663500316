import React from 'react'
import moment from 'moment';
import { useTranslate } from 'react-polyglot';

const CalendarDayLabels = ({
    size,
    currentMonth
}) => {
    const translate = useTranslate();

    function generateDayLabel(day) {
        const dayIndex = moment(day).format("d");
        const dayLabel = translate(`calendar.day${dayIndex}`)

        return dayLabel.slice(0, size === "big" ? 3 : 1);
    }


    let days = [];
    const startDate = moment(currentMonth).startOf('isoWeek');
    for (let i = 0; i < 7; i++) {
        const dayLabel = generateDayLabel(startDate.add(!!i ? 1 : 0, "days"));
        days.push(
            <div className="col col-center" key={i}>
                {dayLabel}
            </div>
        );
    }

    return <div className="days row">{days}</div>;
}

export default CalendarDayLabels;
