/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getDiscountsAction } from "../../../redux/actions/discountActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";
import AuthModalRoute from "../../../components/routes/AuthModalRoute";
import CreateDiscount from "./CreateDiscount";
import EditDiscount from "./EditDiscount";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "discountType.name",
        title: "table.header.discountType",
    },
    {
        key: "name",
        title: "table.header.name",
    },
    {
        key: "fromDate",
        title: "table.header.fromDate",
        valueType: "datetime",
    },
    {
        key: "toDate",
        title: "table.header.toDate",
        valueType: "datetime"
    },
    {
        key: "percentDiscount",
        title: "table.header.percentDiscount",
    },
];

const DiscountManagement = ({
    getDiscountsAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleCreateModal = () => {
        history.push(`${match.url}/create`);
    };

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`)
    };

    return (
        <Fragment>
            <Table
                title="general.menuItems.discounts"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getDiscountsAction}
                actions={{
                    editAction: handleEditModal,
                    addAction: handleCreateModal,
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create`}
                component={CreateDiscount}
            />
            <AuthModalRoute
                path={`${match.url}/edit/:id`}
                component={EditDiscount}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    isLoading: state.users.isLoading,
    data: getListSelector(state.discounts),
    totalItems: getTotalItemsSelector(state.discounts)
});

const mapDispatchToProps = {
    getDiscountsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountManagement)
