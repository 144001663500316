import { all } from "redux-saga/effects";
import authSaga from "./auth.saga";
import currentUserSaga from "./currentUser.saga";
import notificationsSaga from "./notifications.saga";
import translationSaga from "./translation.saga";
import rolesSaga from "./roles.saga";
import servicesSaga from "./services.saga";
import usersSaga from "./users.saga";
import ticketsSaga from "./tickets.saga";
import bookingsSaga from "./bookings.saga";
import discountsSaga from "./discounts.saga";
import inventoriesSaga from "./inventory.saga";
import pricesSaga from "./prices.saga";
import documentTypesSaga from "./documentTypes.saga";
import settingsSaga from "./settings.saga";
import shoppingCartSaga from "./shoppingCart.saga";
import eventSaga from "./events.saga";

export default function* registerSaga() {
    yield all([
        ...authSaga,
        ...currentUserSaga,
        ...notificationsSaga,
        ...translationSaga,
        ...rolesSaga,
        ...servicesSaga,
        ...usersSaga,
        ...ticketsSaga,
        ...bookingsSaga,
        ...discountsSaga,
        ...inventoriesSaga,
        ...pricesSaga,
        ...documentTypesSaga,
        ...settingsSaga,
        ...shoppingCartSaga,
        ...eventSaga
    ])
}