/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import { connect } from "react-redux";

import { getEventsAction } from "../../../redux/actions/eventsActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "name",
        title: "table.header.name",
    },
    {
        key: "description",
        title: "table.header.description"
    },
    {
        key: "approved",
        title: "table.header.approved",
        valueType: "boolean"
    }
];

const EventManagement = ({
    getEventsAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleGoToEdit = id => {
        history.push(`${match.url}/edit/${id}`);
    };

    return (
        <Fragment>
            <Table
                title="general.menuItems.events"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getEventsAction}
                actions={{
                    editAction: handleGoToEdit
                }}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    isLoading: state.events.isLoading,
    data: getListSelector(state.events),
    totalItems: getTotalItemsSelector(state.events)
});

const mapDispatchToProps = {
    getEventsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EventManagement);
