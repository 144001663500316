import React from 'react';
import PropTypes from 'prop-types';
import AdditionCard from './AdditionCard';

const AdditionCards = ({ additions, addToShoppingCart, filter }) => {
    return (
        <ul className="wrap m-list-container">
            {additions.map(item => <AdditionCard key={item.id} addition={item} addToShoppingCart={addToShoppingCart} filter={filter} />)}
        </ul>
    )
}

AdditionCards.propTypes = {
    additions: PropTypes.array.isRequired,
    addToShoppingCart: PropTypes.func.isRequired
}

export default AdditionCards;
