import {
    GET_PRICES,
    GET_PRICES_SUCCESS,
    GET_PRICE,
    GET_PRICE_SUCCESS,
    GET_PRICE_TYPES,
    GET_PRICE_TYPES_SUCCESS,
} from "../actions/priceActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const pricesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRICES:
            return { data: null, meta: null, isLoading: true };
        case GET_PRICES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const priceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRICE:
            return { data: null, meta: null, isLoading: true };
        case GET_PRICE_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const priceTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRICE_TYPES:
            return { data: null, meta: null, isLoading: true };
        case GET_PRICE_TYPES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

export {
    pricesReducer,
    priceReducer,
    priceTypesReducer,
};