export default {
    USER: "users",
    ROLE: "roles",
    FILE: "files",
    TICKET: "tickets",
    TICKET_STATUS: "ticket_statuses",
    TICKET_COMMENTS: "ticket_comments",
    SERVICE_PLAN: "service_plans",
    SERVICE_PLAN_LISTS: "service_plan_lists",
    SERVICE_PLAN_TYPE: "service_plan_types",
    SERVICE_PLAN_ITEM: "service_plan_items",
    SERVICE_PLAN_ITEM_TYPE: "service_plan_item_types",
    BOOKING: "bookings",
    BOOKING_ITEM: "booking_items",
    BOOKING_STATUS: "booking_statuses",
    BOOKING_ITEM_STATUSES: "booking_item_statuses",
    ADDITION: "additions",
    DISCOUNT: "discounts",
    DISCOUNT_TYPE: "discount_types",
    INVENTORY: "inventories",
    PRICE: "price_lists",
    PRICE_TYPE: "price_list_types",
    NOTIFICATION: "notifications",
    NOTIFICATION_TYPES: "notification_types",
    DOCUMENT: "documents",
    DOCUMENT_TYPES: "document_types",
    APP_SETTINGS: "app_settings",
    APP_SETTING_KEY: "app_setting_keys",
    LANGUAGE: "languages",
    CURRENCY: "currencies",
    USER_GENDER: "user_genders",
    EVENT: "booking_item_events",
    EVENT_USERS: "booking_item_event_users",
    EVENT_ANSWERS: "booking_item_event_answers",
    EVENT_INVITE: "booking_item_event_invite",
    TICKET_CATEGORIES: "ticket_categories",
}