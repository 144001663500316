import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import InputField from "../../../components/formFields/InputField";
import RadioGroupRelationship from "../../../components/formFields/RadioGroupRelationship";
import InputDate from "../../../components/formFields/InputDate";
import TextAreaField from "../../../components/formFields/TextAreaField";
import CheckboxField from "../../../components/formFields/CheckboxField";
import Button from "../../../components/Button";

import { required, isPasswordValid, isPhoneNumber } from "../../../utils/validation";
import { BUTTON_TYPES, CALENDAR_YEARS_PERIOD, INPUT_MODE } from "../../../constants/enums";
import ENTITY from "../../../constants/entities";

const RegisterUserForm = ({
    handleSubmit,
    onSubmit,
    translate
}) => {

    return (
        <form className="mt-25" onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap">
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="firstName"
                        label="form.fieldLabel.firstName"
                        validate={[required]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="lastName"
                        label="form.fieldLabel.lastName"
                        validate={[required]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="tel"
                        name="phoneNumber"
                        label="form.fieldLabel.phoneNumber"
                        validate={[required, isPhoneNumber]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="gender"
                        label="form.fieldLabel.gender"
                        component={RadioGroupRelationship}
                        validate={required}
                        entityType={ENTITY.USER_GENDER}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="password"
                        name="password"
                        label="form.fieldLabel.password"
                        validate={[required, isPasswordValid]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="password"
                        name="repeatPassword"
                        label="form.fieldLabel.confirmPassword"
                        validate={[required]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="birthDate"
                        label="form.fieldLabel.birthDate"
                        validate={[required]}
                        component={InputDate}
                        yearsPeriod={CALENDAR_YEARS_PERIOD.PREVIOUS}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="education"
                        label="form.fieldLabel.education"
                        validate={[required]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="profession"
                        label="form.fieldLabel.profession"
                        validate={[required]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="howDidYouHear"
                        label="form.fieldLabel.howDidYouHear"
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        name="otherDetails"
                        label="form.fieldLabel.otherDetails"
                        component={TextAreaField}
                        mode={INPUT_MODE.LIGHT}
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        name="readAcceptedTerms"
                        label="general.readAcceptedTerms"
                        validate={[required]}
                        component={CheckboxField}
                        mode={INPUT_MODE.LIGHT}
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        name="keepMeInformed"
                        label="general.keepMeInformed"
                        component={CheckboxField}
                        mode={INPUT_MODE.LIGHT}
                    />
                </div>
                <div className="_m12 mv-10">
                    <Button
                        type="submit"
                        btnClass={BUTTON_TYPES.FILLED}
                        label="form.button.createAnAccount"
                    />
                    <span className="info-message ml-10">{translate("general.iHaveAccount")}, </span>
                    <Link className="info-message" to="/login">{translate("general.loginLwrCase")}</Link>
                </div>
            </div >
        </form >
    )
}

const validate = (values, rest) => {
    const errors = {}
    if (values.password !== values.repeatPassword) {
        errors.repeatPassword = "form.error.unmatchingPassword"
    }
    return errors
}

export default reduxForm({
    form: "RegisterUserForm",
    validate,
})(RegisterUserForm)