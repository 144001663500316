/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from "react-polyglot";
import { getEventAction } from '../../../redux/actions/eventsActions'
import { getItemSelector, getIsLoading } from "../../../utils/selectors";
import EventForm from '../EventForm';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/Breadcrumbs';

const EditEvent = ({
    history,
    match,
    getEventAction,
    initialValues,
    isLoading
}) => {

    const translate = useTranslate();

    useEffect(() => {
        getEventAction(match.params.id, { include: "image,bookingItem,bookingItemEventUsers" });
    }, [])

    const onSubmit = data => {
    }

    if (isLoading) return <Loader />;

    return (
        <div>
            <div className="wrap event">
                <div className="_12">
                    <Breadcrumbs label="general.backToEvents" />
                    <h1>{translate("general.editEvent")}</h1>
                </div>
            </div>
            <EventForm
                onSubmit={onSubmit}
                submitLabel="general.editEvent"
                readOnly={true}
                inviteGuests={true}
                initialValues={initialValues} />
        </div>
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.event),
    isLoading: getIsLoading(state.event)
});

const mapDispatchToProps = {
    getEventAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
