export const GET_LANGUAGES = "@TRNSL/GET_LANGUAGES";
export const GET_LANGUAGES_SUCCESS = "@TRNSL/GET_LANGUAGES_SUCCESS";
export const getLanguagesAction = () => ({
    type: GET_LANGUAGES
});

export const SET_TRANSLATION = '@TRNSL/SET_TRANSLATION';
export const SET_TRANSLATION_ASYNC = '@TRNSL/SET_TRANSLATION_ASYNC';
export const setTranslation = language => ({
    type: SET_TRANSLATION,
    language
});