import React from 'react';
import { connect } from "react-redux";
import ENTITY from "../../../../../constants/entities";
import { changeBookingItemStatusAction } from "../../../../../redux/actions/bookingsActions";
import useFetchItemFromApi from '../../../../../utils/fetchItemFromApi';
import StatusForm from '../StatusForm';

const EditStatus = ({
    match,
    history,
    changeBookingItemStatusAction
}) => {
    const { id, bookingItemId } = match.params;

    const { data } = useFetchItemFromApi(
        ENTITY.BOOKING_ITEM,
        bookingItemId,
        { include: "status" }
    )

    const onSubmit = formData => {
        changeBookingItemStatusAction(id, formData);
    };

    const handleCancel = () => {
        history.goBack();
    };

    return (
        <StatusForm
            initialValues={data}
            onSubmit={onSubmit}
            handleCancel={handleCancel}
        />
    )
}

const mapDispatchToProps = {
    changeBookingItemStatusAction
};

export default connect(null, mapDispatchToProps)(EditStatus);
