import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getUsersDocumentsAction } from "../../../../redux/actions/usersActions";
import { getListSelector, getTotalItemsSelector } from "../../../../utils/selectors";

import Table from "../../../../components/Table";

import AuthModalRoute from "../../../../components/routes/AuthModalRoute";
import CreateDocument from "./CreateDocument";
import Breadcrumbs from "../../../../components/Breadcrumbs";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "name",
        title: "table.header.name",
    },
];

const UserDocuments = ({
    getUsersDocumentsAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const { id } = match.params;

    const handleCreateModal = () => {
        history.push(`${match.url}/create/${id}`);
    }

    const getUserDocuments = (params) => {
        getUsersDocumentsAction({ include: "documentType,file,user", user: id, ...params })
    }

    const handleGenerateDocument = (id) => {
        const BASE_URL = process.env.REACT_APP_API_HOST;
        const { file } = data.find(item => item.id === id)
        if (file && file.path) {
            window.open(`${BASE_URL}${file.path}`, '_blank');
        }
    }

    return (
        <Fragment>
            <div className="wrap ticket">
                <div className="_12">
                    <Breadcrumbs label="general.backToUsers" />
                </div>
            </div>
            <Table
                title="general.usersDocuments"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getUserDocuments}
                actions={{
                    addAction: handleCreateModal,
                    showDocumentsAction: handleGenerateDocument
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create/:id`}
                component={CreateDocument}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    isLoading: state.users.isLoading,
    data: getListSelector(state.usersDocuments),
    totalItems: getTotalItemsSelector(state.usersDocuments)
})

const mapDispatchToProps = {
    getUsersDocumentsAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDocuments);