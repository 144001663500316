import React from 'react';

const LayoutInvitation = ({ children }) => {
    return (
        <div className="main-container">
            <div className="wrapper event-invitation-container">
                {children}
            </div>
        </div>
    )
}

export default LayoutInvitation;
