/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { connect } from "react-redux";

import DocumentTypeForm from "../DocumentTypeForm";

import { createDocumentTypeAction } from "../../../../redux/actions/documentTypeActions";

const CreateBookingStatus = ({
    createDocumentTypeAction,
    tableParams,
    change,
    history,
}) => {

    const onSubmit = data => {
        createDocumentTypeAction(data, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <DocumentTypeForm
            title="general.createDocumentType"
            change={change}
            onSubmit={onSubmit}
            submitLabel="general.createDocumentType"
            handleCancel={handleCancel}
        />
    )
}

const mapStateToProps = state => ({
    tableParams: state.tableParams,
});

const mapDispatchToProps = {
    createDocumentTypeAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBookingStatus);