import { put, fork, takeLatest } from "redux-saga/effects";

import {
    GET_EVENTS,
    GET_EVENTS_SUCCESS,
    GET_MY_EVENTS,
    GET_MY_EVENTS_SUCCESS,
    GET_EVENT,
    GET_EVENT_SUCCESS,
    CREATE_EVENT,
    EDIT_EVENT,
    GET_EVENT_ANSWERS,
    GET_EVENT_ANSWERS_SUCCESS,
    INVITATION_RESPONSE,
    GET_EVENT_ANSWERS_REGISTRED,
    INVITATION_RESPONSE_REGISTRED
} from '../actions/eventsActions';

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history";
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";

const PATH = "/booking_item_events";
const INVITATION_PATH = "/booking_item_event_users";
const EVENT_ANSWERS_PATH = "/booking_item_event_answers";
const EVENT_INVITE_PATH = "/event_invite";
const EVENT_INVITE_ANSWER_PATH = "/event_invite_answer";


function* getEvents({ params }) {
    try {
        const url = formatUrl(PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_EVENTS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getMyEvents({ params }) {
    try {
        const url = formatUrl(PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_MY_EVENTS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getEvent({ id, params }) {
    try {
        const url = formatUrl(`${PATH}/${id}`, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_EVENT_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createEvent({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.EVENT, data);
        yield axiosClient(POST, PATH, preparedData);
        yield successHandler("notification.message.successCreatedEvent");
        history.goBack();
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editEvent({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.EVENT, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedEvent");
        history.goBack();
    } catch (error) {
        yield errorHandler(error);
    }
};

function* checkEventInvitation({ id }) {
    try {
        const url = `${EVENT_INVITE_ANSWER_PATH}/${id}`;
        const response = yield axiosClient(GET, url);
        if (response.status === 200) {
            const { data } = response;
            const isArray = Array.isArray(data);
            const answer = !isArray ? formatResponseData(data) : {};
            yield getEventAnswers({ answer });
        }
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getEventAnswers({ answer }) {
    try {
        const response = yield axiosClient(GET, EVENT_ANSWERS_PATH);
        const data = formatResponseData(response.data);
        yield put({ type: GET_EVENT_ANSWERS_SUCCESS, data, answer })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* invitationResponse({ id, data }) {
    try {
        const preparedData = formatRequestData(ENTITY.EVENT_USERS, data);
        yield axiosClient(PATCH, `${EVENT_INVITE_PATH}/${id}`, preparedData);
        yield checkEventInvitation({ id });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* invitationResponseRegistred({ id, data }) {
    try {
        const preparedData = formatRequestData(ENTITY.EVENT_USERS, data);
        yield axiosClient(PATCH, `${INVITATION_PATH}/${id}`, preparedData);
        history.go(0);
    } catch (error) {
        yield errorHandler(error);
    }
};

const getEventsAsync = fork(function* () { yield takeLatest(GET_EVENTS, getEvents) });
const getMyEventsAsync = fork(function* () { yield takeLatest(GET_MY_EVENTS, getMyEvents) });
const getEventAsync = fork(function* () { yield takeLatest(GET_EVENT, getEvent) });
const createEventAsync = fork(function* () { yield takeLatest(CREATE_EVENT, createEvent) });
const editEventAsync = fork(function* () { yield takeLatest(EDIT_EVENT, editEvent) });
const getEventAnswersAsync = fork(function* () { yield takeLatest(GET_EVENT_ANSWERS, checkEventInvitation) });
const invitationResponseAsync = fork(function* () { yield takeLatest(INVITATION_RESPONSE, invitationResponse) });
const getEventAnswersRegistredAsync = fork(function* () { yield takeLatest(GET_EVENT_ANSWERS_REGISTRED, getEventAnswers) });
const invitationResponseRegistredAsync = fork(function* () { yield takeLatest(INVITATION_RESPONSE_REGISTRED, invitationResponseRegistred) });

export default [
    getEventsAsync,
    getMyEventsAsync,
    getEventAsync,
    createEventAsync,
    editEventAsync,
    getEventAnswersAsync,
    invitationResponseAsync,
    getEventAnswersRegistredAsync,
    invitationResponseRegistredAsync
];

