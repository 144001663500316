import React from 'react';
import { TICKET_STATUSES } from '../../../../../constants/enums'

function renderTicketStatusBadge(id, name) {

    switch (id) {
        case TICKET_STATUSES.OPEN:
            return (
                <div className="-badge-open">
                    <span>{name}</span>
                </div>
            )
        case TICKET_STATUSES.IN_PROGRESS:
            return (
                <div className="-badge-in_progress">
                    <span>{name}</span>
                </div>
            )
        case TICKET_STATUSES.CLOSED:
            return (
                <div className="-badge-closed">
                    <span>{name}</span>
                </div>
            )
        default:
            return null;
    }
}

export default renderTicketStatusBadge
