/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { connect } from "react-redux";

import ServiceItemForm from "../ServiceItemForm";

import { createServicePlanItemAction } from "../../../../redux/actions/serviceActions";
import { getSettingsItemAction } from '../../../../redux/actions/settingsActions';
import { getItemSelector } from "../../../../utils/selectors";
import { APP_SETTING_KEYS } from '../../../../constants/enums';

const CreateServiceItem = ({
    createServicePlanItemAction,
    getSettingsItemAction,
    defaultLanguage,
    tableParams,
    change,
    history
}) => {

    useEffect(() => {
        getSettingsItemAction(APP_SETTING_KEYS.DEFAULT_LANGUAGE);
    }, [])

    const onSubmit = data => {
        createServicePlanItemAction(data, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <ServiceItemForm
            title="general.createServiceItem"
            change={change}
            onSubmit={onSubmit}
            submitLabel="general.createServiceItem"
            handleCancel={handleCancel}
            defaultLanguage={defaultLanguage}
        />
    )
}

const mapStateToProps = state => ({
    tableParams: state.tableParams,
    defaultLanguage: getItemSelector(state.settingsItem)
});

const mapDispatchToProps = {
    createServicePlanItemAction,
    getSettingsItemAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateServiceItem);