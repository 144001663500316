import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from "react-polyglot";
import Button from '../Button';
import { BUTTON_TYPES } from '../../constants/enums';
import DateTimePicker from '../formFields/DateTimePicker';

const DateAndTime = ({ 
    borderTop, 
    borderBottom, 
    date, 
    handleChangeDate,
    error,
    action,
    btnLabel,
}) => {

    const translate = useTranslate();

    return (
        <Fragment>
            <div className="wrap">
                <div className="_12">
                    { borderTop && <span className="a-line"></span> }
                    <p className="m-date-and-time-title">{translate('general.dateAndTime')}</p>
                    <p className="m-date-and-time-description">{translate('general.chooseDateAndTime')}</p>
                </div>
            </div>
            <div className="wrap date-time">
                <div className="_xl3 _l4 _m4 _s6 _xs8">
                    <DateTimePicker 
                        input={{onChange: handleChangeDate,
                                value: date}}
                        err={error}
                    />
                </div>
                { action && btnLabel &&
                    <div className="_xl9 _l8 _m8 _s6">
                        <Button 
                            btnClass={BUTTON_TYPES.SECONDARY}
                            label={btnLabel} 
                            onClick={action} 
                        />
                    </div>
                }
            </div>
            { borderBottom && 
             <div className="wrap">
                <div className="_12">
                    <span className="a-line"></span>
                </div>
            </div>}
        </Fragment>
    )
}

DateAndTime.propTypes = {
    borderTop: PropTypes.bool,
    borderBottom: PropTypes.bool,
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    handleChangeDate: PropTypes.func.isRequired,
    error: PropTypes.string,
    action: PropTypes.func,
    btnLabel: PropTypes.string
}

export default DateAndTime;
