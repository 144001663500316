/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getPricesAction } from "../../../redux/actions/priceActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";
import AuthModalRoute from "../../../components/routes/AuthModalRoute";
import CreatePrice from "./CreatePrice";
import EditPrice from "./EditPrice";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "priceListType.name",
        title: "table.header.priceType",
    },
    {
        key: "name",
        title: "table.header.name",
    },
    {
        key: "price",
        title: "table.header.price",
    }
];

const PricesManagement = ({
    getPricesAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleCreateModal = () => {
        history.push(`${match.url}/create`);
    };

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`);
    };

    return (
        <Fragment>
            <Table
                title="general.menuItems.priceList"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getPricesAction}
                actions={{
                    editAction: handleEditModal,
                    addAction: handleCreateModal,
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create`}
                component={CreatePrice}
            />
            <AuthModalRoute
                path={`${match.url}/edit/:id`}
                component={EditPrice}
            />
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.prices.isLoading,
    data: getListSelector(state.prices),
    totalItems: getTotalItemsSelector(state.prices)
});

const mapDispatchToProps = {
    getPricesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PricesManagement)
