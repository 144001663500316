import React, { useState } from "react"

import TicketItem from "./TicketItem";

const TicketList = ({
    data,
}) => {

    const [selectedTicket, setSelectedTicket] = useState(0);

    if (data.length === 0) return "No Items";

    return (
        data.map(item => {
            return (
                <TicketItem
                    key={item._id}
                    ticket={item}
                    selectedTicketId={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                />
            )
        })
    )
}

export default TicketList;
