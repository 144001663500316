import React from "react"
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";

import TicketForm from "../TicketForm";
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { createTicketAction } from "../../../../redux/actions/ticketsActions";

const CreateTicket = ({
    createTicketAction,
    tableParams
}) => {
    const translate = useTranslate();

    const onSubmit = data => {
        createTicketAction(data, tableParams);
    }

    return (
        <div>
            <div className="wrap ticket">
                <div className="_12">
                    <Breadcrumbs label="general.backToTickets" />
                    <h1>{translate("general.createTicket")}</h1>
                </div>
            </div>
            <TicketForm
                onSubmit={onSubmit}
                submitLabel="general.createTicket"
            />
        </div>
    )
}

const mapStateToProps = state => ({
    tableParams: state.tableParams
});

const mapDispatchToProps = {
    createTicketAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket);