/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, Fragment } from 'react';
import { useTranslate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import { BUTTON_TYPES } from '../../constants/enums';
import Backdrop from '../../components/Backdrop';
import DateTimePicker from '../../components/formFields/DateTimePicker';
import { getPriceListIdByFilterValue } from '../../utils/checkValue';
import { calculatePriceWithCurrency } from '../../utils/calculatePrice';
import clickOutsideHOC from '../../components/wrappers/clickOutsideHOC';
import { BASE_URL } from '../../utils/axiosClient';

const AdditionModal = ({
    addition,
    filter,
    handleCancel,
    handleSubmit,
    setSubmittedAddition
}) => {
    const translate = useTranslate();
    const modalRef = useRef(null);

    const [date, setDate] = useState("");
    const [error, setError] = useState("");

    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(addition, filter);

    const handleChangeDate = (value) => {
        setDate(value);
    }

    const handleAddToShoppingCart = () => {
        if (date) {
            setSubmittedAddition(true);
            const priceListId = getPriceListIdByFilterValue(addition, filter);
            handleSubmit(
                { ...addition, fromDate: date, filter: filter, priceListId: priceListId }
            );
        } else {
            setError('form.error.required');
        }
    }

    if (!addition) return null;

    return (
        <Fragment>
            <div className={`modal show`}>
                <Backdrop handleClose={handleCancel} />
                <div className="modal-container">
                    <span onClick={handleCancel} className='close'>&times;</span>
                    <div ref={modalRef} className='modal-special-content'>
                        <div className="additional-options-container">
                            <img alt="add" src={`${BASE_URL}${addition?.image?.path}`} />
                            <div className="content">
                                <div className="info-action">
                                    <div className="info">
                                        <p className="name">{translate(`${addition.type}.${addition.id}.name`)}</p>
                                        <div className="price">
                                            <p className="-big value">{`+ ${discount ? priceWithDiscount : price}`}</p>
                                            <p className="-big period">{`/ ${filter?.name?.toLowerCase()}`}</p>
                                        </div>
                                        {discount &&
                                            <div className="a-discount-container">
                                                <span className="price">{`+ ${price}`}</span>
                                                <span className="a-discount">{`- ${discount}%`}</span>
                                            </div>
                                        }
                                    </div>
                                    <Button btnClass={BUTTON_TYPES.SECONDARY} label="general.add" onClick={handleAddToShoppingCart} />
                                </div>
                                <DateTimePicker
                                    input={{
                                        onChange: handleChangeDate,
                                        value: date
                                    }}
                                    err={!date && error ? error : ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

AdditionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    addition: PropTypes.object,
    handleCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
    error: PropTypes.string,
    setError: PropTypes.func
}

const AdditionModalHoc = clickOutsideHOC(AdditionModal);

export default AdditionModalHoc;
