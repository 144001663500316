import React from 'react';
import { connect } from 'react-redux';
import { useTranslate } from "react-polyglot";
import { createEventAction } from '../../../redux/actions/eventsActions';
import '../EventForm';
import EventForm from '../EventForm';
import ENTITY from '../../../constants/entities';
import Breadcrumbs from '../../../components/Breadcrumbs';

const CreateAnEvent = ({
    history,
    createEventAction
 }) => {

    const translate = useTranslate();

    const onSubmit = ({ name, description, image, bookingItem }) => {
        const dataToSubmit = {
            name,
            description,
            image,
            bookingItem: {id: bookingItem.id, type: ENTITY.BOOKING_ITEM}
        }
        createEventAction(dataToSubmit);
    }

    return (
        <div>
            <div className="wrap event">
                <div className="_12">
                    <Breadcrumbs label="general.backToEvents" />
                    <h1>{translate("general.createEvent")}</h1>
                </div>
            </div>
            <EventForm
                onSubmit={onSubmit}
                submitLabel="general.createEvent"
                readOnly={false}
                inviteGuests={false} />
        </div>
    )
}

const mapDispatchToProps = {
    createEventAction
};

export default connect(null, mapDispatchToProps)(CreateAnEvent);