// NOTE: Finish translation of errors
export const required = value => value ? undefined : "form.error.required"

export const arrayEmpty = value => !value || !value.length ? "form.error.required" : undefined

const alphaNumericRegex = /[^A-Za-z0-9]+/g;
export const isAlphaNumeric = value => {
    return value && RegExp(alphaNumericRegex).test(value) ?
        "Must contain only numbers and letters!" :
        undefined
};

export const fixedLength = (len, message = `Must be ${len} characters long`) => value => {
    return value && ("" + value).length !== len ?
        message :
        undefined
};

export const maxLength = (max, message = `Can not be more than ${max} characters long`) => value => value && value.length > max ?
    message :
    undefined;

export const maxLength1 = maxLength(1);
export const maxLength4 = maxLength(4);

export const minLength = (min, message = `Can not be less than ${min} characters long`) => (value) => {
    return value && value.length < min ?
        message :
        undefined;
}

export const minLength1 = minLength(1);
export const minLength4 = minLength(4);

export const isNumber = value => value && isNaN(Number(value)) ?
    "Must be a number" :
    undefined;

export const minValue = (min, message = "Number too low, must be higher than ") => value => value && value < min ?
    `${message}${min}` :
    undefined;

export const maxValue = (max, message = "Number too high, must be lower than ") => value => value && value > max ?
    `${message}${max}` :
    undefined;

export const minValue0 = minValue(0);
export const maxValue30 = maxValue(30);

const emailRegex = /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
export const isValidEmail = value => {
    return value && !RegExp(emailRegex).test(value) ?
        "form.error.invalidEmail" :
        undefined
};

export const phoneNumberRegex = /^((\+|0)[0-9]{7,12})/;
export const isPhoneNumber = value => value && !RegExp(phoneNumberRegex).test(value) ?
    "form.error.invalidPhone" : undefined;

export const isPasswordValid = value => {
    let regex = /[0-9]/;
    if (value.length < 6) {
        return "form.error.passwordLength"
    }
    if (!regex.test(value)) {
        return "form.error.passwordMustContainNumber"
    }
    regex = /[a-z]/;
    if (!regex.test(value)) {
        return "form.error.passwordMustContainLowerLetter"
    }
    regex = /[A-Z]/;
    if (!regex.test(value)) {
        return "form.error.passwordMustContainUpperLetter";
    }
    regex = /[!@#$%^&*)([\]{}\\|/+=._-]/;
    if (!regex.test(value)) {
        return "form.error.passwordMustContainSpecialCharacter";
    }
    return undefined;
}

export const accessTypeRegex = /(^\d{2}-\d{2}$)/;
export const isAccessType = value => {
    if (!value) return undefined;
    if (value && !RegExp(accessTypeRegex).test(value)) {
        return 'Must be format ex. "06-19"'
    }
    const [first, second] = value.split('-');
    if (Number(first) >= Number(second)) {
        return 'First number must be smaller ex. "06-19"';
    }
}