import React from 'react';
import { useTranslate } from "react-polyglot";
import { BUTTON_TYPES } from '../../constants/enums';
import Button from '../../components/Button';

const NoActivePackage = ({ label, history }) => {

    const translate = useTranslate();

    const handleClick = () => {
        history.push('/package-catalog');
    }

    return (
        <div className="m-no-active-package">
            <div className="no-active-img"></div>
            <p className="title">{translate('general.noActivePackages')}</p>
            <p className="description">{translate(label)}</p>
            <Button btnClass={BUTTON_TYPES.SECONDARY} label="general.bookAPackage" onClick={handleClick} />
        </div> 
    )
}

export default NoActivePackage;
