/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment, useState } from 'react';
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";
import { getListSelector, getItemSelector, getIsLoading } from "../../utils/selectors";
import { getServicePlanListsAction, resetServicePlanListsAction } from "../../redux/actions/serviceActions";
import { getPriceTypesAction } from "../../redux/actions/priceActions";
import { addAdditionToShoppingCartAction } from "../../redux/actions/shoppingCartActions";
import { SERVICE_TYPES } from '../../constants/enums';
import Loader from '../../components/Loader';
import ButtonGroup from '../../components/ButtonGroup';
import AdditionCards from './AdditionCards';
import NoActivePackage from '../../components/NoActivePackage';
import NoContent from '../../components/NoContent';

const AdditionCatalog = ({ 
    additions, 
    getServicePlanListsAction, 
    addAdditionToShoppingCartAction,
    resetServicePlanListsAction,
    getPriceTypesAction,
    priceTypes,
    isLoading,
    currentUser,
    history
}) => {

    const translate = useTranslate();
    const [option, setOption] = useState(null);

    const { language, hasActivePackage } = currentUser;

    useEffect(() => {
        if (hasActivePackage) {
            getServicePlanListsAction({include: 'userCurrency,image,discount', planType: SERVICE_TYPES.ADDITION});
            getPriceTypesAction();
        }
        return () => {
            resetServicePlanListsAction();
        }
    }, [language.code])

    
    if (!hasActivePackage) return (
        <div>
            <div className="content-container-header">
                <div className="title">
                    <h1>{translate("general.additionalPerks")}</h1>
                    <p className="-regular">{translate('general.additionalPerksTitle')}</p>
                </div>
            </div>
            <NoActivePackage label="general.noActivePackagesAdditionsDescription" history={history}/>
        </div>
    );

    if (isLoading) return <Loader />;

    return (
        <Fragment>
            <div>
                <div className="wrap">
                    <div className="_l12 content-container-header">
                        <div className="title">
                            <h1>{translate("general.additionalPerks")}</h1>
                            <p className="-regular">{translate('general.additionalPerksTitle')}</p>
                        </div>
                    </div>
                    <div className="_m12 content-container-header">
                        { additions.length > 0 ? 
                            <ButtonGroup 
                                options={priceTypes}
                                selectedOption={option || priceTypes[0]} 
                                setOption={setOption}
                                btnGroupClass="mini"  /> : null }
                    </div>
                </div>
                { additions.length > 0 ?
                    <AdditionCards additions={additions} addToShoppingCart={addAdditionToShoppingCartAction} filter={option || priceTypes[0]} /> :
                    <NoContent label='general.noAdditions' />
                }
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    additions: getListSelector(state.servicePlanLists),
    priceTypes: getListSelector(state.priceTypes),
    isLoading: getIsLoading(state.servicePlanLists),
    currentUser: getItemSelector(state.currentUser)
})

const mapDispatchToProps = {
    getServicePlanListsAction,
    addAdditionToShoppingCartAction,
    getPriceTypesAction,
    resetServicePlanListsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionCatalog)
