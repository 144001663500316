import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Loader from '../Loader';

import { useTranslate } from 'react-polyglot';
import { INPUT_MODE } from '../../constants/enums';
import useFetchOptionsFromApi from './hooks/fetchOptionsFromApi';

const ITEMS_PER_PAGE = 100;

const SelectFieldRelationship = ({
    input,
    label,
    onSelect,
    entityType,
    readOnly,
    mode,
    required,
    meta: { touched, error }
}) => {
    const hasError = touched && error && !required;
    const translate = useTranslate();
    const attributeName = input?.name?.split('.')[0];

    const { options, isLoading } = useFetchOptionsFromApi(
        entityType,
        {
            page: 1,
            itemsPerPage: ITEMS_PER_PAGE,
        });

    useEffect(() => {
        onSelect(attributeName, {
            id: input.value ? input.value : required ? options[0]?.id : "",
            type: entityType
        });
    }, [onSelect, options, attributeName, input, entityType, required]) // [input.value]

    const filled = (input.value && input.value !== "") || required;

    return (
        <div className='a-input-group'>
            <select
                {...input}
                className={`a-select -${mode} ${filled ? "-filled" : ""} ${hasError ? "-error" : ""}`}
                disabled={readOnly}
                id={input.name}
            >
                {!required && <option value="" hidden></option>}
                {options.map(({ _id, name }) => (
                    <option
                        key={_id}
                        value={_id}
                    >
                        {name}
                    </option>
                ))}
            </select>
            <label htmlFor={input.name} className={`-${mode} ${required ? "-required" : ""}`}>{translate(label)}</label>
            {isLoading ?
                <div className="a-select-loader">
                    <Loader type="mini" />
                </div>
                : <i className="select-arrow"></i>}
            {hasError && <span className="a-select-error">{translate(error)}</span>}
        </div>
    )
};

SelectFieldRelationship.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    entityType: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    mode: PropTypes.oneOf([
        INPUT_MODE.DARK,
        INPUT_MODE.LIGHT
    ]),
    required: PropTypes.bool,
}

SelectFieldRelationship.defaultProps = {
    input: {},
    meta: {},
    mode: INPUT_MODE.DARK,
    required: false,
}

export default SelectFieldRelationship;