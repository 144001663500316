import React from 'react';
import { Field, reduxForm } from "redux-form";
import SelectFieldRelationship from '../../../../../components/formFields/SelectFieldRelationship';
import ModalForm from '../../../../../components/ModalForm';
import ENTITY from "../../../../../constants/entities";
import { required } from "../../../../../utils/validation";

const StatusForm = ({
    change,
    handleSubmit,
    onSubmit,
    handleCancel,
    initialValues
}) => {
    return (
        <ModalForm
            open
            title="general.changeStatus"
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel="general.changeStatus"
            cancelLabel="form.button.cancel"
        >
            <div className="wrap">
                <div className="_12 mv-10">
                    <Field
                        name="status.id"
                        label="form.fieldLabel.status"
                        onSelect={change}
                        entityType={ENTITY.BOOKING_ITEM_STATUSES}
                        component={SelectFieldRelationship}
                        validate={required}
                        required
                    />
                </div>
            </div>
        </ModalForm>
    )
}

export default reduxForm({ form: "BookingItemStatusForm", enableReinitialize: true })(StatusForm);
