import {
    GET_INVENTORIES,
    GET_INVENTORIES_SUCCESS,
    GET_INVENTORY,
    GET_INVENTORY_SUCCESS
} from "../actions/inventoryActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const inventoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVENTORIES:
            return { data: null, meta: null, isLoading: true };
        case GET_INVENTORIES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVENTORY:
            return { data: null, meta: null, isLoading: true };
        case GET_INVENTORY_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

export {
    inventoriesReducer,
    inventoryReducer,
};