import React, { Fragment } from 'react'
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";

import SearchField from './SearchField';
import Button from '../../Button';
import { BUTTON_TYPES, BUTTON_ICON_POSITIONS } from '../../../constants/enums';
import ENTITY from "../../../constants/entities";
import InputDate from '../../formFields/InputDate';
import Icon from '../../Icon';
import moment from 'moment';
import SelectFieldRelationship from '../../formFields/SelectFieldRelationship';
import _ from 'lodash'

const TableToolbar = ({
    title,
    searchable,
    addAction,
    filterByDate,
    filterByStatus,
    filters,
    setFilters,
    setPage
}) => {
    const translate = useTranslate();

    const handleChangeDebounce = _.debounce((value) => {
        setPage(1)
        if (value) {
            setFilters({...filters, search: value})
        } else {
            const { search, ...restFilters } = filters
            setFilters(restFilters)
        }
    }, 1000)

    const handleSearch = event => {
        handleChangeDebounce(event.target.value)
    };

    const handleChangeDate = (value) => {
        if (value !== filters.date) {
            setPage(1)
            if (value) {
                const formattedValue = moment.utc(value).format('YYYY-MM-DD')
                setFilters({...filters, date: formattedValue})
            } else {
                const { date, ...restFilters } = filters
                setFilters(restFilters)
            }
        }
    }

    const handleChangeStatus = (event) => {
        const { value } = event.target
        setFilters({...filters, status: value})
    }

    const handleRemoveFilter = () => {
        if (filters.date || filters.status) {
            const { date, status, ...restFilters } = filters
            setPage(1)
            setFilters(restFilters)
        }
    }

    return (
        <div className="table-toolbar">
            <h1>{translate(title)}</h1>
            <div className="table-action-bar">
                {searchable && <SearchField
                    placeholder={translate("general.search")}
                    onChange={handleSearch}
                />}
                {addAction &&
                    <Button
                        label="general.addNew"
                        onClick={addAction}
                        btnClass={BUTTON_TYPES.FILLED}
                        icon="circle-plus"
                        iconPosition={BUTTON_ICON_POSITIONS.LEFT}
                    />
                }
            </div>
            <div className="table-filters-bar">
                <Fragment>
                    {filterByDate && <div className="table-filter">
                        <span className='label'>Filter by: </span>
                        <InputDate 
                            input={{onChange: handleChangeDate,
                                    value: filters.date || ''}}
                        />
                    </div>}
                    {filterByStatus && <div className="table-filter">
                        <span className='label'>Filter by: </span>
                        <SelectFieldRelationship
                            name="status.id"
                            label="form.fieldLabel.status"
                            input={{
                                onChange: handleChangeStatus,
                                value: filters.status || ''
                            }}
                            onSelect={() => {}}
                            entityType={ENTITY.BOOKING_ITEM_STATUSES}
                        />
                    </div>}
                    {(filterByDate || filterByStatus) && <span onClick={handleRemoveFilter} className='close'>
                        <Icon name="close" />
                    </span>}
                </Fragment>
            </div>
        </div>
    )
}

TableToolbar.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    searchable: PropTypes.bool,
    addAction: PropTypes.func,
    filterByDate: PropTypes.bool,
    filterByStatus: PropTypes.bool
}

export default TableToolbar;
