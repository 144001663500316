import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SideBarItem = ({ label, to, onClick, icon, active }) => {
    const handleClick = () => {
        if (onClick && typeof onClick === 'function') {
            onClick();
        }
    }
    return (
        <Link
            className={`m-sidenav-item ${active ? ' -active' : ''}`}
            onClick={handleClick}
            to={to ? to : '#'}>
            <i className="dot"></i>
            {icon}
            <span>{label}</span>
        </Link>
    )
}

SideBarItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    action: PropTypes.func
}

export default SideBarItem;