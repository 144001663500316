/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { FieldArray, Field, reduxForm, arrayPush } from 'redux-form';
import { useTranslate } from "react-polyglot";
import Button from '../../../../components/Button';
import InputFieldArray from '../../../../components/formFields/InputFieldArray';
import ENTITY from '../../../../constants/entities';
import { BUTTON_TYPES } from '../../../../constants/enums';
import { createBookingAction } from '../../../../redux/actions/bookingsActions';
import { getDiscountsAction } from '../../../../redux/actions/discountActions';
import { getBookingStatusesAction } from '../../../../redux/actions/bookingsActions';
import { DISCOUNT_TYPES } from '../../../../constants/enums';
import { getIsLoading, getListSelector } from "../../../../utils/selectors";
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Loader from '../../../../components/Loader';
import SelectSearchField from '../../../../components/formFields/SelectSearchField';
import { required } from '../../../../utils/validation';

const CreateBooking = ({
    handleSubmit, 
    pushArray,
    createBookingAction,
    getDiscountsAction,
    getBookingStatusesAction,
    discounts,
    bookingStatuses,
    tableParams,
    isLoading
}) => {
    const translate = useTranslate();
    useEffect(() => {
        pushArray('BookingForm', 'servicePlans', {key: Math.random() * 1000});

        getDiscountsAction({ discountType: DISCOUNT_TYPES.SERVICE });
    }, [])

    const onSubmit = (data) => {
        let dataFormated = {
            user: data.user,
            servicePlans: data.servicePlans.map(({fromDate, discountId, servicePlan: {id, selectedPriceList}}) => (
                {
                    id,
                    // isFree: true,
                    priceListId: selectedPriceList,
                    fromDate,
                    discountId
                }
            ))
        }
        createBookingAction(dataFormated, tableParams);
    }

    if (isLoading) return <Loader />;

    return (
        <div className="booking">
            <div className="wrap header">
                <div className="_12">
                    <Breadcrumbs label="general.backToBookings"/>
                    <h1>{translate("general.createNewBooking")}</h1>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="wrap">
                    <div className="_12">
                        <p className="title">{translate('general.chooseUser')}</p>
                        <Field
                            name="user"
                            label="form.fieldLabel.email"
                            entityType={ENTITY.USER}
                            component={SelectSearchField}
                            params={{ include: 'image' }}
                            searchable={true}
                            validate={required}
                            required
                        />
                    </div>
                </div>
                <div className="wrap">
                    <div className="_12">
                        <span className="a-line"></span>
                        <p className="title">{translate('general.chooseServicePlan')}</p>
                        <FieldArray 
                            name="servicePlans" 
                            component={InputFieldArray}
                            entityType={ENTITY.SERVICE_PLAN}
                            btnLabel="general.chooseMoreServicePlan"
                            discounts={discounts}
                            // statuses={bookingStatuses}
                        />
                    </div>
                </div>
                <div className="wrap btn">
                    <div className="_12">
                        <Button type="submit" label="general.createBooking" btnClass={BUTTON_TYPES.FILLED}/>
                    </div>
                </div>
            </form>
        </div>
    )
}


const mapStateToProps = state => ({
    discounts: getListSelector(state.discounts),
    bookingStatuses: getListSelector(state.bookingStatuses),
    tableParams: state.tableParams,
    isLoading: getIsLoading(state.discounts)
});

const mapDispatchToProps = {
    pushArray: arrayPush,
    createBookingAction,
    getDiscountsAction,
    getBookingStatusesAction
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "BookingForm", enableReinitialize: true })(CreateBooking));


