export const GET_USERS = '@USR/GET_USERS';
export const GET_USERS_SUCCESS = '@USR/GET_USERS_SUCCESS';
export const getUsersAction = params => ({
    type: GET_USERS,
    params
});

export const GET_USER = '@USR/GET_USER';
export const GET_USER_SUCCESS = '@USR/GET_USER_SUCCESS';
export const getUserAction = (id, params) => ({
    type: GET_USER,
    id,
    params
})

export const CREATE_USER = '@USR/CREATE_USER';
export const createUserAction = (data, params) => ({
    type: CREATE_USER,
    data,
    params
});

export const EDIT_USER = '@USR/EDIT_USER';
export const editUserAction = (data, params) => ({
    type: EDIT_USER,
    data,
    params
});

export const DELETE_USER = '@USR/DELETE_USER';
export const deleteUserAction = (data, params) => ({
    type: DELETE_USER,
    data,
    params
});

export const GET_USERS_DOCUMENTS = '@USR/GET_USERS_DOCUMENTS';
export const GET_USERS_DOCUMENTS_SUCCESS = '@USR/GET_USERS_DOCUMENTS_SUCCESS';
export const getUsersDocumentsAction = (params) => ({
    type: GET_USERS_DOCUMENTS,
    params
})

export const ADD_DOCUMENT_TO_USER = '@USR/ADD_DOCUMENT_TO_USER';
export const addDocumentToUserAction = (data, params) => ({
    type: ADD_DOCUMENT_TO_USER,
    data,
    params
})
export const GET_USER_INVOICES = '@USR/GET_USER_INVOICES';
export const GET_USER_INVOICES_SUCCESS = '@USR/GET_USER_INVOICES_SUCCESS';
export const getUserInvoicesAction = params => ({
    type: GET_USER_INVOICES,
    params
})
