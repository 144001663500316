import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_DISCOUNTS,
    GET_DISCOUNTS_SUCCESS,
    GET_DISCOUNT,
    GET_DISCOUNT_SUCCESS,
    CREATE_DISCOUNT,
    EDIT_DISCOUNT
} from "../actions/discountActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history";
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";

const PATH = "/discounts";

function* getDiscounts({ params }) {
    try {
        const url = formatUrl(PATH, { include: "discountType", ...params });
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_DISCOUNTS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getDiscount({ id, params }) {
    try {
        const url = formatUrl(`${PATH}/${id}`, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_DISCOUNT_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createDiscount({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.DISCOUNT, data);
        yield axiosClient(POST, PATH, preparedData);
        yield successHandler("notification.message.successCreatedDiscount");
        history.goBack();
        yield getDiscounts({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editDiscount({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.DISCOUNT, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedDiscount");
        history.goBack();
        yield getDiscounts({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

const getDiscountsAsync = fork(function* () { yield takeLatest(GET_DISCOUNTS, getDiscounts) });
const getDiscountAsync = fork(function* () { yield takeLatest(GET_DISCOUNT, getDiscount) });
const createDiscountAsync = fork(function* () { yield takeLatest(CREATE_DISCOUNT, createDiscount) });
const editDiscountAsync = fork(function* () { yield takeLatest(EDIT_DISCOUNT, editDiscount) });

export default [
    getDiscountsAsync,
    getDiscountAsync,
    createDiscountAsync,
    editDiscountAsync,
];

