import React from "react";
import { ROLES } from "../../../constants/enums";

import Icon from '../../Icon';

const USER_MENU_ITEMS = [
    {
        key: "package_catalog",
        label: "general.menuItems.packageCatalog",
        icon: <Icon name="package" />,
        to: "/package-catalog",
    },
    {
        key: "service_catalog",
        label: "general.menuItems.serviceCatalog",
        icon: <Icon name="services" />,
        to: "/service-catalog",
        secondPath: "/service-catalog/book-a-service/:id"
    },
    {
        key: "additions_catalog",
        label: "general.menuItems.additionsCatalog",
        icon: <Icon name="additions" />,
        to: "/addition-catalog"
    },
    {
        key: "tickets",
        label: "general.menuItems.tickets",
        icon: <Icon name="tickets" />,
        to: "/my-tickets",
    },
    {
        key: "events_catalog",
        label: "general.menuItems.eventsAsGuest",
        icon: <Icon name="events" />,
        to: "/events-catalog",
    },
    {
        key: "my_events",
        label: "general.menuItems.eventsAsHost",
        icon: <Icon name="my-events" />,
        to: "/my-events",
    },
    {
        key: "bookings",
        icon: <Icon name="file" />,
        label: "general.menuItems.myBookings",
        to: "/my-bookings",
    }
];

const ADMIN_MENU_ITEMS = [
    {
        key: "bookings",
        icon: <Icon name="booking" />,
        label: "general.menuItems.bookings",
        to: "/bookings",
    },
    {
        key: "service_item",
        icon: <Icon name="services" />,
        label: "general.menuItems.serviceItems",
        to: "/service-item",
    },
    {
        key: "service_plan",
        label: "general.menuItems.servicePlans",
        icon: <Icon name="service-plan" />,
        to: "/service-plan",
    },
    {
        key: "users",
        label: "general.menuItems.users",
        icon: <Icon name="users" />,
        to: "/users",
    },
    {
        key: "tickets",
        label: "general.menuItems.tickets",
        icon: <Icon name="tickets" />,
        to: "/tickets",
    },
    {
        key: "discounts",
        label: "general.menuItems.discounts",
        icon: <Icon name="discounts" />,
        to: "/discounts"
    },
    {
        key: "prices",
        label: "general.menuItems.priceList",
        icon: <Icon name="prices" />,
        to: "/prices"
    },
    {
        key: "inventory",
        label: "general.menuItems.inventory",
        icon: <Icon name="package" />,
        to: "/inventory"
    },
    {
        key: "events",
        label: "general.menuItems.events",
        icon: <Icon name="events" />,
        to: "/events"
    },
    {
        key: "documentTypes",
        label: "general.menuItems.documentTypes",
        icon: <Icon name="document" />,
        to: "/document-types"
    },
    {
        key: "settings",
        label: "general.menuItems.settings",
        icon: <Icon name="settings" />,
        to: "/settings"
    }
];

const useMenuItems = userRoleName => {
    switch (userRoleName) {
        case ROLES.USER.NAME:
            return USER_MENU_ITEMS;
        case ROLES.MANAGER.NAME:
        case ROLES.ADMIN.NAME:
            return ADMIN_MENU_ITEMS;
        default:
            return null;
    }
};

export default useMenuItems;