import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_USERS,
    GET_USERS_SUCCESS,
    DELETE_USER,
    GET_USER,
    GET_USER_SUCCESS,
    EDIT_USER,
    CREATE_USER,
    GET_USERS_DOCUMENTS,
    GET_USERS_DOCUMENTS_SUCCESS,
    ADD_DOCUMENT_TO_USER,
    GET_USER_INVOICES,
    GET_USER_INVOICES_SUCCESS,
} from "../actions/usersActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history"
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";

const PATH = "/users";
const DOCUMENTS_PATH = "/documents";
const USER_INVOICES_PATH = "/user_invoices";

function* getUsers({ params }) {
    try {
        const url = formatUrl(PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_USERS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getUser({ id, params }) {
    try {
        const url = formatUrl(`${PATH}/${id}`, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_USER_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
}

function* deleteUser({ data, params }) {
    try {
        data = {
            ...data,
            active: false
        };
        const preparedData = formatRequestData(ENTITY.USER, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successDeactivatedUser");
        yield getUsers({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createUser({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.USER, data);
        yield axiosClient(POST, PATH, preparedData);
        yield successHandler("notification.message.successCreatedUserByAdmin");
        yield getUsers({ params });
        history.goBack();
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editUser({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.USER, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedUser");
        history.goBack();
        yield getUsers({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getUsersDocuments({ params }) {
    try {
        const url = formatUrl(DOCUMENTS_PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_USERS_DOCUMENTS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
}

function* addDocumentToUser({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.DOCUMENT, data);
        yield axiosClient(POST, DOCUMENTS_PATH, preparedData);
        yield successHandler("notification.message.successAddedDocument");
        yield getUsersDocuments({ params });
        history.goBack();
    } catch (error) {
        yield errorHandler(error);
    }
}
function* getUserInvoices({ id, params }) {
    try {
        const url = formatUrl(USER_INVOICES_PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_USER_INVOICES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

const getUsersAsync = fork(function* () { yield takeLatest(GET_USERS, getUsers) });
const getUserAsync = fork(function* () { yield takeLatest(GET_USER, getUser) });
const deleteUserAsync = fork(function* () { yield takeLatest(DELETE_USER, deleteUser) });
const createUserAsync = fork(function* () { yield takeLatest(CREATE_USER, createUser) });
const editUserAsync = fork(function* () { yield takeLatest(EDIT_USER, editUser) });
const getUsersDocumentsAsync = fork(function* () { yield takeLatest(GET_USERS_DOCUMENTS, getUsersDocuments) });
const addDocumentToUserAsync = fork(function* () { yield takeLatest(ADD_DOCUMENT_TO_USER, addDocumentToUser) });
const getUserInvoicesAsync = fork(function* () { yield takeLatest(GET_USER_INVOICES, getUserInvoices) });

export default [
    getUsersAsync,
    getUserAsync,
    deleteUserAsync,
    createUserAsync,
    editUserAsync,
    getUsersDocumentsAsync,
    addDocumentToUserAsync,
    getUserInvoicesAsync
];