import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslate } from "react-polyglot";

import { loginAction, googleAuthenticationAction } from "../../../redux/actions/authActions";
import LoginForm from "./LoginForm";

// import GoogleLogin from "react-google-login";

const Login = ({ loginAction, googleAuthenticationAction }) => {

    const translate = useTranslate();

    // const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

    const onSubmit = data => {
        loginAction(data);
    }

    // const handleGoogleLogin = response => {
    //     const { profileObj } = response;
    //     googleAuthenticationAction(profileObj);
    // }

    return (
        <div className="no-auth-content">
            <div className="wrapper auth-container">
                <h2>{translate("general.haveAnAccount")}</h2>
                <h1>{translate("general.pleaseLogin")}</h1>
                <LoginForm
                    onSubmit={onSubmit}
                    translate={translate}
                />
                <div style={{ textAlign: "center" }}>
                    <span className="info-message mr-5">{translate("general.dontHaveAnAccount")}</span>
                    <Link className="info-message" to="/register-email">{translate("general.registerHere")}</Link>
                </div>
                <br />
                {/* <GoogleLogin
                    clientId={googleClientId}
                    buttonText="Login"
                    onSuccess={handleGoogleLogin}
                    cookiePolicy="single_host_origin"
                /> */}
            </div >
        </div>
    )
}

const mapDispatchToProps = {
    loginAction,
    googleAuthenticationAction
}

export default connect(null, mapDispatchToProps)(Login);

