import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import Button from '../../../components/Button';
import { BUTTON_TYPES } from '../../../constants/enums';
import { BASE_URL } from '../../../utils/axiosClient';
import { checkFilterValue, getPriceListIdByFilterValue } from '../../../utils/checkValue';
import { calculatePriceWithCurrency } from '../../../utils/calculatePrice';
import { formatDateTime } from '../../../services/moment.service';
import DateTimePicker from '../../../components/formFields/DateTimePicker';

const AdditionCard = ({ addition, addToShoppingCart, filter }) => {

    const translate = useTranslate();

    const [date, setDate] = useState("");
    const [error, setError] = useState("");
    const disabled = !checkFilterValue(addition, filter);

    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(addition, filter);

    useEffect(() => {
        setError("");
        setDate("");
    }, [disabled])

    const handleChange = (value) => {
        setDate(value);
    }

    const handleAddToShoppingCart = () => {
        if (date) {
            const priceListId = getPriceListIdByFilterValue(addition, filter);
            addToShoppingCart(
                { ...addition, fromDate: date, filter: filter, priceListId: priceListId }
            );
        } else {
            setError('form.error.required');
        }
    }

    if (!addition) return null;
    return (
        <li className="_12">
            <div className={`m-list -addition ${disabled ? "-disable" : ""}`}>
                <div className="item">
                    <img alt="itemImg" src={`${BASE_URL}${addition?.image?.path}`} />
                    <div className="description">
                        <p className="-big">{translate(`${addition.type}.${addition.id}.name`)}</p>
                        {disabled ?
                            <p className="message">{translate(`${addition.type}.${addition.id}.description`)}</p> :
                            <Fragment>
                                <div className="price-container">
                                    <div className="price">
                                        <p className="-big value">{`+ ${discount ? priceWithDiscount : price}`}</p>
                                        <p className="-regular period">{`/ ${translate(`${filter.type}.${filter.id}.name`).toLowerCase()}`}</p>
                                    </div>
                                    {discount &&
                                        <div className="a-discount-container">
                                            <span className="price">{`+ ${price}`}</span>
                                            <span className="a-discount">{`- ${discount}%`}</span>
                                            <span className="a-discount-valid">{`${translate("general.validUntil")} ${formatDateTime(discount?.toDate)}h`}</span>
                                        </div>
                                    }
                                </div>
                                <div className="wrap">
                                    <div className="_m6">
                                        <DateTimePicker
                                            input={{
                                                onChange: handleChange,
                                                value: date
                                            }}
                                            err={!date && error ? error : ""}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
                <Button
                    btnClass={BUTTON_TYPES.SECONDARY}
                    disabled={disabled}
                    label="general.addToPackage"
                    onClick={handleAddToShoppingCart} />
            </div>
        </li>
    )
}

AdditionCard.propTypes = {
    addition: PropTypes.object.isRequired,
    addToShoppingCart: PropTypes.func.isRequired
}

export default AdditionCard
