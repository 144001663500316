import { useState, useEffect } from 'react';
import axiosClient, { GET } from '../../../../utils/axiosClient';
import { formatResponseData } from "../../../../utils/jsonApiHelpers";

const useFetchTicketComments = (ticketId, showDetails, openEdit) => {
    const [comments, setComments] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        if (ticketId && showDetails) {
            setLoading(true);
            const getData = async () => {
                const response = await axiosClient(GET, `/ticket_comments/?ticket.id=${ticketId}&include=author&itemsPerPage=30`);
                const { data } = formatResponseData(response.data)
                setComments(data);
                setLoading(false);
            }
            setTimeout(() => {
                getData();
            }, 500)
        }
    }, [ticketId, showDetails, openEdit])

    return { comments, isLoading };

}

export default useFetchTicketComments;

