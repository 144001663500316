import { 
    GET_EVENTS,
    GET_EVENTS_SUCCESS,
    GET_MY_EVENTS,
    GET_MY_EVENTS_SUCCESS,
    GET_EVENT,
    GET_EVENT_SUCCESS,
    GET_EVENT_ANSWERS,
    GET_EVENT_ANSWERS_SUCCESS,
    RESET_EVENT_LISTS
} from '../actions/eventsActions';

const initialState = {
    data: null,
    answer: null,
    meta: null,
    isLoading: false
};

const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS:
            return { data: null, meta: null, isLoading: true };
        case GET_EVENTS_SUCCESS:
            return { ...action.data, isLoading: false };
        case RESET_EVENT_LISTS:
            return { data: null, meta: null, isLoading: false };
        default:
            return state;
    }
};

const myEventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_EVENTS:
            return { data: null, meta: null, isLoading: true };
        case GET_MY_EVENTS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT:
            return { data: null, meta: null, isLoading: true };
        case GET_EVENT_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const eventAnswersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_ANSWERS:
            return { data: null, answer: null, meta: null, isLoading: true };
        case GET_EVENT_ANSWERS_SUCCESS:
            return { ...action.data, answer: action.answer, isLoading: false };
        default:
            return state;
    }
};

export {
    eventsReducer,
    myEventsReducer,
    eventReducer,
    eventAnswersReducer
};