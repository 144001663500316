import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { INPUT_MODE } from '../../constants/enums';
import { isEmptyOrWhiteSpaces } from "../../utils/checkValue";

const TextAreaField = ({
    input,
    label,
    placeholder,
    readOnly,
    mode,
    required,
    meta: { error, warning, touched }
}) => {

    const hasError = touched && error && required;
    const translate = useTranslate();

    return (
        <Fragment>
            <div className="a-input-group -textarea">
                <textarea
                    {...input}
                    id={input.name}
                    placeholder={placeholder ? translate(placeholder) : ""}
                    disabled={readOnly}
                    className={`a-input -${mode} ${!isEmptyOrWhiteSpaces(input.value) ? "-filled" : ""} ${hasError ? "-error" : ""}`}
                />
                <label htmlFor={input.name} className={`-${mode} ${required ? "-required" : ""}`}>{translate(label)}</label>
                {hasError && <span className="a-input-error">{translate(error)}</span>}
            </div>
        </Fragment>
    )
}

TextAreaField.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    mode: PropTypes.oneOf([
        INPUT_MODE.DARK,
        INPUT_MODE.LIGHT
    ]),
    required: PropTypes.bool
}

TextAreaField.defaultProps = {
    input: {},
    meta: {},
    mode: INPUT_MODE.DARK,
    required: false
}

export default TextAreaField;