import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_SETTINGS,
    GET_SETTINGS_SUCCESS,
    GET_SETTING_SUCCESS,
    GET_SETTING,
    CREATE_SETTING,
    EDIT_SETTING,
} from "../actions/settingsActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";

const PATH = "/app_settings";

function* getSettings({ params }) {
    try {
        const url = formatUrl(PATH, { include: "key", ...params });
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);

        yield put({ type: GET_SETTINGS_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getSettingsItem({ id }) {
    try {
        const response = yield axiosClient(GET, `${PATH}/${id}`);
        const data = formatResponseData(response.data);
        yield put({ type: GET_SETTING_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createSettings({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.APP_SETTINGS, data);
        yield axiosClient(POST, PATH, preparedData);
        yield successHandler("notification.message.successCreatedSettings");
        yield getSettings({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editSettings({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.APP_SETTINGS, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedSettings");
        yield getSettings({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

const getSettingsAsync = fork(function* () { yield takeLatest(GET_SETTINGS, getSettings) });
const getSettingsItemAsync = fork(function* () { yield takeLatest(GET_SETTING, getSettingsItem) });
const createSettingsAsync = fork(function* () { yield takeLatest(CREATE_SETTING, createSettings) });
const editSettingsAsync = fork(function* () { yield takeLatest(EDIT_SETTING, editSettings) });

export default [
    getSettingsAsync,
    getSettingsItemAsync,
    createSettingsAsync,
    editSettingsAsync,
];