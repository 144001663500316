import {
    ADD_PACKAGE_TO_SHOPPING_CART,
    ADD_PACKAGE_TO_SHOPPING_CART_SUCCESS,
    CHECK_PACKAGE,
    CHECK_PACKAGE_SUCCESS,
    CHECK_PACKAGE_FAILED,
    REMOVE_PACKAGE_FROM_SHOPPING_CART,
    ADD_ADDITION_TO_SHOPPING_CART,
    ADD_ADDITION_TO_SHOPPING_CART_SUCCESS,
    REMOVE_ADDITION_FROM_SHOPPING_CART,
    ADD_SERVICE_TO_SHOPPING_CART,
    ADD_SERVICE_TO_SHOPPING_CART_SUCCESS,
    REMOVE_SERVICE_FROM_SHOPPING_CART,
    CREATE_BOOKING,
    CREATE_BOOKING_SUCCESS,
    RESET_SHOPPING_CART
} from '../actions/shoppingCartActions';

import { getUserShoppingCart, setUserShoppingCart, deleteUserShoppingCart } from '../../services/localStorage.service';

const initialData = getUserShoppingCart();

const initialState = {
    packages: initialData.packages,
    additions: initialData.additions,
    services: initialData.services,
    isLoading: false,
    isSuccess: false,
    isPackageInCart: false
};

const shoppingCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PACKAGE_TO_SHOPPING_CART:
            return { ...state, isLoading: true, isPackageInCart: false };
        case ADD_PACKAGE_TO_SHOPPING_CART_SUCCESS:
            setUserShoppingCart({ ...state, packages: [...state.packages, action.data] });
            return { ...state, packages: [...state.packages, action.data], isLoading: false, isPackageInCart: true };
        case CHECK_PACKAGE:
            return { ...state, isLoading: true, isSuccess: false };
        case CHECK_PACKAGE_SUCCESS:
            return { ...state, isLoading: false, isSuccess: true };
        case CHECK_PACKAGE_FAILED:
            return { ...state, isLoading: false, isSuccess: false };
        case REMOVE_PACKAGE_FROM_SHOPPING_CART:
            let packagesArray = [...state.packages];
            packagesArray.splice(action.index, 1);
            setUserShoppingCart ({ ...state, packages: packagesArray});
            return { ...state, packages: packagesArray, isPackageInCart: false };
        case ADD_ADDITION_TO_SHOPPING_CART:
            return { ...state, isLoading: true, isSuccess: false };
        case ADD_ADDITION_TO_SHOPPING_CART_SUCCESS:
            setUserShoppingCart({ ...state, additions: [...state.additions, action.data] });
            return { ...state, additions: [...state.additions, action.data], isLoading: false, isSuccess: true };
        case REMOVE_ADDITION_FROM_SHOPPING_CART:
            let additionsArray = [...state.additions];
            additionsArray.splice(action.index, 1);
            setUserShoppingCart ({ ...state, additions: additionsArray});
            return { ...state, additions: additionsArray };
        case ADD_SERVICE_TO_SHOPPING_CART:
            return { ...state, isLoading: true };
        case ADD_SERVICE_TO_SHOPPING_CART_SUCCESS:
            setUserShoppingCart({ ...state, services: [...state.services, action.data] });
            return { ...state, services: [...state.services, action.data], isLoading: false };
        case REMOVE_SERVICE_FROM_SHOPPING_CART:
            let servicesArray = [...state.services];
            servicesArray.splice(action.index, 1);
            setUserShoppingCart ({ ...state, services: servicesArray});
            return { ...state, services: servicesArray };
        case CREATE_BOOKING:
            return { ...state, isLoading: true };
        case CREATE_BOOKING_SUCCESS:
            deleteUserShoppingCart();
            return { packages: [], additions: [], services: [], isLoading: false };
        case RESET_SHOPPING_CART:
            deleteUserShoppingCart();
            return { packages: [], additions: [], services: [], isLoading: false };
        default:
            return state;
    }
};

export {
    shoppingCartReducer
};