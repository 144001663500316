import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axiosClient, { POST } from '../../utils/axiosClient';
import { IMAGE_TYPES } from '../../constants/enums';
import ENTITY from '../../constants/entities';
import Image from '../Image';

const ImageUpload = ({ image, onImageChange, firstName, lastName, imageType, imageClass }) => {

    const fileRef = useRef(null);

    const openFileRef = () => {
        fileRef.current.click();
    }

    const [imagePath, setImagePath] = useState(null);
    useEffect(() => {
        if (image) setImagePath(image);
    }, [image])

    const handleImageChange = event => {
        const formData = new FormData();
        const data = event.target.files[0];
        formData.append('file', data);
        axiosClient(POST, '/files', formData).then(({ data }) => {
            const { id, attributes } = data.data;
            setImagePath(attributes.path);
            onImageChange(imageType, { id, type: ENTITY.FILE })
        }).catch(err => {
            console.error(err)
        })
    }

    // TODO: After design for this component is added
    // and button for delete image is added, add this to onClick event
    // eslint-disable-next-line no-unused-vars
    const handleDeleteImage = () => {
        onImageChange('image', null);
        setImagePath(null);
    }

    const getImageClass = () => {
        switch (imageClass) {
            case IMAGE_TYPES.REGULAR:
                return '-regular';
            case IMAGE_TYPES.AVATAR:
                return '-avatar';
            case IMAGE_TYPES.USER_UPLOAD:
                return '-user-upload';
            default:
                return '';
        }
    }

    const imgClass = getImageClass();

    return (
        <div className={`a-image-container${imgClass}`}
            onClick={openFileRef}>
            <input
                ref={fileRef}
                style={{ display: 'none' }}
                type='file'
                onChange={handleImageChange}
            />
            <Image image={imagePath} firstName={firstName} lastName={lastName} imageClass={imageClass} />
        </div>
    );
};

ImageUpload.propTypes = {
    image: PropTypes.string,
    onImageChange: PropTypes.func,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    imageType: PropTypes.oneOf([
        IMAGE_TYPES.REGULAR,
        IMAGE_TYPES.AVATAR
    ]).isRequired,
    imageClass: PropTypes.oneOf([
        IMAGE_TYPES.REGULAR,
        IMAGE_TYPES.AVATAR,
        IMAGE_TYPES.USER_UPLOAD
    ])
}

ImageUpload.defaultProps = {
    imageType: IMAGE_TYPES.REGULAR,
    imageClass: IMAGE_TYPES.REGULAR
};

export default ImageUpload;
