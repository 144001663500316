import { formatDate, formatDateTime } from '../services/moment.service';
import { ROLES } from '../constants/enums';

export const extractValueFromService = (item, attribute, filter = {}) => {
    try {
        if (item) {
            switch (attribute) {
                case "price":
                    if (item.priceLists?.length > 0) {
                        return Number(item.priceLists[0].data.attributes.priceForUserCurrency);
                    }
                    return "";
                case "currency":
                    if (item.priceLists?.length > 0) {
                        return item.priceLists[0].included[0].attributes.code;
                    }
                    return "";
                case "date":
                    return formatDate(item.fromDate);
                case "datetime":
                    return `${formatDateTime(item.fromDate)} h`;
                case "price-currency":
                    if (item.priceLists?.length > 0) {
                        return `${item.priceLists[0].data.attributes.priceForUserCurrency} ${item.priceLists[0].included[0].attributes.code}`;
                    }
                    return "";
                case "price-currency-filter":
                    const priceLists = item.priceLists.filter(item => item.data.relationships.priceListType.data.id === filter.id);
                    if (priceLists.length > 0) {
                        return `${priceLists[0].data.attributes.priceForUserCurrency} ${priceLists[0].included[0].attributes.code}`;
                    }
                    return "";
                case "price-filter":
                    const priceList = item.priceLists.filter(item => item.data.relationships.priceListType.data.id === filter.id);
                    if (priceList.length > 0) {
                        return Number(priceList[0].data.attributes.priceForUserCurrency);
                    }
                    return "";
                default:
                    return "";
            }
        }
    } catch (error) {
        console.log(error);
        return "";
    }
}

export const checkFilterValue = (item, filter) => {
    try {
        if (item) {
            return item.priceLists.some(item => item?.data?.relationships?.priceListType?.data?.id === filter?.id);
        }
    } catch (error) {
        console.log(error);
    }
}

export const getPriceListIdByFilterValue = (item, filter) => {
    try { 
        if (item) {
            let priceLists = item.priceLists.filter(item => item?.data?.relationships?.priceListType?.data?.id === filter?.id);
            return priceLists[0].data.id;
        }
    } catch (error) {
        console.log(error);
    }
}

export const isEmptyOrWhiteSpaces = value => {
    try {
        return value === null || value.trim() === '';
    } catch (error) {
        console.log(error);
    }
}

export const renderRoleLabel = (roleIdentifier) => {
    switch (roleIdentifier) {
        case ROLES.GUEST.NAME:
            return 'Guest';
        case ROLES.USER.NAME:
            return 'User';
        case ROLES.MANAGER.NAME:
            return 'Manager';
        case ROLES.ADMIN.NAME:
            return 'Admin';
        default:
            return ""
    }
};