import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_UNREAD_NOTIFICATIONS_SUCCESS,
    GET_UNREAD_NOTIFICATIONS,
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    EDIT_NOTIFICATION,
    GET_NOTIFICATION_TYPES,
    GET_NOTIFICATION_TYPES_SUCCESS,
} from "../actions/notificationActions";

import axiosClient, { GET, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatResponseData, formatRequestData } from "../../utils/jsonApiHelpers";

import errorHandler from "./errorHandler";

import ENTITY from "../../constants/entities";

const PATH = "/notifications";
const NOTIFICATION_TYPES_PATH = "/notification_types"

function* getNotifications({ params, isForDropdown }) {
    try {
        params = {
            ...params,
            include: "creator",
        };
        const url = formatUrl(PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        if(isForDropdown) {
            yield put({ type: GET_UNREAD_NOTIFICATIONS_SUCCESS, data })
        } else {
            yield put({ type: GET_NOTIFICATIONS_SUCCESS, data })
        }
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getNotification({ id }) {
    try {
        const response = yield axiosClient(GET, `${PATH}/${id}`);
        const data = formatResponseData(response.data);
        yield put({ type: GET_NOTIFICATION_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editNotification({ data, params, isForDropdown }) {
    try {
        const preparedData = formatRequestData(ENTITY.NOTIFICATION, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield getNotifications({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getNotificationTypes({ params }) {
    try {
        const url = formatUrl(NOTIFICATION_TYPES_PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_NOTIFICATION_TYPES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

const getUnreadNotificationsAsync = fork(function* () { yield takeLatest(GET_UNREAD_NOTIFICATIONS, getNotifications) });
const getNotificationsAsync = fork(function* () { yield takeLatest(GET_NOTIFICATIONS, getNotifications) });
const getNotificationAsync = fork(function* () { yield takeLatest(GET_NOTIFICATION, getNotification) });
const editNotificationAsync = fork(function* () { yield takeLatest(EDIT_NOTIFICATION, editNotification) });
const getNotificationTypesAsync = fork(function* () { yield takeLatest(GET_NOTIFICATION_TYPES, getNotificationTypes) });

export default [
    getUnreadNotificationsAsync,
    getNotificationsAsync,
    getNotificationAsync,
    editNotificationAsync,
    getNotificationTypesAsync
];