import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";
import { Link } from "react-router-dom";

import { forgotPasswordAction } from "../../../redux/actions/authActions";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword = ({ forgotPasswordAction }) => {
    const translate = useTranslate();

    const onSubmit = data => {
        forgotPasswordAction(data);
    }

    return (
        <Fragment>
            <Link to="/login" className="no-auth-breadcrumbs">
                <i className="m-avatar-chevron -left"></i>
                {translate("general.backToLogin")}
            </Link>
            <div className="no-auth-content">
                <div className="wrapper auth-container text-center">
                    <div className="image -forgot-password mt-25" />
                    <h1 className="mv-10">{translate("general.forgotPassword")}</h1>
                    <p className="forgot-password-info-message m-auto">{translate("general.forgotPasswordInfoMsg")}</p>
                    <ForgotPasswordForm
                        onSubmit={onSubmit}
                    />
                </div>
            </div>
        </Fragment>
    )
}

const mapDispatchToProps = {
    forgotPasswordAction,
}

export default connect(null, mapDispatchToProps)(ForgotPassword);

