import React from 'react';
import { connect } from "react-redux";
import { getBookingsAction } from "../../redux/actions/bookingsActions";
import { getListSelector, getTotalItemsSelector } from "../../utils/selectors";

import Table from "../../components/Table";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "user",
        title: "table.header.user",
    },
    {
        key: "bookingItems",
        title: "table.header.bookingItems",
        valueType: 'array'
    }
];

const UserBookings = ({
    getBookingsAction,
    isLoading,
    data,
    totalItems
}) => {

    const handleGenerateInvoice = (id) => {
        const BASE_URL = process.env.REACT_APP_API_HOST;
        const { invoice } = data.find(item => item.id === id)
        if (invoice) {
            window.open(`${BASE_URL}${invoice}`, '_blank');
        }
    }

    return (
        <div>
            <Table
                title="general.menuItems.myBookings"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getBookingsAction}
                actions={{
                    generateFileAction: handleGenerateInvoice
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.bookings.isLoading,
    data: getListSelector(state.bookings),
    totalItems: getTotalItemsSelector(state.bookings)
});

const mapDispatchToProps = {
    getBookingsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBookings);
