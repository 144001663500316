import { GET_DOCUMENT_TYPES, GET_DOCUMENT_TYPES_SUCCESS, GET_DOCUMENT_TYPE, GET_DOCUMENT_TYPE_SUCCESS } from "../actions/documentTypeActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const documentTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCUMENT_TYPES:
            return { data: null, meta: null, isLoading: true };
        case GET_DOCUMENT_TYPES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const documentTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DOCUMENT_TYPE:
            return { data: null, meta: null, isLoading: true };
        case GET_DOCUMENT_TYPE_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

export {
    documentTypesReducer,
    documentTypeReducer
};