import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import Button from '../../components/Button';
import { BUTTON_TYPES } from '../../constants/enums';
import { calculatePriceWithCurrency } from '../../utils/calculatePrice';
import { BASE_URL } from '../../utils/axiosClient';
import { formatDateTime } from '../../services/moment.service';

const ServiceCard = ({ service, handleBookAService }) => {

    const translate = useTranslate();
    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(service);

    if (!service) return null;

    return (
        <li className="_12">
            <div className="m-list -service">
                <img alt="serviceImg" src={`${BASE_URL}${service?.image?.path}`} />
                <div className="item">
                    <div className="title-price-container">
                        <h2>{translate(`${service.type}.${service.id}.name`)}</h2>
                        <div>
                            <div className="price-container">
                                <p className="bold price">{`${discount ? priceWithDiscount : price}`}</p>
                                {discount &&
                                    <div className="a-discount-container">
                                        <span className="price">{price}</span>
                                        <span className="a-discount">{`- ${discount}%`}</span>
                                        <span className="a-discount-valid">{`${translate("general.validUntil")} ${formatDateTime(discount?.toDate)}h`}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <p className="-regular description">{translate(`${service.type}.${service.id}.description`)}</p>
                    <Button btnClass={BUTTON_TYPES.PRIMARY} label="general.bookAService" onClick={() => handleBookAService(service)} />
                </div>
            </div>
        </li>
    )
}

ServiceCard.propTypes = {
    service: PropTypes.object.isRequired
}

export default ServiceCard;
