import { formValueSelector } from 'redux-form';

const getIsLoading = state => {
    return state?.isLoading || false
}

const getListSelector = state => {
    return state?.data || []
};

const getItemSelector = state => state?.data || null;

const getTotalItemsSelector = state => {
    return state?.meta?.totalItems || 0
};

const getServicePlanByType = (state, planType) => {
    if (state.data && state.data.length > 0) {
        return state.data.filter(service => service.planType.id === planType)
    }
    return []
}

const getIsServiceSelector = (state) => {
    const SERVICE_ID = 2;
    const selector = formValueSelector("ServicePlanForm");
    const servicePlanTypeId = selector(state, "planType.id");
    return Number(servicePlanTypeId) === SERVICE_ID;
}

const getDiscountTypeSelector = (state) => {
    const selector = formValueSelector("DiscountForm")
    return Number(selector(state, "discountType.id"))
}

const getRegularPackages = (state) => {
    return (getListSelector(state)).filter(item => !item.timeLimit);
}

const getTimeLimitPackages = (state) => {
    return (getListSelector(state)).filter(item => item.timeLimit);
}

export {
    getIsLoading,
    getListSelector,
    getItemSelector,
    getTotalItemsSelector,
    getServicePlanByType,
    getIsServiceSelector,
    getDiscountTypeSelector,
    getRegularPackages,
    getTimeLimitPackages
}