import React from 'react';
import PropTypes from 'prop-types';
import generatePagination from './generatePagination';
import PageSelector from './PageSelector';

const PAGINATION_OPTIONS = [10, 20, 30];

const Pagination = ({
    display,
    page,
    setPage,
    itemsPerPage,
    setItemsPerPage,
    totalItems,
    paginationOption
}) => {

    const paginationItems = generatePagination(page, totalItems, itemsPerPage);

    if (!display) return null;

    return (
        <div className="pagination-container">
            <PageSelector
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                options={paginationOption}
                totalItems={totalItems}
            />
            {
                paginationItems.length > 1 &&
                <div className="pagination">
                    {
                        page !== 1 &&
                        <span
                            onClick={() => setPage(--page)}
                            className="item"
                        >
                            &lt;
                        </span>
                    }
                    {
                        paginationItems.map(item => (
                            <span
                                key={item}
                                onClick={typeof item === 'number' ? () => setPage(item) : undefined}
                                className={`item ${item === page ? 'active' : ''} ${typeof item !== 'number' ? 'disabled' : ''}`}
                            >
                                {item}
                            </span>
                        ))
                    }
                    {
                        page !== paginationItems[paginationItems.length - 1] &&
                        <span
                            onClick={() => setPage(++page)}
                            className="item"
                        >
                            &gt;
                        </span>
                    }
                </div>
            }
        </div>
    )
}

Pagination.propTypes = {
    display: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    setItemsPerPage: PropTypes.func.isRequired,
    paginationOption: PropTypes.array.isRequired,
    totalItems: PropTypes.number.isRequired
};

Pagination.defaultProps = {
    display: true,
    paginationOption: PAGINATION_OPTIONS
};

export default Pagination;