/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import axiosClient, { GET } from '../utils/axiosClient';
import { formatResponseData, formatUrl } from '../utils/jsonApiHelpers';

const useFetchItemFromApi = (entityType, id, params) => {

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (id) {
            const getData = async () => {
                try {
                    setIsLoading(true);
                    const PATH = `/${entityType}/${id}`;
                    const url = formatUrl(PATH, params);
                    const response = await axiosClient(GET, url);
                    const { data } = formatResponseData(response.data);
                    setData(data);
                    setIsLoading(false);

                } catch (error) {
                    setData({});
                    setIsLoading(false);
                }
            }
            getData();
        }
    }, [id])

    return { data, isLoading };
}

export default useFetchItemFromApi;
