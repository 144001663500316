/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import moment from "moment";

import CalendarHeader from './CalendarHeader';
import CalendarDayLabels from './CalendarDayLabels';
import CalendarCells from './CalendarCells';
import { CALENDAR_YEARS_PERIOD } from '../../constants/enums';

const CalendarPicker = ({
    input,
    display = true,
    yearsPeriod
}) => {
    const [currentMonth, setCurrentMonth] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (input.value) {
            setCurrentMonth(moment.utc(input.value));
            setSelectedDate(moment.utc(input.value));
        } else {
            setCurrentMonth(moment.utc("00:00", "HH:mm"));
            setSelectedDate(moment.utc("00:00", "HH:mm"));
        }
    }, [input.value])

    useEffect(() => {
        if (selectedDate) {
            input.onChange(selectedDate.format());
        }
    }, [selectedDate]);

    const handleSelectDate = date => {
        date.set({
            hour: selectedDate.get('hour'),
            minute: selectedDate.get('minute')
        })
        setSelectedDate(date.startOf(date));
    }

    if (
        !display ||
        !currentMonth ||
        !selectedDate
    ) return null;


    return (
        <div className={`m-calendar m-calendar-input`}>
            <CalendarHeader
                size='input'
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                selectedDate={selectedDate}
                yearsPeriod={yearsPeriod}
            />
            <CalendarDayLabels
                size='input'
                currentMonth={currentMonth} />
            <CalendarCells
                currentMonth={currentMonth}
                selectedDate={selectedDate}
                setSelectedDate={handleSelectDate}
            />
        </div>
    )
}

CalendarPicker.propTypes = {
    input: PropTypes.object.isRequired,
    display: PropTypes.bool,
    yearsPeriod: PropTypes.oneOf([
        CALENDAR_YEARS_PERIOD.NEXT,
        CALENDAR_YEARS_PERIOD.PREVIOUS
    ])
}

export default CalendarPicker;
