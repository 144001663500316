/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from "react-polyglot";
import { Field, FieldArray, reduxForm, arrayPush } from 'redux-form';
import { required, isValidEmail } from "../../../utils/validation";
import ENTITY from '../../../constants/entities';
import InputField from '../../../components/formFields/InputField';
import TextAreaField from '../../../components/formFields/TextAreaField';
import FileUploadDnD from '../../../components/formFields/FileUploadDnD';
import { BUTTON_TYPES, BOOKING_ITEM_STATUSES, FILE_TYPES } from "../../../constants/enums";
import Button from '../../../components/Button';
import SelectField from '../../../components/formFields/SelectField';
import { formatDateTime } from '../../../services/moment.service';
import useFetchItemFromApi from '../../../utils/fetchItemFromApi';
import useFetchOptionsFromApi from '../../../components/formFields/hooks/fetchOptionsFromApi';
import { BASE_URL } from '../../../utils/axiosClient';
import Invitation from './Invitation';
import Icon from '../../../components/Icon';

const renderFieldArray = ({
    fields,
    btnLabel,
    bookingItemId,
    maxPersons
}) => {
    return (
        <ul className="m-array-field -array">
            {fields.map((item, index) => (
                <li key={index}>
                    <Field
                        name={`${item}.email`}
                        validate={[required, isValidEmail]}
                        required={required}
                        component={Invitation}
                        bookingItemId={bookingItemId}
                    />
                </li>
            ))}
            <li>
                <Button type="button" label={btnLabel} onClick={() => fields.push({})} />
                <span className="max-persons">{maxPersons}</span>
            </li>
        </ul>
    )
}

const EventForm = ({
    handleSubmit,
    onSubmit,
    submitLabel,
    pushArray,
    change,
    readOnly,
    inviteGuests,
    initialValues
}) => {

    const translate = useTranslate();

    const [bookingItem, setBookingItem] = useState(initialValues ? initialValues.bookingItem : null);

    const { data } = useFetchItemFromApi(
        ENTITY.SERVICE_PLAN,
        initialValues ? initialValues?.bookingItem?.relationships?.servicePlan?.data?.id : bookingItem?.servicePlanId,
        { include: "image" }
    )

    const { options } = useFetchOptionsFromApi(
        ENTITY.BOOKING_ITEM,
        {
            page: 1,
            itemsPerPage: 30,
            eventInvite: 1,
            include: "servicePlan",
            "status.id": BOOKING_ITEM_STATUSES.IN_PROGRESS,
            pagination: false
        }
    );

    const selectOptions = options.map(({ id, fromDate, toDate, servicePlan }) => (
        {
            id,
            fromDate,
            toDate,
            name: translate(`${servicePlan.type}.${servicePlan.id}.name`),
            servicePlanId: servicePlan.id
        }
    ));

    useEffect(() => {
        if (inviteGuests) {
            pushArray('EventForm', 'users', {});
        }
    }, [])

    const handleChangeBookingItem = (event) => {
        setBookingItem(selectOptions.find(item => item.id === Number(event.target.value)));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap event-fields">
                <div className="_m6">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.eventName"
                        component={InputField}
                        // readOnly={!!initialValues}
                        readOnly={readOnly}
                        validate={required}
                        required
                    />
                    <Field
                        name="image"
                        component={FileUploadDnD}
                        onImageChange={change}
                        type={FILE_TYPES.IMAGE}
                    />
                </div>
                <div className="_m6">
                    <Field
                        name="description"
                        label="form.fieldLabel.describeYourEvent"
                        component={TextAreaField}
                        // readOnly={!!initialValues}
                        readOnly={readOnly}
                        validate={required}
                        required
                    />
                </div>
                <div className="_12">
                    <span className="a-line"></span>
                </div>
                <div className="_12">
                    <p className="title">{translate("general.chooseBookingItem")}</p>
                </div>
                <div className="_12 _mobile-m6">
                    <Field
                        name="bookingItem.id"
                        label="form.fieldLabel.bookingItem"
                        component={SelectField}
                        options={selectOptions}
                        validate={required}
                        required
                        onChange={handleChangeBookingItem}
                        readOnly={readOnly}
                    />
                </div>
                <div className="_12 _mobile-m6">
                    {bookingItem &&
                        <div className="booking-item">
                            <div>
                                <p className="title">{data?.id ? translate(`${data.type}.${data.id}.name`) : ""}</p>
                                <div className="date-time-container">
                                    <span className="row">
                                        {translate("general.fromDate")} <span className="date-time">{formatDateTime(bookingItem?.fromDate)}</span>
                                    </span>
                                    <span className="row">
                                        {translate("general.toDate")} <span className="date-time">{formatDateTime(bookingItem?.toDate)}</span>
                                    </span>
                                </div>
                            </div>
                            <img src={`${BASE_URL}${data?.image?.path}`} alt="" className="image" />
                        </div>
                    }
                </div>
                <div className="_12">
                    <span className="a-line"></span>
                </div>
            </div>
            { inviteGuests &&
                <Fragment>
                    <div className="wrap event-fields">
                        <div className="_12">
                            <p className="invitation">{translate('general.inviteGuests')}</p>
                            <p className="invitation-description">{translate('general.sendEventInvitation')}</p>
                            {initialValues?.bookingItemEventUsers?.length > 0 &&
                                <div className="guests">
                                    {initialValues.bookingItemEventUsers.map((item, index) => (
                                        <div className="guest" key={index}>
                                            <span className="email" >
                                                {item.userEmail}
                                                <Icon name="mail-opened" color={"#d8d8d8"} />
                                            </span>
                                            <span className="status">{translate(`general.${item.answerName || "pending"}`)}</span>
                                        </div>
                                    ))}
                                </div>
                            }
                            <FieldArray
                                name="users"
                                component={renderFieldArray}
                                fieldValidate={[required, isValidEmail]}
                                required={true}
                                entityType={ENTITY.USER}
                                bookingItemId={initialValues?.id}
                                btnLabel="general.inviteMoreGuests"
                                maxPersons={`${translate('general.maxPersonsNumber')} ${initialValues?.maxPersonsNumber}`} />
                        </div>
                    </div>
                </Fragment>
            }
            { !readOnly &&
                <div className="wrap event-btn">
                    <div className="_12">
                        <Button type="submit" btnClass={BUTTON_TYPES.SECONDARY} label={submitLabel} />
                    </div>
                </div>
            }
        </form>
    )
}

const mapDispatchToProps = {
    pushArray: arrayPush
}

export default connect(null, mapDispatchToProps)(reduxForm({ form: "EventForm", enableReinitialize: true })(EventForm));
