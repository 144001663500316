import { extractValueFromService } from './checkValue';

export const calculatePrice = (item, filter = null) => {
    const discount =  item?.discount?.percentDiscount;
    const price = extractValueFromService(item, filter ? "price-filter" : "price", filter);
    const priceWithDiscount = discount ? calculatePriceWithDiscount(price, discount) : price;
    return priceWithDiscount;
}

export const calculatePriceWithCurrency = (item, filter = null) => {
    
    const discount =  item?.discount?.percentDiscount;
    const price = extractValueFromService(item, filter ? "price-currency-filter" : "price-currency", filter);
    const itemPrice = extractValueFromService(item, filter ? "price-filter" : "price", filter);
    const currency = extractValueFromService(item, "currency");
    const priceWithDiscount = discount ? `${calculatePriceWithDiscount(itemPrice, discount)} ${currency}` : null;

    return {
        discount,
        price,
        priceWithDiscount
    };
}

export const calculatePriceWithDiscount = (price, discount) => {
    const priceValue = Number(price);
    const priceWithDiscount = priceValue - Number(discount)/100 * priceValue;
    return priceWithDiscount;
}