import React from "react";
import { Field, reduxForm } from "redux-form";
import { useTranslate } from "react-polyglot";
import ModalForm from "../../../../components/ModalForm";
import InputField from "../../../../components/formFields/InputField";
import { required } from "../../../../utils/validation";
import ENTITY from "../../../../constants/entities";
import SelectFieldRelationship from "../../../../components/formFields/SelectFieldRelationship";
import TextAreaField from "../../../../components/formFields/TextAreaField";
import SelectMultipleFieldRelationship from "../../../../components/formFields/SelectMultipleFieldRelationship";

const PriceForm = ({
    title,
    priceTypes,
    defaultCurrency,
    handleSubmit,
    onSubmit,
    submitLabel,
    handleCancel,
    change
}) => {

    const translate = useTranslate();

    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
        >
            <div className="wrap price-form">
                <div className="_m6 mv-10">
                    <Field
                        name="priceListType.id"
                        label="form.fieldLabel.priceType"
                        entityType={ENTITY.PRICE_TYPE}
                        onSelect={change}
                        options={priceTypes}
                        component={SelectFieldRelationship}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.name"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="price"
                        label="form.fieldLabel.price"
                        type="number"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="servicePlans"
                        label="form.fieldLabel.servicePlans"
                        onSelect={change}
                        entityType={ENTITY.SERVICE_PLAN}
                        component={SelectMultipleFieldRelationship}
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        type="text"
                        name="description"
                        label="form.fieldLabel.description"
                        component={TextAreaField}
                    />
                </div>
                {defaultCurrency &&
                    <div className="_m12 mv-10">
                        <p className="default">{translate('general.defaultCurrency')}<span className="value">{`${defaultCurrency?.value}`}</span></p>
                    </div>
                }
            </div>
        </ModalForm>
    )
}

export default reduxForm({
    form: "PriceForm",
    enableReinitialize: true,
})(PriceForm);