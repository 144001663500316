import { DEFAULT_LANGUAGE } from "../constants/enums";

const USER_TOKEN = 'hub_user_token';
const USER_ID = 'hub_user_id';
const USER_LANG = 'hub_user_lang';
const USER_SHOPPING_CART = 'hub_user_shopping_cart';


export const getUserToken = () => {
    return localStorage.getItem(USER_TOKEN);
}

export const setUserToken = token => {
    localStorage.setItem(USER_TOKEN, token);
}

export const deleteUserToken = () => {
    localStorage.removeItem(USER_TOKEN);
}

export const getUserId = () => {
    return Number(localStorage.getItem(USER_ID));
}

export const setUserId = id => {
    localStorage.setItem(USER_ID, id);
}

export const deleteUserId = () => {
    localStorage.removeItem(USER_ID);
}

export const getUserLang = () => {
    return localStorage.getItem(USER_LANG) || DEFAULT_LANGUAGE;
}

export const setUserLang = value => {
    localStorage.setItem(USER_LANG, value);
}

export const getUserShoppingCart = () => {
    const shoppingCart = localStorage.getItem(USER_SHOPPING_CART);
    if (shoppingCart) {
        return JSON.parse(shoppingCart);
    }
    return { packages: [], additions: [], services: [] };
}

export const setUserShoppingCart = data => {
    localStorage.setItem(USER_SHOPPING_CART, JSON.stringify(data));
}

export const deleteUserShoppingCart = () => {
    localStorage.removeItem(USER_SHOPPING_CART);
}