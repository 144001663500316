import React from 'react'
import PropTypes from 'prop-types';
import { useTranslate } from "react-polyglot";
import Avatar from '../../../../../components/Avatar';
import Loader from '../../../../../components/Loader';

const SingleComment = ({ repliersName, firstName, lastName, avatarPath, roleName, text }) => {
    const translate = useTranslate();
    return (
        <div className="comments-item">
            <div className="comment-creator">
                <Avatar firstName={firstName} lastName={lastName} avatarImg={avatarPath} role={roleName} />
                <span className="in-reply">{translate("general.inReplyTo")} {repliersName}</span >
            </div>
            <p className="-regular">{text}</p>
        </div>
    )
}

SingleComment.propTypes = {
    repliersName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatarPath: PropTypes.string,
    text: PropTypes.string.isRequired
}

const TicketDetailsContainer = ({ comments, ticketCreator, text, isLoading }) => {
    if (isLoading) return <Loader type="mini" />;
    return (
        <div className="ticket-middle">
            <div className="ticket-middle-description">
                <p className="-regular">{text}</p>
            </div>
            <div className="ticket-middle-comments">
                {comments.length > 0 && comments.map((comment, index) => (
                    <SingleComment
                        key={index}
                        repliersName={ticketCreator}
                        firstName={comment.author.firstName}
                        lastName={comment.author.lastName}
                        avatarPath={comment.author.avatarPath}
                        roleName={comment.author.roleName}
                        text={comment.text}
                    />
                )
                )}
            </div>
        </div>
    )
}

TicketDetailsContainer.propTypes = {
    comments: PropTypes.array.isRequired,
    ticketCreator: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired
}

export default TicketDetailsContainer