import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './redux/reducers';

import createSagaMiddleware from 'redux-saga';
import saga from './redux/saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware);

const enhancers = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;

const store = createStore(
    rootReducer,
    {},
    compose(middleware, enhancers)
)

sagaMiddleware.run(saga)

export default store;