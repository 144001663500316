export const GET_DISCOUNTS = '@DSC/GET_DISCOUNTS';
export const GET_DISCOUNTS_SUCCESS = '@DSC/GET_DISCOUNTS_SUCCESS';
export const getDiscountsAction = params => ({
    type: GET_DISCOUNTS,
    params
});

export const GET_DISCOUNT = '@DSC/GET_DISCOUNT';
export const GET_DISCOUNT_SUCCESS = '@DSC/GET_DISCOUNT_SUCCESS';
export const getDiscountAction = (id, params) => ({
    type: GET_DISCOUNT,
    id,
    params
})

export const CREATE_DISCOUNT = '@DSC/CREATE_DISCOUNT';
export const createDiscountAction = (data) => ({
    type: CREATE_DISCOUNT,
    data
});

export const EDIT_DISCOUNT = '@DSC/EDIT_DISCOUNT';
export const editDiscountAction = (data) => ({
    type: EDIT_DISCOUNT,
    data
});