import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { SERVICE_TYPES, BOOKING_ITEM_STATUSES, ROLES } from '../../../../constants/enums';
import { getItemSelector, getListSelector } from '../../../../utils/selectors';
import ActivePackage from '../ActivePackage';
import AdditionalPerks from '../AdditionalPerks';
import { Fragment } from 'react';
import RenewPackage from '../../../RenewPackage';

const activePackageValidation = (item) => {
    if (item) {
        let today = moment.utc().add(60, 'm');
        let fromDate = moment(item.fromDate);
        let toDate = moment(item.toDate);
        let hoursLeft = toDate.diff(today, 'hours');
        let hoursPassed = today.diff(fromDate, 'hours');
        if (hoursLeft >= 0 && hoursPassed >= 0) {
            return true;
        }
        return false;
    }
}

const UserActiveBookings = ({ bookingItems, user }) => {

    const [open, setOpen] = useState(false);

    const [ renewModal, setRenewModal ] = useState(false);

    const hocRef = useRef(null);
    const userRoleName = user?.roleName;
    const isUser = userRoleName === ROLES.USER.NAME;

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (hocRef && hocRef.current && !hocRef.current.contains(event.target)) {
            setOpen(false);
        }
    }

    let activePackage = null;
    let packages = [];
    let additions = [];
    let services = [];

    const filterByServiceType = serviceType => {
        return bookingItems.filter(item => item?.servicePlan?.relationships?.planType?.data?.id === serviceType)
    }

    if (bookingItems.length > 0) {
        packages = filterByServiceType(SERVICE_TYPES.PACKAGE);
        const activePackages = packages.filter(item => activePackageValidation(item) && item.status.id === BOOKING_ITEM_STATUSES.IN_PROGRESS);
        activePackage = activePackages[0];
        activePackageValidation(activePackage);
        additions = filterByServiceType(SERVICE_TYPES.ADDITION);
        services = filterByServiceType(SERVICE_TYPES.SERVICE);
    }

    const handleCancel = () => {
        setRenewModal(false);
    }

    return (
        <Fragment>
        <div ref={hocRef} className={`user-bookings ${open ? '-open' : ''}`}>
            <span className="user-bookings-opener" onClick={() => setOpen(!open)}></span>
            <div className="user-bookings-container">
                {activePackage && <ActivePackage activePackage={activePackage} setRenewModal={setRenewModal} />}
                
                <AdditionalPerks data={packages} label='general.menuItems.packageCatalog' />
                <AdditionalPerks data={additions} label='general.additionalPerks' />
                <AdditionalPerks data={services} label='general.menuItems.services' />
            </div>
        </div>
        {(isUser && renewModal ) && 
            <RenewPackage 
                initialValues={{
                    activePackage: activePackage, 
                    additions: additions.filter(item => item.status.id === BOOKING_ITEM_STATUSES.IN_PROGRESS), 
                    renewFromDate: activePackage.toDate}} 
                    open={renewModal} 
                    setRenewModal={setRenewModal}
                    handleCancel={handleCancel} />
                }
        </Fragment>
    )
}

const mapStateToProps = state => ({
    bookingItems: getListSelector(state.bookingItems),
    user: getItemSelector(state.currentUser)
})

export default connect(mapStateToProps, null)(UserActiveBookings);
