import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../utils/axiosClient';
import { IMAGE_TYPES } from '../../constants/enums';
import Icon from '../Icon';

const Image = ({ image, firstName, lastName, imageClass }) => {

    const getImageClass = () => {
        switch (imageClass) {
            case IMAGE_TYPES.REGULAR:
                return '-regular';
            case IMAGE_TYPES.AVATAR:
                return '-avatar';
            case IMAGE_TYPES.USER_UPLOAD:
                return '-user-upload';
            default:
                return '';
        }
    }

    const getImageText = () => {
        if (firstName && lastName) return `${firstName.charAt(0)}${lastName.charAt(0)}`;
        if (firstName) return `${firstName.charAt(0)}`;
        return "";
    }

    const imgClass = getImageClass();
    

    return (
        <div className={`a-image ${imgClass} ${image ? "" : "-show"}`}>
            {
                image ?
                    <Fragment>
                        <img src={`${BASE_URL}${image}`} alt=""/>
                        { imageClass === IMAGE_TYPES.USER_UPLOAD && 
                            <div className={`image-icon`}>
                                <Icon name="image" />
                            </div> 
                        }
                    </Fragment> :
                    <Fragment>
                        <span className="text">{getImageText()}</span>
                        { imageClass === IMAGE_TYPES.USER_UPLOAD && 
                            <div className={`image-icon`}>
                                <Icon name="image" />
                            </div>
                        }
                    </Fragment>
            }
        </div>
    );
};

Image.propTypes = {
    image: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    imageClass: PropTypes.oneOf([
        IMAGE_TYPES.REGULAR,
        IMAGE_TYPES.AVATAR,
        IMAGE_TYPES.USER_UPLOAD
    ])
}

Image.defaultProps = {
    imageClass: IMAGE_TYPES.REGULAR
};

export default Image;
