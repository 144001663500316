/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import { BUTTON_TYPES } from "../../../constants/enums";
import Button from '../../../components/Button';
import InputField from '../../../components/formFields/InputField';
import ENTITY from '../../../constants/entities';
import { formatRequestData } from '../../../utils/jsonApiHelpers';
import axiosClient, { POST } from '../../../utils/axiosClient';
import errorHandler from '../../../redux/saga/errorHandler';

const Invitation = ({
    input,
    bookingItemId,
    meta,
    meta: { error }
}) => {
    const translate = useTranslate();
    const [sendingStarted, setSendingStarted] = useState(false);
    const [isSended, setIsSended] = useState(false);

    useEffect(() => {
        if (sendingStarted) {
            const sendInvitation = async () => {
                try {
                    const preparedData = formatRequestData(ENTITY.EVENT_USERS, {
                        bookingItemEvent: { id: bookingItemId, type: ENTITY.EVENT },
                        userEmail: input.value
                    });
                    await axiosClient(POST, "/booking_item_event_users", preparedData);
                    setIsSended(true);
                } catch (error) {
                    await errorHandler(error);
                    setIsSended(false);
                }
            }
            sendInvitation();
            setSendingStarted(false);
        }
    }, [sendingStarted])

    const handleSendInvite = () => {
        setSendingStarted(true);
    }

    return (
        <div className="invitation-field">
            <div className="search-input">
                <InputField
                    input={input}
                    type="email"
                    label="form.fieldLabel.email"
                    required
                    meta={meta}
                    readOnly={isSended} />
            </div>
            { isSended ?
                <span className="status">{translate('general.pending')}</span> :
                !error &&
                <Button
                    type="button"
                    btnClass={isSended ? BUTTON_TYPES.DISABLED : BUTTON_TYPES.FILLED}
                    label={isSended ? "general.invitationSent" : "general.sendAnInvitation"}
                    disabled={isSended}
                    onClick={handleSendInvite} />
            }
        </div>
    )
}

Invitation.propTypes = {
    input: PropTypes.object.isRequired,
    bookingItemId: PropTypes.number,
    meta: PropTypes.object
}

export default Invitation;
