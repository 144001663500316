import { put, fork, takeLatest } from 'redux-saga/effects';
import {
    LOGIN_ACTION,
    REGISTER_EMAIL,
    REGISTER_USER,
    CHECK_REGISTER_TOKEN,
    FORGOT_PASSWORD_ACTION,
    RESET_PASSWORD_ACTION,
    GOOGLE_AUTHENTICATION,
    CHECK_PASSWORD_TOKEN
} from '../actions/authActions';
import { GET_CURRENT_USER } from '../actions/currentUserActions';

import axiosClient, { POST, GET, PATCH } from '../../utils/axiosClient';
import { deleteUserShoppingCart, setUserId, setUserToken } from '../../services/localStorage.service';
import history from '../../utils/history';
import errorHandler from './errorHandler';
import successHandler from './successHandler';
import { formatRequestData } from '../../utils/jsonApiHelpers';

import ENTITY from "../../constants/entities";

const PATH = "/users";

function* login({ data }) {
    try {
        const response = yield axiosClient(POST, '/login_check', data, false);
        const user = response.data;
        setUserToken(user.token);
        setUserId(user.user_id);
        deleteUserShoppingCart();
        history.push('/');
    } catch (error) {
        yield errorHandler(error);
    }
}

function* googleAuthentication({ data }) {
    try {
        const response = yield axiosClient(POST, '/google_authentication', data);
        const { detail, token, user_id } = response.data;
        if (detail) {
            yield successHandler("notification.message.successRegister");
        } else {
            setUserToken(token);
            setUserId(user_id);
            history.push('/');
        }
    } catch (error) {
        yield errorHandler(error);
    }

}

function* checkRegisterToken({ token }) {
    try {
        const response = yield axiosClient(GET, `${PATH}/check_register_token?token=${token}`);
        yield put({
            type: GET_CURRENT_USER,
            data: response.data.data
        });
    } catch (error) {
        yield errorHandler(error);
        history.push('/');
    }
}

function* registerEmail({ data }) {
    try {
        const preparedData = formatRequestData(ENTITY.USER, data);
        yield axiosClient(POST, `${PATH}/email_register`, preparedData);
        yield successHandler("notification.message.successRegister");
    } catch (error) {
        yield errorHandler(error);
    }
}

function* registerUser({ data }) {
    try {
        let { token, ...restData } = data;
        const preparedData = formatRequestData(ENTITY.USER, restData);
        yield axiosClient(PATCH, `${PATH}/${preparedData.data.id}/register?token=${token}`, preparedData);
        yield successHandler("notification.message.successCreatedUser");
        history.push('/login');
    } catch (error) {
        yield errorHandler(error);
    }
}

function* checkPasswordToken({ token }) {
    try {
        const response = yield axiosClient(GET, `${PATH}/check_password_token?token=${token}`);
        yield put({
            type: GET_CURRENT_USER,
            data: response.data.data
        });
    } catch (error) {
        yield errorHandler(error);
        history.push('/');
    }
}

function* forgotPassword({ data }) {
    try {
        const preparedData = formatRequestData(ENTITY.USER, data);
        yield axiosClient(POST, `${PATH}/forgot_password`, preparedData);
        yield successHandler("notification.message.successEmailSent");
    } catch (error) {
        yield errorHandler(error);
    }
}

function* resetPassword({ id, data }) {
    try {
        const { token, ...restData } = data;
        const preparedData = formatRequestData(ENTITY.USER, restData)
        yield axiosClient(PATCH, `${PATH}/${id}/reset_password?token=${token}`, preparedData);
        yield successHandler("notification.message.successPasswordChange");
    } catch (error) {
        yield errorHandler(error);
    }
}

const loginAsync = fork(function* () { yield takeLatest(LOGIN_ACTION, login) });
const googleAuthenticationAsync = fork(function* () { yield takeLatest(GOOGLE_AUTHENTICATION, googleAuthentication) });
const checkRegisterTokenAsync = fork(function* () { yield takeLatest(CHECK_REGISTER_TOKEN, checkRegisterToken) });
const registerEmailAsync = fork(function* () { yield takeLatest(REGISTER_EMAIL, registerEmail) });
const registerUserAsync = fork(function* () { yield takeLatest(REGISTER_USER, registerUser) });
const checkPasswordTokenAsync = fork(function* () { yield takeLatest(CHECK_PASSWORD_TOKEN, checkPasswordToken) });
const forgotPasswordAsync = fork(function* () { yield takeLatest(FORGOT_PASSWORD_ACTION, forgotPassword) });
const resetPasswordAsync = fork(function* () { yield takeLatest(RESET_PASSWORD_ACTION, resetPassword) });

export default [
    loginAsync,
    googleAuthenticationAsync,
    checkRegisterTokenAsync,
    registerEmailAsync,
    registerUserAsync,
    checkPasswordTokenAsync,
    forgotPasswordAsync,
    resetPasswordAsync
]