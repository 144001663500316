/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react';
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";

import { getIsLoading, getItemSelector } from "../../utils/selectors";
import { BASE_URL } from "../../utils/axiosClient";
import { formatDateTime } from '../../services/moment.service';
import Breadcrumbs from '../../components/Breadcrumbs';
import Loader from '../../components/Loader';
import { getBookingItemAction } from '../../redux/actions/bookingsActions';
import useFetchItemFromApi from '../../utils/fetchItemFromApi';
import ENTITY from '../../constants/entities';

const ViewUserBooking = ({
    match,
    getBookingItemAction,
    booking,
    isLoading,
    user
}) => {

    const translate = useTranslate();

    const { id } = match.params;

    useEffect(() => {
        getBookingItemAction(id);
    }, [])

    const { data } = useFetchItemFromApi(
        ENTITY.SERVICE_PLAN,
        booking?.servicePlan?.id,
        { include: "image" }
    )

    const status = useFetchItemFromApi(
        ENTITY.BOOKING_ITEM_STATUSES,
        booking?.status?.id,
        {}
    )

    if (isLoading) return <Loader />;

    return (
        <Fragment>
            <div className="booking">
                <div className="wrap header">
                    <div className="_12">
                        <Breadcrumbs label="general.backToBookings" />
                        <h1>{translate("general.viewBooking")}</h1>
                    </div>
                </div>
                <div className="wrap">
                    <div className="_12 booking-user">
                        <p className="title">{translate('general.bookingUser')}</p>
                        <div className="info">
                            <div className="img-name">
                                <img src={`${BASE_URL}${user?.avatarPath}`} alt="" className={user?.avatarPath ? "image -user" : "none"}/>
                                <div className="name">{`${user?.firstName} ${user?.lastName}`}</div>
                            </div>
                            <span className="email-title">{translate('general.email')}</span>
                            <span className="email">{user?.email}</span>
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="_12 booking-items">
                        <span className="a-line"></span>
                        <p className="title">{translate('general.bookingItems')}</p>
                        <div className="items">
                            <div  className="item-container">
                                <div className="item">
                                    <div className="info">
                                        <div>
                                            <img src={`${BASE_URL}${data.image?.path}`} alt="" className="image"/>
                                        </div>
                                        <div className="info-container">
                                            <span className="name">{data.name}</span>
                                            <span className="row">
                                                {translate('general.fromDate')} <span className="date-time">{formatDateTime(booking?.fromDate)}</span>
                                            </span>
                                            <span className="row">
                                                {translate('general.toDate')} <span className="date-time">{formatDateTime(booking?.toDate)}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="price-container">
                                        <span className="price-row">
                                            {translate('general.price')} <span className="price">{booking?.price}</span>
                                        </span>
                                        <span className="price-row">
                                            {translate('general.discount')} <span className="price">{`- ${booking?.discount}`}</span>
                                        </span>
                                        <span className="price-discount-row">
                                            {translate('general.priceWithDiscount')} <span className="price">{booking?.priceWithDiscount}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="status">
                                    <span title={translate('table.header.status')}>{status?.data?.name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    booking: getItemSelector(state.bookingItem),
    isLoading: getIsLoading(state.bookingItem),
    user: getItemSelector(state.currentUser)
});

const mapDispatchToProps = {
    getBookingItemAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserBooking)
