/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { connect } from "react-redux";
import { getItemSelector } from "../../utils/selectors";
import { getServicePlanListAction, resetServicePlanListsAction, resetServicePlanListAction } from "../../redux/actions/serviceActions";
import { addServiceToShoppingCartAction } from "../../redux/actions/shoppingCartActions";
import { calculatePriceWithCurrency } from '../../utils/calculatePrice';
import DateAndTime from '../../components/DateAndTime';
import Breadcrumbs from '../../components/Breadcrumbs';

const BookAService = ({ 
    service, 
    getServicePlanListAction, 
    addServiceToShoppingCartAction, 
    resetServicePlanListsAction,
    resetServicePlanListAction,
    currentUser,
    match 
}) => {
    const translate = useTranslate();
    const { id, priceLists } = match.params;

    const [date, setDate] = useState("");
    const [error, setError] = useState("");

    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(service);
    const { language } = currentUser;

    useEffect(() => {
        getServicePlanListAction(id, {include: 'userCurrency,discount,priceLists,planItems,planType,relatedServicePlans,image', priceLists: priceLists});
        return () => {
            resetServicePlanListsAction();
            resetServicePlanListAction();
        }
    }, [language.code])

    const handleChangeDate = (value) => {
        setDate(value);
    }

    const handleAddToShoppingCart = () => {
        if (date) {
            addServiceToShoppingCartAction(
                {...service, fromDate: date, priceListId: service.priceLists[0].data.id}
            );
        } else {
            setError('form.error.required');
        }
    }

    if (!service) return null;

    return (
        <Fragment>
            <div className="book-a-service">
                <div className="wrap service">
                    <div className="_12">
                        <Breadcrumbs label="general.backToServices" />
                        <div className="title">
                            <h1>{translate(`${service.type}.${service.id}.name`)}</h1>
                            <span className="service-price">
                                {discount ? priceWithDiscount : price}
                            </span>
                            {discount &&
                                <div className="a-discount-container">
                                    <span className="price">{price}</span>
                                    <span className="a-discount">{`- ${discount}%`}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <DateAndTime 
                    borderBottom={true} 
                    date={date}
                    error={!date && error ? error : ""}
                    setError={setError} 
                    handleChangeDate={handleChangeDate}
                    action={handleAddToShoppingCart}
                    btnLabel="general.bookAService" />
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    service: getItemSelector(state.servicePlanList),
    currentUser: getItemSelector(state.currentUser)
})

const mapDispatchToProps = {
    addServiceToShoppingCartAction,
    getServicePlanListAction,
    resetServicePlanListsAction,
    resetServicePlanListAction
}

export default connect(mapStateToProps, mapDispatchToProps)(BookAService);
