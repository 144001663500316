import { put, fork, takeLatest } from "redux-saga/effects";
import { GET_ROLES, GET_ROLES_SUCCESS } from "../actions/rolesActions";

import axiosClient, { GET } from "../../utils/axiosClient";
import errorHandler from "./errorHandler";
import { formatResponseData } from "../../utils/jsonApiHelpers";

const PATH = "/roles";

function* getRoles() {
    try {
        const response = yield axiosClient(GET, PATH);
        const data = formatResponseData(response.data);
        yield put({ type: GET_ROLES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

const getRolesAsync = fork(function* () { yield takeLatest(GET_ROLES, getRoles) });

export default [
    getRolesAsync
];