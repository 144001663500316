import React from 'react'
import moment from "moment";
import { useTranslate } from 'react-polyglot';

import Select from '../Select';
import { CALENDAR_YEARS_PERIOD } from '../../constants/enums';

export const generateYears = (yearsPeriod) => {
    const currentYear = moment().year();
    const yearOptions = [];

    switch (yearsPeriod) {
        case CALENDAR_YEARS_PERIOD.NEXT:
            for (let i = currentYear - 2; i <= currentYear + 10; i++) {
                yearOptions.push({
                    value: i,
                    label: i,
                })
            }
            break;
        case CALENDAR_YEARS_PERIOD.PREVIOUS:
            for (let i = 1930; i <= currentYear; i++) {
                yearOptions.push({
                    value: i,
                    label: i,
                })
            }
            break;
        default:
            for (let i = currentYear - 2; i <= currentYear + 10; i++) {
                yearOptions.push({
                    value: i,
                    label: i,
                })
            }
    }
    return yearOptions;
}

const CalendarHeader = ({
    currentMonth,
    setCurrentMonth,
    selectedDate,
    label,
    yearsPeriod
}) => {

    const translate = useTranslate();

    const onYearClick = event => {
        const year = event.target.value;
        setCurrentMonth(moment(selectedDate).set('year', year));
    }

    const nextMonth = () => {
        setCurrentMonth(moment(currentMonth).add(1, "month"))
    };

    const prevMonth = () => {
        setCurrentMonth(moment(currentMonth).subtract(1, "month"))
    };

    const renderMonthLabel = () => {
        const monthFormat = "M";
        const monthIndex = moment(currentMonth).format(monthFormat);
        const headerLabel = translate(`calendar.month${monthIndex}`)
        return headerLabel;
    }

    return (
        <div className="header row flex-middle">
            <div className="row _aic">
                <div className="col col-end">
                    <div className="header-container">
                        {label && <p className="header-title">{label}</p>}
                        <div className="header-date">
                            <span className="left" onClick={prevMonth}></span>
                            <span className="month-title">{renderMonthLabel()}</span>
                            <Select
                                name="year"
                                defaultValue={moment(currentMonth).year()}
                                onChange={onYearClick}
                                options={generateYears(yearsPeriod)}
                                required
                            />
                            <span className="right" onClick={nextMonth}></span>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CalendarHeader;
