import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ProgressRing = ({ radius, stroke, part, total }) => {

    const normalizedRadius = radius - stroke;
    const circumference = normalizedRadius * 2 * Math.PI;
    const progress = (part * 100) / total;
    const strokeDashoffset = circumference - progress / 100 * circumference;

    return (
        <Fragment>
            <svg className="a-progress-ring" height={radius * 2}  width={radius * 2}>
                <circle className="bg"
                    fill="transparent"
                    strokeWidth={stroke}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle className="value"
                    stroke="url(#purple-gradient)"
                    fill="transparent"
                    strokeWidth={stroke + 8}
                    strokeDasharray={ circumference + ' ' + circumference }
                    style={{strokeDashoffset}}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>
            <svg width="0" height="0">
                <defs>
                <linearGradient id="purple-gradient" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
                    <stop stopColor="#b35cea"/>
                    <stop offset="100%" stopColor="#6645d3"/>
                </linearGradient>
                </defs>
            </svg>
        </Fragment>
    )
}

ProgressRing.propTypes = {
    radius: PropTypes.number.isRequired,
    stroke: PropTypes.number.isRequired,
    part: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
}

export default ProgressRing;
