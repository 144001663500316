import React from "react";
import { connect } from "react-redux";

import InventoryForm from "../InventoryForm";

import { createInventoryAction } from "../../../../redux/actions/inventoryActions";

const CreateInventory = ({
    createInventoryAction,
    tableParams,
    history
}) => {

    const onSubmit = data => {
        createInventoryAction(data, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <InventoryForm
            title="general.createInventory"
            onSubmit={onSubmit}
            submitLabel="general.createInventory"
            handleCancel={handleCancel}
        />
    )
}

const mapStateToProps = state => ({
    tableParams: state.tableParams
});

const mapDispatchToProps = {
    createInventoryAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInventory)