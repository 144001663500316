import { GET_ROLES, GET_ROLES_SUCCESS } from "../actions/rolesActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
}

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROLES:
            return { data: null, meta: null, isLoading: true };
        case GET_ROLES_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
}

export { rolesReducer };