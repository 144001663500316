import React from "react"
import { Field, reduxForm } from "redux-form";

import ModalForm from "../../../../components/ModalForm";
import InputField from "../../../../components/formFields/InputField";
import TextAreaField from "../../../../components/formFields/TextAreaField";
import SelectFieldRelationship from "../../../../components/formFields/SelectFieldRelationship";
import DateTimePicker from "../../../../components/formFields/DateTimePicker";
import SelectMultipleFieldRelationship from "../../../../components/formFields/SelectMultipleFieldRelationship";

import { required } from "../../../../utils/validation";
import ENTITY from "../../../../constants/entities";
import { DISCOUNT_TYPES } from "../../../../constants/enums";

// Render relationship field based on discountType
const renderRelationalField = (discountType, change) => {
    switch (discountType) {
        case DISCOUNT_TYPES.SERVICE:
            return (
                <Field
                    name="servicePlans"
                    label="form.fieldLabel.servicePlans"
                    onSelect={change}
                    entityType={ENTITY.SERVICE_PLAN}
                    component={SelectMultipleFieldRelationship}
                />
            )
        case DISCOUNT_TYPES.USER:
            return (
                <Field
                    name="users"
                    label="form.fieldLabel.users"
                    onSelect={change}
                    entityType={ENTITY.USER}
                    component={SelectMultipleFieldRelationship}
                />
            )
        default:
            return null;
    }
}

const DiscountForm = ({
    title,
    change,
    handleSubmit,
    onSubmit,
    submitLabel,
    handleCancel,
    selectedDiscountType,
    initialValues
}) => {
    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
        >
            <div className="wrap">
                <div className="_mobile-m6 mv-10">
                    <Field
                        name="discountType.id"
                        label="form.fieldLabel.discountType"
                        onSelect={change}
                        entityType={ENTITY.DISCOUNT_TYPE}
                        component={SelectFieldRelationship}
                        readOnly={!!initialValues}
                        validate={required}
                        required
                    />
                </div>
                <div className="_mobile-m6 mv-10"></div>
                <div className="_mobile-m6 mv-10">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.name"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_mobile-m6 mv-10">
                    <Field
                        type="number"
                        name="percentDiscount"
                        label="form.fieldLabel.percentDiscount"
                        component={InputField}
                        icon="discounts"
                        validate={required}
                        required
                    />
                </div>
                <div className="_mobile-m6 mv-10">
                    <Field
                        name="fromDate"
                        label="form.fieldLabel.fromDate"
                        component={DateTimePicker}
                        validate={required}
                        required
                    />
                </div>
                <div className="_mobile-m6 mv-10">
                    <Field
                        name="toDate"
                        label="form.fieldLabel.toDate"
                        component={DateTimePicker}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        type="text"
                        name="description"
                        label="form.fieldLabel.description"
                        component={TextAreaField}
                    />
                </div>
                <div className="_mobile-m6 mv-10">
                    {renderRelationalField(selectedDiscountType, change)}
                </div>
            </div>
        </ModalForm>
    )
}

const validate = values => {
    const errors = {}
    if (values.fromDate >= values.toDate) {
        errors.fromDate = "form.error.fromHoursGreater"
    }
    return errors
}

export default reduxForm({
    form: "DiscountForm",
    validate
})(DiscountForm)
