import React from 'react';
import PropTypes from 'prop-types';

// NOTE:: Still needs logic for checked values
const Checkbox = ({
    display,
    value,
    checked,
    disabled,
    onClick,
}) => {

    if (!display) return null;
    return (
        <td className='a-checkbox'>
            <input
                value={value}
                className='checkbox -dark'
                type='checkbox'
                defaultChecked={checked}
                onClick={onClick}
            />
            <label />
        </td>
    )
}

Checkbox.propTypes = {
    display: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default Checkbox;