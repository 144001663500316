import React, { useState } from "react"
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";
import PropTypes from "prop-types";
import useFetchTicketComments from '../../hooks/useFetchTicketComments'
import { createTicketCommentAction } from "../../../../../redux/actions/ticketsActions";

import Button from "../../../../../components/Button";
import Image from '../../../../../components/Image';
import TicketDetailsContainer from "./TicketDetailsContainer";
import TicketComment from "./TicketComment";

import renderTicketStatusBadge from "./renderTicketStatusBadge";
import { formatDate } from "../../../../../services/moment.service";

import { TICKET_STATUSES, BUTTON_ICON_POSITIONS, BUTTON_TYPES } from "../../../../../constants/enums";
import { IMAGE_TYPES } from '../../../../../constants/enums';


const TicketItem = ({
    ticket,
    selectedTicketId,
    setSelectedTicket,
    createTicketCommentAction
}) => {
    const translate = useTranslate();

    const [openEdit, setOpenEdit] = useState(false);

    const { _id: id, createdDate: date, status, author: user, subject, text, category } = ticket;

    const handleOpenEdit = (id) => {
        if (setSelectedTicket) {
            setSelectedTicket(id);
        }
        setOpenEdit(true);
    }

    const handleEditComment = (data) => {
        if (id) {
            createTicketCommentAction(id, data);
            setOpenEdit(false);
        }
    }

    const handleCloseEdit = () => {
        if (setSelectedTicket) {
            setSelectedTicket(0);
        }
        setOpenEdit(false);
    }

    const statusName = translate(`${status.type}.${status.id}.name`);

    const isTicketClosed = status._id === TICKET_STATUSES.CLOSED;
    const showDetails = id === selectedTicketId;

    const { firstName, lastName, avatarPath } = user;

    const { comments, isLoading } = useFetchTicketComments(id, showDetails, openEdit);

    return (
        <li className="m-list -ticket">
            <div className="ticket-top">
                <div className="ticket-top-title">
                    <span className="date-label">{formatDate(date)}</span>
                    {category && <span>
                                    <span className="category" >{translate('general.category')}</span>
                                    <span className="category-name" >{translate(`${category.type}.${category.id}.name`)}</span>
                                </span>
                    }
                    <span className="subject-label">
                        {subject}
                        <span className="status">{renderTicketStatusBadge(status.id, statusName)}</span>
                    </span>
                </div>
                <div className="ticket-top-creator">
                    <div className="m-avatar-container">
                        <Image firstName={firstName} lastName={lastName} image={avatarPath} imageClass={IMAGE_TYPES.AVATAR} />
                        <span className="m-avatar-info">
                            <span className="m-avatar-info -title -creator">{translate('general.submitedBy')}:</span>
                            <span className="m-avatar-info -name">{`${firstName} ${lastName}`}</span>
                        </span>
                    </div>
                    {!isTicketClosed && <Button
                        label="general.editTicket"
                        iconPosition={BUTTON_ICON_POSITIONS.CENTER}
                        icon="corner-up-left"
                        btnClass={BUTTON_TYPES.FILLED}
                        onClick={() => handleOpenEdit(id)}
                    />}
                </div>
            </div>
            {showDetails &&
                <TicketDetailsContainer
                    comments={comments}
                    ticketCreator={firstName}
                    text={text}
                    isLoading={isLoading}
                />
            }
            <div className="ticket-bottom">
                {setSelectedTicket ? showDetails ?
                    <span onClick={() => setSelectedTicket(0)} className="show-details">{translate('general.showLess')}</span> :
                    <span onClick={() => setSelectedTicket(id)} className="show-details">{translate('general.showDetails')}</span>
                    : null
                }
            </div>
            {(!isTicketClosed && openEdit) && <TicketComment handleSubmit={handleEditComment} handleCloseComment={handleCloseEdit} />}
        </li>
    )
}

TicketItem.propTypes = {
    ticket: PropTypes.object.isRequired,
    selectedTicketId: PropTypes.number.isRequired,
    setSelectedTicket: PropTypes.func
}

const mapDispatchToProps = {
    createTicketCommentAction
};

export default connect(null, mapDispatchToProps)(TicketItem);