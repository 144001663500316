import React from "react";
import { Field, reduxForm } from "redux-form";

import ModalForm from "../../../../../components/ModalForm";
import InputField from "../../../../../components/formFields/InputField";
import InputDate from "../../../../../components/formFields/InputDate";
import SelectFieldRelationship from "../../../../../components/formFields/SelectFieldRelationship";
import FileUploadDnD from "../../../../../components/formFields/FileUploadDnD";

import { required } from "../../../../../utils/validation";
import { CALENDAR_YEARS_PERIOD, FILE_TYPES } from "../../../../../constants/enums";
import ENTITY from "../../../../../constants/entities";

const DocumentForm = ({
    title,
    handleSubmit,
    handleCancel,
    onSubmit,
    submitLabel,
    change,
}) => {
    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
        >
            <div className="wrap">
                <div className="_m6 mv-10">
                    <Field
                        name="documentType.id"
                        label="form.fieldLabel.documentType"
                        entityType={ENTITY.DOCUMENT_TYPES}
                        onSelect={change}
                        component={SelectFieldRelationship}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.name"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        type="text"
                        name="description"
                        label="form.fieldLabel.description"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="createdDate"
                        label="form.fieldLabel.createdDate"
                        component={InputDate}
                        validate={required}
                        required
                        yearsPeriod={CALENDAR_YEARS_PERIOD.PREVIOUS}
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="expiryDate"
                        label="form.fieldLabel.expiryDate"
                        validate={[required]}
                        component={InputDate}
                        required
                        yearsPeriod={CALENDAR_YEARS_PERIOD.NEXT}
                    />
                </div>

                <div className="_m12 mv-10">
                    <Field
                        name="file"
                        component={FileUploadDnD}
                        onImageChange={change}
                        type={FILE_TYPES.FILE}
                    />
                </div>
            </div>
        </ModalForm>
    )
}

export default reduxForm({
    form: "DocumentForm",
    enableReinitialize: true
})(DocumentForm)