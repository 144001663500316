import React, { useEffect } from "react"
import { connect } from "react-redux"

import InventoryForm from "../InventoryForm";

import { getInventoryAction, editInventoryAction } from "../../../../redux/actions/inventoryActions"
import { getItemSelector } from "../../../../utils/selectors";

const EditInventory = ({
    getInventoryAction,
    editInventoryAction,
    initialValues,
    tableParams,
    match,
    history
}) => {

    useEffect(() => {
        getInventoryAction(match.params.id)
    }, [getInventoryAction, match.params.id]);

    const onSubmit = data => {
        editInventoryAction(data, tableParams);
    };

    const handleCancel = () => {
        history.goBack();
    };

    if (!initialValues) return null;

    return (
        <InventoryForm
            title="general.editInventory"
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitLabel="general.editInventory"
            handleCancel={handleCancel}
        />
    )
}

const mapStateToProps = (state) => ({
    initialValues: getItemSelector(state.inventory),
    tableParams: state.tableParams
})

const mapDispatchToProps = {
    getInventoryAction,
    editInventoryAction
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInventory)
