import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { INPUT_MODE } from '../../constants/enums';
import Icon from "../Icon";
import { isEmptyOrWhiteSpaces } from '../../utils/checkValue';
import serbiaIcon from "../../assets/img/icons/serbia-flag.png";

const renderIcon = (type, iconName, isActive) => {
    let icon = null;
    const iconColor = isActive ? "#ea68a8" : "#D9DFE4";

    switch (type) {
        case "tel":
            icon = <img className="mr-5" src={serbiaIcon} alt="" />
            break
        case "email":
            icon = <Icon name="mail-opened" color={iconColor} />
            break;
        case "password":
            icon = <Icon name="lock" color={iconColor} />
            break;
        default:
            return;
    }

    if (iconName) {
        icon = <Icon name={iconName} color={iconColor} />
    }

    if (!icon) return null;

    return <div className="a-input-icon -active">{icon}</div>
}



const InputField = ({
    input,
    type,
    label,
    placeholder,
    readOnly,
    icon,
    mode,
    required,
    meta: { error, touched, active }
}) => {

    const hasError = touched && error;
    const translate = useTranslate();

    const filled = type === "number" ? input.value : !isEmptyOrWhiteSpaces(input.value);

    let isIcon = false;
    if (type === "tel" || type === "email" || type === "password" || icon) {
        isIcon = true;
    }

    return (
        <Fragment>
            <div className="a-input-group">
                <input
                    {...input}
                    id={input.name}
                    type={type}
                    placeholder={placeholder ? translate(placeholder) : ""}
                    disabled={readOnly}
                    className={`a-input -${mode} ${filled ? "-filled" : ""} ${hasError ? "-error" : ""} ${isIcon ? '-hasIcon' : ''}`}
                />
                <label htmlFor={input.name} className={`-${mode} ${required ? "-required" : ""}`}>{translate(label)}</label>
                {renderIcon(type, icon, active)}
                {hasError && <span className="a-input-error">{translate(error)}</span>}
            </div>
        </Fragment>
    )
}

InputField.propTypes = {
    type: PropTypes.oneOf([
        "text",
        "password",
        "email",
        "number",
        "tel"
    ]).isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    mode: PropTypes.oneOf([
        INPUT_MODE.DARK,
        INPUT_MODE.LIGHT
    ]),
    required: PropTypes.bool
}

InputField.defaultProps = {
    input: {},
    meta: {},
    mode: INPUT_MODE.DARK,
    required: false
}

export default InputField;