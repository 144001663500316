import { put } from 'redux-saga/effects';
import { SET_ALERT } from '../actions/alertActions';
import { ALERT_TYPES } from '../../constants/enums';


function* errorHandler(error) {
    let errMessage = '';
    let errTitle = 'Something Went Wrong!';
    if (!error.response) {
        errTitle = error.message;
    } else {
        if (error.response.data) {
            const { title, detail, message } = error.response.data;
            if (title) {
                errTitle = title;
            }
            if (detail) {
                errMessage = detail;
            } else if (message) {
                errMessage = message;
            }
        }
    }
    yield put({
        type: SET_ALERT,
        data: {
            type: ALERT_TYPES.ERROR,
            title: errTitle,
            message: errMessage
        }
    })
}

export default errorHandler;
