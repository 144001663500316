import React from 'react';
import { useTranslate } from 'react-polyglot';
import history from "../../utils/history";

const Breadcrumbs = ({ label }) => {

    const translate = useTranslate();

    return (
        <div className="a-breadcrumbs-container" onClick={history.goBack}>
            <i className="m-avatar-chevron -left"></i>
            <p className="-regular a-breadcrumbs">{translate('general.back')}</p>
        </div>
    )
}

export default Breadcrumbs;
