/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";
import ENTITY from '../../constants/entities';
import SelectSearchField from './SelectSearchField';

const ArrayField = ({ 
    input,
    label,
    entityType,
    index,
    fields,
    item,
    required,
    meta,
    isAdditions
}) => {

    const include = () => {
        switch (entityType) {
            case ENTITY.USER:
                return "image";
            case ENTITY.SERVICE_PLAN:
                return "image,discounts,priceLists,planItems,planType";
            default:
                return "";
        }
    }
    
    const [price, setPrice] = useState();
    const [selectedValue, setSelectedValue] = useState();

    const handleSelect = (value) => {
        setSelectedValue(value)
        if (entityType === ENTITY.SERVICE_PLAN && value && value.priceLists) {
            setPrice(value.priceLists[0]);
            input.onChange({ ...value, selectedPriceList: value.priceLists[0].id});
        } else if (entityType === ENTITY.USER) {
            fields.get(index).id = value.id;
            input.onChange(value);
        }else if(entityType === ENTITY.SERVICE_PLAN_LISTS) {
            input.onChange(value);
        }
       
    }

    const handlePrice = (item) => {
        setPrice(item);
        input.onChange({ ...selectedValue, selectedPriceList: item.id });
    }

    const renderInfo = () => {
        if (selectedValue && input.value) {
            switch (entityType) {
                case ENTITY.SERVICE_PLAN:
                    return (
                        <div className="info">
                            {selectedValue.priceLists && selectedValue.priceLists[0] &&
                                <div className="btn-group">
                                    {selectedValue.priceLists.map(priceList => (
                                        <Fragment key={`${item}${priceList.id}`}>
                                            <input 
                                                type="radio"
                                                name={`${item}-group`}
                                                id={`${item}${priceList.id}`}
                                                value={priceList.id}
                                                defaultChecked={price.id === priceList.id}
                                                onChange={() => handlePrice(priceList)}
                                            />
                                            <label htmlFor={`${item}${priceList.id}`}>
                                                {priceList.name}
                                                <span className="price">{priceList.priceForUserCurrency}</span>
                                            </label>
                                        </Fragment>
                                    ))}
                                </div>}
                        </div>
                    );
                default:
                    return <div className="info"></div>;
            }
            
        }
        return <div className="info"></div>;
    }

    return (
        <div className="field">
            <div className="search-input">
                <SelectSearchField 
                    input={input}
                    label={label}
                    entityType={entityType}
                    params={isAdditions ? { onlyWithPriceLists: true, planType: 3 } : { include: include() }}
                    searchable={true}
                    required={required}
                    customAction={handleSelect}
                    meta={meta}
                />
            </div>
            { selectedValue && renderInfo() }
        </div>
    )
};

ArrayField.propTypes = {
    input: PropTypes.object,
    type: PropTypes.oneOf([
        "text",
        "password",
        "email",
        "number",
        "tel"
    ]).isRequired,
    label: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    required: PropTypes.bool,
    meta: PropTypes.object
}

ArrayField.defaultProps = {
    input: {},
    meta: {},
    required: false
}

export default ArrayField;
