import { put, fork, takeLatest } from "redux-saga/effects";
import {
    GET_INVENTORIES,
    GET_INVENTORIES_SUCCESS,
    GET_INVENTORY,
    GET_INVENTORY_SUCCESS,
    CREATE_INVENTORY,
    EDIT_INVENTORY
} from "../actions/inventoryActions";

import axiosClient, { GET, POST, PATCH } from "../../utils/axiosClient";
import { formatUrl, formatRequestData, formatResponseData } from "../../utils/jsonApiHelpers";
import history from "../../utils/history"
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";

import ENTITY from "../../constants/entities";

const PATH = "/inventories";

function* getInventories({ params }) {
    try {
        const url = formatUrl(PATH, params);
        const response = yield axiosClient(GET, url);
        const data = formatResponseData(response.data);
        yield put({ type: GET_INVENTORIES_SUCCESS, data })
    } catch (error) {
        yield errorHandler(error);
    }
};

function* getInventory({ id }) {
    try {
        const response = yield axiosClient(GET, `${PATH}/${id}`);
        const data = formatResponseData(response.data);
        yield put({ type: GET_INVENTORY_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* createInventory({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.INVENTORY, data);
        yield axiosClient(POST, PATH, preparedData);
        yield successHandler("notification.message.successCreatedInventory");
        history.goBack();
        yield getInventories({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* editInventory({ data, params }) {
    try {
        const preparedData = formatRequestData(ENTITY.INVENTORY, data);
        yield axiosClient(PATCH, `${PATH}/${data._id}`, preparedData);
        yield successHandler("notification.message.successEditedInventory");
        history.goBack();
        yield getInventories({ params });
    } catch (error) {
        yield errorHandler(error);
    }
};

const getInventoriesAsync = fork(function* () { yield takeLatest(GET_INVENTORIES, getInventories) });
const getInventoryAsync = fork(function* () { yield takeLatest(GET_INVENTORY, getInventory) });
const createInventoryAsync = fork(function* () { yield takeLatest(CREATE_INVENTORY, createInventory) });
const editInventoryAsync = fork(function* () { yield takeLatest(EDIT_INVENTORY, editInventory) });

export default [
    getInventoriesAsync,
    getInventoryAsync,
    createInventoryAsync,
    editInventoryAsync
];