import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";

import { getUserAction, deleteUserAction } from "../../../../redux/actions/usersActions";
import { getItemSelector } from "../../../../utils/selectors";

import Modal from "../../../../components/Modal";

const DeleteUser = ({
    getUserAction,
    deleteUserAction,
    user,
    tableParams,
    match,
    history
}) => {
    const translate = useTranslate();

    useEffect(() => {
        getUserAction(match.params.id)
    }, [getUserAction, match.params.id]);


    const handleCancelAction = () => {
        history.goBack();
    }

    const handleDeleteUser = () => {
        deleteUserAction(user, tableParams);
        history.goBack();
    };

    if (!user) return null;

    return (
        <Modal
            open
            text={`${translate("general.aysDeleteUser")} ${user.firstName} ${user.lastName} ?`}
            handleCancel={handleCancelAction}
            handleSubmit={handleDeleteUser}
            submitLabel="general.deleteUser"
            cancelLabel="general.cancel"
        />
    )
};

const mapStateToProps = state => ({
    user: getItemSelector(state.user),
    tableParams: state.tableParams
});

const mapDispatchToProps = {
    getUserAction,
    deleteUserAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser);
