import React from 'react'
import LanguagePicker from '../LanguagePicker'


// TODO: change topnav class for nonauth routes
const LayoutNoAuth = ({
    children
}) => {
    return (
        <div className="no-auth-container">
            <div className="language-container">
                <LanguagePicker />
            </div>
            <div className="wrap m-0">
                <div className="_s7 no-auth-background" />
                <div className="_s5 no-auth-form">
                    {children}
                </div>
            </div>
        </div >
    )
}

export default LayoutNoAuth;
