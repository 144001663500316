import React from "react";
import { useRef } from "react";
import PropTypes from 'prop-types';
import { useEffect } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import DateTimePicker from "../formFields/DateTimePicker";
import ModalForm from "../ModalForm";
import ENTITY from '../../constants/entities';
import InputFieldArray from "../formFields/InputFieldArray";
import { formatDateTimeServer } from "../../services/moment.service";
import { addPackageToShoppingCartAction } from "../../redux/actions/shoppingCartActions";
import { getItemSelector } from "../../utils/selectors";
import { connect } from "react-redux";
import { createBookingAction } from "../../redux/actions/bookingsActions";
import { useTranslate } from "react-polyglot";


const RenewPackage = ({open, handleCancel, handleSubmit, clickOutside = false, initialValues, currentUser, createBookingAction, additions }) => {

    const modalRef = useRef(null);
    const translate = useTranslate();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClickOutside = (event) => {
        if (open && modalRef && modalRef.current && !modalRef.current.contains(event.target)) {
            handleCancel();
        }
    };

    useEffect(() => {
        if (clickOutside) {
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [clickOutside, handleClickOutside, open]);

    const transformData = (inputData) => {
        const additionData = inputData.servicePlan || inputData;
        return {
            id: additionData.id,
            // isFree: true,
            priceListId: additionData.priceLists ? additionData.priceLists[0].data.id : additionData.relationships.priceLists.data[0].id,
            discountId: additionData.discount?.id,
            fromDate: formatDateTimeServer(inputData.toDate)
        }
    }

    const onSubmit = (newData) => {
        let data = [];
        let packagesData = [];
        let additionsData = [];
        packagesData.push({...newData.activePackage.servicePlan, fromDate: newData.renewFromDate, priceListId: newData.activePackage.servicePlan.relationships.priceLists.data[0].id});
        if (newData.additions.length > 0) {
            additionsData = newData.additions.map(addition => transformData(addition))
        }

        data = [...packagesData, ...additionsData];

        createBookingAction({ servicePlans: data });
    }

    if (!initialValues) return null;

    return (
        <ModalForm
            open
            title="general.renew"
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel="form.button.submit"
            cancelLabel="form.button.cancel"
        >   
            <h2 className="activePackageTitle">{initialValues.activePackage.servicePlan.name}</h2>
            <div className="reniewNote">
                <span>
                    {translate('general.note')}
                </span>
            </div>
            <div className="_12">
                <Field
                    name="renewFromDate"
                    component={DateTimePicker}
                    required
                />
            </div>
            <div className="_12">
                <span className="a-line"></span>
                <FieldArray 
                    name="additions" 
                    component={InputFieldArray}
                    entityType={ENTITY.SERVICE_PLAN_LISTS}
                    btnLabel="general.chooseMoreAdditions"
                />
            </div>
        </ModalForm>  
    )
};

RenewPackage.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    submitLabel: PropTypes.string,
    clickOutside: PropTypes.bool,
}

const mapStateToProps = state => ({
    currentUser: getItemSelector(state.currentUser),
    packages: state.shoppingCart.packages,
    additions: state.shoppingCart.additions
});

const mapDispatchToProps = {
    addPackageToShoppingCartAction,
    createBookingAction
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "RenewPackage", enableReinitialize: true })(RenewPackage));
