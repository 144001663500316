import React from 'react';
import PropTypes from 'prop-types';
import EventCard from './EventCard';

const EventCards = ({ 
    events,
    answers
}) => {
    return (
        <ul className="wrap m-list-container">
            {events.map(item => <EventCard 
                                    key={item.id} 
                                    event={item} 
                                    answers={answers}
                                />)}
        </ul>
    )
}

EventCards.propTypes = {
    events: PropTypes.array.isRequired,
    answers: PropTypes.array
}

export default EventCards;
