export const GET_DOCUMENT_TYPES = '@DCT/GET_DOCUMENT_TYPES';
export const GET_DOCUMENT_TYPES_SUCCESS = '@DCT/GET_DOCUMENT_TYPES_SUCCESS';
export const getDocumentTypesAction = params => ({
    type: GET_DOCUMENT_TYPES,
    params
});

export const GET_DOCUMENT_TYPE = '@DCT/GET_DOCUMENT_TYPE';
export const GET_DOCUMENT_TYPE_SUCCESS = '@DCT/GET_DOCUMENT_TYPE_SUCCESS';
export const getDocumentTypeAction = (id, params) => ({
    type: GET_DOCUMENT_TYPE,
    id,
    params
})

export const CREATE_DOCUMENT_TYPE = '@DCT/CREATE_DOCUMENT_TYPE';
export const createDocumentTypeAction = (data) => ({
    type: CREATE_DOCUMENT_TYPE,
    data
});

export const EDIT_DOCUMENT_TYPE = '@DCT/EDIT_DOCUMENT_TYPE';
export const editDocumentTypeAction = (data) => ({
    type: EDIT_DOCUMENT_TYPE,
    data
});