import { put, fork, takeLatest, delay } from "redux-saga/effects";
import {
    CREATE_BOOKING,
    CREATE_BOOKING_SUCCESS,
    ADD_PACKAGE_TO_SHOPPING_CART,
    ADD_PACKAGE_TO_SHOPPING_CART_SUCCESS,
    CHECK_PACKAGE,
    CHECK_PACKAGE_SUCCESS,
    CHECK_PACKAGE_FAILED,
    ADD_SERVICE_TO_SHOPPING_CART,
    ADD_SERVICE_TO_SHOPPING_CART_SUCCESS,
    ADD_ADDITION_TO_SHOPPING_CART,
    ADD_ADDITION_TO_SHOPPING_CART_SUCCESS
} from "../actions/shoppingCartActions";

import axiosClient, { POST } from "../../utils/axiosClient";
import errorHandler from "./errorHandler";
import successHandler from "./successHandler";
import { getBookingItems } from "../saga/bookings.saga";
import { getUserShoppingCart } from '../../services/localStorage.service';
import { formatDateTimeServer } from "../../services/moment.service";

const BOOKING_PATH = "/bookings";
const SERVICE_PLAN_AVAILABILITY_PATH = "/service_plans_availability";

function* createBooking({ data }) {
    try {
        yield axiosClient(POST, BOOKING_PATH, data);
        yield successHandler("notification.message.successCreateBooking");
        yield delay(2000);
        yield getBookingItems({params: {
            active: true,
            include: "servicePlan,status",
            pagination: false
        }});
        yield put({ type: CREATE_BOOKING_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
}

const transformData = (data) => {
    return {
        id: data.id,
        priceListId: data.priceListId,
        fromDate: formatDateTimeServer(data.fromDate)
    }
}

function* checkAvailability({ type, data }) {
    try {
        const { packages, additions, services } = getUserShoppingCart();
        const newServicePlan = transformData(data);
        const currentServicePlans = [...packages, ...additions, ...services].map(item => transformData(item));
        yield axiosClient(POST, SERVICE_PLAN_AVAILABILITY_PATH, { newServicePlan, currentServicePlans });
        yield put({ type, data });
        yield successHandler("notification.message.available");
    } catch (error) {
        throw error;
    }
};

function* addPackageToShoppingCart({ data }) {
    try {
        yield checkAvailability({ type: ADD_PACKAGE_TO_SHOPPING_CART_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* checkPackage({ data }) {
    try {
        yield checkAvailability({ type: CHECK_PACKAGE_SUCCESS, data });
    } catch (error) {
        yield put({ type: CHECK_PACKAGE_FAILED });
        yield errorHandler(error);
    }
};

function* addAdditionToShoppingCart({ data }) {
    try {
        yield checkAvailability({ type: ADD_ADDITION_TO_SHOPPING_CART_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

function* addServiceToShoppingCart({ data }) {
    try {
        yield checkAvailability({ type: ADD_SERVICE_TO_SHOPPING_CART_SUCCESS, data });
    } catch (error) {
        yield errorHandler(error);
    }
};

const createBookingAsync = fork(function* () { yield takeLatest(CREATE_BOOKING, createBooking) });
const addPackageToShoppingCartAsync = fork(function* () { yield takeLatest(ADD_PACKAGE_TO_SHOPPING_CART, addPackageToShoppingCart) });
const checkPackageAsync = fork(function* () { yield takeLatest(CHECK_PACKAGE, checkPackage) });
const addAdditionToShoppingCartAsync = fork(function* () { yield takeLatest(ADD_ADDITION_TO_SHOPPING_CART, addAdditionToShoppingCart) });
const addServiceToShoppingCartAsync = fork(function* () { yield takeLatest(ADD_SERVICE_TO_SHOPPING_CART, addServiceToShoppingCart) });

export default [
    createBookingAsync,
    addPackageToShoppingCartAsync,
    checkPackageAsync,
    addAdditionToShoppingCartAsync,
    addServiceToShoppingCartAsync
];