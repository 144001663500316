/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react'
import { connect } from "react-redux";

import { getServicePlanItemsAction } from "../../../redux/actions/serviceActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";
import AuthModalRoute from "../../../components/routes/AuthModalRoute";
import CreateServiceItem from "./CreateServiceItem";
import EditServiceItem from "./EditServiceItem";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "itemType.name",
        title: "table.header.serviceType"
    },
    {
        key: "name",
        title: "table.header.name",
    },
    {
        key: "value",
        title: "table.header.value",
    }
]


const ServiceItemManagement = ({
    getServicePlanItemsAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleCreateModal = () => {
        history.push(`${match.url}/create`);
    };

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`);
    };

    return (
        <Fragment>
            <Table
                title="general.menuItems.serviceItems"
                headerItems={TABLE_HEADER}
                isLoading={isLoading}
                totalItems={totalItems}
                data={data}
                getData={getServicePlanItemsAction}
                actions={{
                    editAction: handleEditModal,
                    addAction: handleCreateModal,
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create`}
                component={CreateServiceItem}
            />
            <AuthModalRoute
                path={`${match.url}/edit/:id`}
                component={EditServiceItem}
            />
        </Fragment>
    )
}


const mapStateToProps = (state) => ({
    isLoading: state.servicePlanItems.isLoading,
    data: getListSelector(state.servicePlanItems),
    totalItems: getTotalItemsSelector(state.servicePlanItems)
});

const mapDispatchToProps = {
    getServicePlanItemsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceItemManagement)