export const GET_EVENTS = "@TCK/GET_EVENTS";
export const GET_EVENTS_SUCCESS = "@TCK/GET_EVENTS_SUCCESS";
export const getEventsAction = params => ({
    type: GET_EVENTS,
    params
});

export const GET_MY_EVENTS = "@TCK/GET_MY_EVENTS";
export const GET_MY_EVENTS_SUCCESS = "@TCK/GET_MY_EVENTS_SUCCESS";
export const getMyEventsAction = params => ({
    type: GET_MY_EVENTS,
    params
});

export const GET_EVENT = "@TCK/GET_EVENT";
export const GET_EVENT_SUCCESS = "@TCK/GET_EVENT_SUCCESS";
export const getEventAction = (id, params) => ({
    type: GET_EVENT,
    id,
    params
});

export const CREATE_EVENT = "@TCK/CREATE_EVENT";
export const createEventAction = (data, params) => ({
    type: CREATE_EVENT,
    data,
    params
});

export const EDIT_EVENT = "@TCK/EDIT_EVENT";
export const editEventAction = (data, params) => ({
    type: EDIT_EVENT,
    data,
    params
});

export const GET_EVENT_ANSWERS = "@TCK/GET_EVENT_ANSWERS";
export const GET_EVENT_ANSWERS_SUCCESS = "@TCK/GET_EVENT_ANSWERS_SUCCESS";
export const getEventAnswersAction = id => ({
    type: GET_EVENT_ANSWERS,
    id
});

export const GET_EVENT_ANSWERS_REGISTRED = "@TCK/GET_EVENT_ANSWERS_REGISTRED";
export const getEventAnswersRegistredAction = id => ({
    type: GET_EVENT_ANSWERS_REGISTRED,
    id
});

export const INVITATION_RESPONSE = "@TCK/INVITATION_RESPONSE";
export const invitationResponseAction = (id, data) => ({
    type: INVITATION_RESPONSE,
    id,
    data
});

export const INVITATION_RESPONSE_REGISTRED = "@TCK/INVITATION_RESPONSE_REGISTRED";
export const invitationResponseRegistredAction = (id, data) => ({
    type: INVITATION_RESPONSE_REGISTRED,
    id,
    data
});

export const RESET_EVENT_LISTS = '@SRV/RESET_EVENT_LISTS';
export const resetEventListsAction = () => ({
    type: RESET_EVENT_LISTS
});