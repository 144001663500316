import { GET_CURRENT_USER, GET_CURRENT_USER_SUCCESS } from "../actions/currentUserActions"

const currentUserReducer = (state = null, action) => {
    switch (action.type) {
        case GET_CURRENT_USER:
            return null;
        case GET_CURRENT_USER_SUCCESS:
            return action.data;
        default:
            return state;
    }
}

export { currentUserReducer };