/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import PriceForm from "../PriceForm";

import { getPriceAction, editPriceAction } from "../../../../redux/actions/priceActions"
import { getSettingsItemAction } from '../../../../redux/actions/settingsActions';
import { getItemSelector } from "../../../../utils/selectors";
import { APP_SETTING_KEYS } from '../../../../constants/enums';

export const EditPrice = ({
    getPriceAction,
    editPriceAction,
    initialValues,
    getSettingsItemAction,
    defaultCurrency,
    tableParams,
    match,
    history
}) => {

    useEffect(() => {
        getPriceAction(match.params.id, { include: "servicePlans" });
        getSettingsItemAction(APP_SETTING_KEYS.DEFAULT_CURRENCY);
    }, [getPriceAction, match.params.id]);


    // TODO:: think about generic data formatter
    const onSubmit = data => {
        if (data.price) {
            data.price = parseFloat(data.price);
        }
        if (data.defaultCurrency) {
            delete data.defaultCurrency
        }
        if (data.userCurrency) {
            delete data.userCurrency
        }
        editPriceAction(data, tableParams);
    };

    const handleCancel = () => {
        history.goBack();
    };

    if (!initialValues) return null;
    return (
        <PriceForm
            title="general.editPrice"
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitLabel="general.editPrice"
            handleCancel={handleCancel}
            defaultCurrency={defaultCurrency}
        />
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.price),
    tableParams: state.tableParams,
    defaultCurrency: getItemSelector(state.settingsItem)
});

const mapDispatchToProps = {
    getPriceAction,
    editPriceAction,
    getSettingsItemAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPrice);
