import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import { BASE_URL } from '../../utils/axiosClient';
import Icon from '../../components/Icon';
import { formatDateTime } from '../../services/moment.service';

const EventCard = ({ 
    event, 
    history, 
    match
}) => {

    const translate = useTranslate();

    const handleInviteGuests = () => {
        history.push(`${match.url}/edit/${event.id}`)
    }

    return (
        <li className="_12">
            <div className="m-list -event">
                <div className="item">
                    <img src={`${BASE_URL}${event?.image?.path}`} alt=""/>
                    <div className="info">
                        <div>
                            <h2>{event.name}</h2>
                            <div className="date-time">
                                <Icon name="events-mini" color="#ffffff86"/>
                                <span className="time">{`${formatDateTime(event.bookingItem.fromDate)}h - ${formatDateTime(event.bookingItem.toDate)}h`}</span>
                            </div>
                        </div>
                        {event.bookingItemEventUsers?.length > 0 && 
                            <div className="guests">
                                {event.bookingItemEventUsers.map((item, index) => {
                                        if (index < 6) {
                                            return (
                                                <div key={index} className={`a-image-container-guest`} title={item.userEmail}>
                                                    {item.userEmail.charAt(0)}
                                                </div>
                                            )
                                        }
                                        return null;
                                    })}
                                {event.bookingItemEventUsers?.length > 6 && 
                                    <div className="all-guests-container">
                                        <div className={`all-guests`}>
                                            {`+${event.bookingItemEventUsers.length - 6}`}
                                        </div>
                                        <span className="see-all" onClick={handleInviteGuests}>{translate('general.seeAllGuests')}</span>
                                    </div>
                                }
                            </div>
                        }
                        {event.approved && <p className="invite" onClick={handleInviteGuests}>{translate('general.inviteMoreGuests')}</p>}
                    </div>
                </div>
                <span className="status">{translate(event.approved ? 'general.approved' : 'general.notApproved')}</span>
            </div>
        </li>
    )
}

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
    history: PropTypes.object,
    match: PropTypes.object
}

export default EventCard;
