import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import Button from '../../../components/Button';
import { BUTTON_TYPES, BUTTON_SIZE } from '../../../constants/enums';
import { BASE_URL } from '../../../utils/axiosClient';
import { checkFilterValue } from '../../../utils/checkValue';
import { calculatePriceWithCurrency } from '../../../utils/calculatePrice';
import { formatDateTime } from '../../../services/moment.service';

const AdditionalOption = ({ addition, addToShoppingCart, filter }) => {

    const translate = useTranslate();

    const disabled = !checkFilterValue(addition, filter);

    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(addition, filter);

    return (
        <div className="_s6">
            <div className={`m-list -addition-options ${disabled ? "-disable" : ""}`}>
                <div className="item">
                    <img alt="add" src={`${BASE_URL}${addition?.image?.path}`} />
                    <div className="description">
                        <p className="-big name">{translate(`${addition.type}.${addition.id}.name`)}</p>
                        { disabled ?
                            <p className="message">{translate(`${addition.type}.${addition.id}.description`)}</p> :
                            <Fragment>
                                <div className="price">
                                    <p className="-big price-value">{`+ ${discount ? priceWithDiscount : price}`}</p>
                                    <p className="-regular period">{`/ ${translate(`${filter.type}.${filter.id}.name`).toLowerCase()}`}</p>
                                </div>
                                {discount &&
                                    <Fragment>
                                        <div className="a-discount-container">
                                            <span className="price">{`+ ${price}`}</span>
                                            <span className="a-discount">{`- ${discount}%`}</span>
                                        </div>
                                        <span className="a-discount-valid">{`${translate("general.validUntil")} ${formatDateTime(discount?.toDate)}h`}</span>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                </div>
                <Button 
                    btnClass={BUTTON_TYPES.SECONDARY}
                    disabled={disabled}
                    btnSize={BUTTON_SIZE.SMALL} 
                    label="general.add" 
                    onClick={() => addToShoppingCart(addition)} />
            </div>
        </div>
    )
}

AdditionalOption.propTypes = {
    addition: PropTypes.object.isRequired,
    addToShoppingCart: PropTypes.func.isRequired
}

export default AdditionalOption;
