/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getEventAnswersAction, invitationResponseAction } from '../../redux/actions/eventsActions';
import { BUTTON_TYPES } from '../../constants/enums';
import Button from '../Button';
import { getIsLoading, getItemSelector, getListSelector } from '../../utils/selectors';
import Loader from '../Loader';
import ENTITY from "../../constants/entities";

const EventInvitation = ({ 
    history,
    match,
    getEventAnswersAction,
    invitationResponseAction,
    answers,
    answer,
    isLoading
 }) => {

    useEffect(() => {
        getEventAnswersAction(match.params.id);
    }, [])
    
    const handleInvitationResponse = (id) => {
        invitationResponseAction(match.params.id, {
            answer: {id, type: ENTITY.EVENT_ANSWERS}
        })
    }

    if (isLoading) return <Loader />;

    return (
        <div className="event-invitation">
            {answers.map(({id, name}, index) => (
                <Button
                    key={index}
                    label={`general.${name}`} 
                    btnClass={id === answer?.id ? BUTTON_TYPES.FILLED : BUTTON_TYPES.SECONDARY} 
                    onClick={() => handleInvitationResponse(id)} />
            ))}
        </div>
    )
}

const mapStateToProps = state => ({
    answers: getListSelector(state.eventAnswers),
    answer: getItemSelector(state.eventAnswers.answer),
    isLoading: getIsLoading(state.eventAnswers)
})

const mapDispatchToProps = {
    getEventAnswersAction,
    invitationResponseAction
}

export default connect(mapStateToProps, mapDispatchToProps)(EventInvitation);
