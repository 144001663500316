import React, { useState, Fragment } from 'react';
import { Field, reduxForm } from "redux-form";

import ModalForm from "../../../../components/ModalForm";
import InputField from "../../../../components/formFields/InputField";
import TextAreaField from "../../../../components/formFields/TextAreaField";
import CheckboxField from '../../../../components/formFields/CheckboxField';
import SelectFieldRelationship from "../../../../components/formFields/SelectFieldRelationship";
import SelectMultipleFieldRelationship from "../../../../components/formFields/SelectMultipleFieldRelationship";
import FileUploadDnD from '../../../../components/formFields/FileUploadDnD';

import { required } from "../../../../utils/validation";
import ENTITY from "../../../../constants/entities";
import { DISCOUNT_TYPES, FILE_TYPES } from "../../../../constants/enums";

const ServicePlanForm = ({
    title,
    change,
    initialValues,
    handleSubmit,
    onSubmit,
    submitLabel,
    handleCancel,
    isService
}) => {

    const [showMultipleServicePlans, setShowMultipleServicePlans] = useState(initialValues ? initialValues.multipleServicePlan : false);
    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
        >
            <div className="wrap service-plan-form">
                <div className="_m12 mv-10">
                    <Field
                        name="active"
                        label="form.fieldLabel.active"
                        component={CheckboxField}
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="planType.id"
                        label="form.fieldLabel.servicePlanType"
                        onSelect={change}
                        entityType={ENTITY.SERVICE_PLAN_TYPE}
                        component={SelectFieldRelationship}
                        required
                        validate={required}
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.name"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="number"
                        name="totalAvailable"
                        label="form.fieldLabel.available"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="priceLists"
                        label="form.fieldLabel.price"
                        onSelect={change}
                        entityType={ENTITY.PRICE}
                        component={SelectMultipleFieldRelationship}
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="discounts"
                        label="form.fieldLabel.discount"
                        onSelect={change}
                        entityType={ENTITY.DISCOUNT}
                        component={SelectMultipleFieldRelationship}
                        params={{ discountType: DISCOUNT_TYPES.SERVICE }}
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        name="planItems"
                        label="form.fieldLabel.servicePlanItems"
                        onSelect={change}
                        entityType={ENTITY.SERVICE_PLAN_ITEM}
                        component={SelectMultipleFieldRelationship}
                    />
                </div>
                {isService &&
                    <Fragment>
                        <div className="_m6 mv-10">
                            <Field
                                name="multipleServicePlan"
                                label="form.fieldLabel.multipleServicePlans"
                                component={CheckboxField}
                                action={() => setShowMultipleServicePlans(!showMultipleServicePlans)}
                            />
                        </div>
                        {
                            showMultipleServicePlans ?
                                <div className="_m6 mv-10">
                                    <Field
                                        name="relatedServicePlans"
                                        label="form.fieldLabel.servicePlans"
                                        onSelect={change}
                                        entityType={ENTITY.SERVICE_PLAN}
                                        component={SelectMultipleFieldRelationship}
                                    />
                                </div> :
                                <div className="_m6 mv-10"></div>
                        }
                    </Fragment>
                }
                <div className="_m6 mv-10">
                    <Field
                        name="image"
                        component={FileUploadDnD}
                        onImageChange={change}
                        type={FILE_TYPES.IMAGE}
                    />
                </div>
                <div className="_m6 mv-10">
                    <Field
                        type="text"
                        name="description"
                        label="form.fieldLabel.description"
                        component={TextAreaField}
                        validate={required}
                        required
                    />
                </div>
            </div>
        </ModalForm>
    );
};

export default reduxForm({ form: "ServicePlanForm", enableReinitialize: true })(ServicePlanForm);
