/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";
import { getRegularPackages, getTimeLimitPackages, getIsLoading, getItemSelector } from "../../utils/selectors";
import { getServicePlanListsAction, resetServicePlanListsAction } from "../../redux/actions/serviceActions";
import { SERVICE_TYPES } from "../../constants/enums";
import Loader from '../../components/Loader';
import PackageCards from "./PackageCards";
import PackageModal from "./PackageModal";
import NoContent from '../../components/NoContent';

export const PackageCatalog = ({
    regularPackages,
    timeLimitPackages,
    getServicePlanListsAction,
    resetServicePlanListsAction,
    currentUser,
    isLoading,
    history, 
    match 
}) => {
    const translate = useTranslate();
    const [selectedPackage, setPackage] = useState(null);

    const {language} = currentUser;

    useEffect(() => {
        getServicePlanListsAction({ include: 'userCurrency,discount', planType: SERVICE_TYPES.PACKAGE });
        return () => {
            resetServicePlanListsAction();
        }
    }, [language.code])

    const handleCloseModal = () => {
        setPackage(null)
    }

    const handleBookAPackage = (data) => {
        history.push(`${match.url}/book-a-package/${data.id}/${data?.priceLists[0]?.data?.id}`);
    }

    const getOptions = () => {
        const isRegularPackage = regularPackages.some(item => item?.id === selectedPackage?.id);
        if (isRegularPackage) {
            return regularPackages;
        }
        return timeLimitPackages;
    }

    if (isLoading) return <Loader />;

    if (regularPackages.length < 1 && timeLimitPackages.length < 1) return (
        <div>
            <div className="wrap">
                <div className="_12 content-container-header">
                    <div className="title">
                        <h1>{translate("general.welcomeToEva")}</h1>
                        <p className="-regular">{translate('general.packageTitle')}</p>
                    </div>
                </div>
            </div>
            <NoContent label='general.noPackages' />
        </div>
    );

    return (
        <Fragment>
            <div>
                <div className="wrap">
                    <div className="_12 content-container-header">
                        <div className="title">
                            <h1>{translate("general.welcomeToEva")}</h1>
                            <p className="-regular">{translate('general.packageTitle')}</p>
                        </div>
                    </div>
                </div>
                <PackageCards
                    packages={regularPackages}
                    handleSeeMore={setPackage}
                    handleBookAPackage={handleBookAPackage}
                    mode="large"
                />
            </div>
            <div>
                <PackageCards
                    packages={timeLimitPackages}
                    handleSeeMore={setPackage}
                    handleBookAPackage={handleBookAPackage}
                    mode="small"
                />
            </div>
            <PackageModal
                open={!!selectedPackage}
                packageOptions={getOptions()}
                selectedPackage={selectedPackage}
                setPackage={setPackage}
                submitLabel="general.bookAPackage"
                handleCancel={handleCloseModal}
                handleSubmit={handleBookAPackage}
            />
        </Fragment >
    )
}

const mapStateToProps = (state) => ({
    regularPackages: getRegularPackages(state.servicePlanLists),
    timeLimitPackages: getTimeLimitPackages(state.servicePlanLists),
    isLoading: getIsLoading(state.servicePlanLists),
    currentUser: getItemSelector(state.currentUser)
})

const mapDispatchToProps = {
    getServicePlanListsAction,
    resetServicePlanListsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageCatalog)
