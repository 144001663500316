import React from "react"
import { Field, reduxForm } from "redux-form";
import ModalForm from "../../../../components/ModalForm";

import InputField from "../../../../components/formFields/InputField";
import CheckboxField from "../../../../components/formFields/CheckboxField";
import TextAreaField from "../../../../components/formFields/TextAreaField";
import InputDate from "../../../../components/formFields/InputDate";

import { required } from "../../../../utils/validation";

export const InventoryForm = ({
    title,
    handleSubmit,
    onSubmit,
    submitLabel,
    handleCancel
}) => {
    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
        >
            <div className="wrap">
                <div className="_m12 mv-10">
                    <Field
                        name="usable"
                        label="form.fieldLabel.usable"
                        component={CheckboxField}
                    />
                </div>
                <div className="_s6 mv-10">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.name"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_s6 mv-10">
                    <Field
                        type="text"
                        name="uid"
                        label="form.fieldLabel.uid"
                        component={InputField}
                        validate={required}
                        required
                    />
                </div>
                <div className="_s6 mv-10">
                    <Field
                        name="warrantyDate"
                        label="form.fieldLabel.warrantyDate"
                        component={InputDate}
                        validate={required}
                        required
                    />
                </div>
                <div className="_s6 mv-10">
                    <Field
                        name="purchaseDate"
                        label="form.fieldLabel.purchaseDate"
                        component={InputDate}
                        validate={required}
                        required
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        type="text"
                        name="description"
                        label="form.fieldLabel.description"
                        component={TextAreaField}
                    />
                </div>
            </div>
        </ModalForm>
    )
}

export default reduxForm({
    form: "InventoryForm",
})(InventoryForm);