/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import axiosClient, { GET } from '../../../../../utils/axiosClient';
import { formatResponseData, formatUrl } from '../../../../../utils/jsonApiHelpers';

const useFetchItemFromApi = (entityType, id, params, onChange = null) => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const PATH = `/${entityType}/${id}`;
                const url = formatUrl(PATH, params);
                const response = await axiosClient(GET, url);
                const { data } = formatResponseData(response.data);
                setData(data);
                setIsLoading(false);

            } catch (error) {
                setData(null);
                setIsLoading(false);
            }
        }
        getData();
    }, [onChange])

    return { data, isLoading };
}

export default useFetchItemFromApi;