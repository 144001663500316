/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import history from '../../../../utils/history';

import { getUnreadNotificationsAction, editNotificationAction } from "../../../../redux/actions/notificationActions"
import { getItemSelector, getListSelector } from "../../../../utils/selectors"
import { getUserToken } from '../../../../services/localStorage.service';
import { formatDateTimeFromServer } from '../../../../services/moment.service';
import { hitsNotificationAction } from '../../../../utils/hitsNotificationAction';

import dropdownHOC from '../../../wrappers/dropdownHOC';
import Button from '../../../Button';
import Icon from '../../../Icon';
import { ROLES } from '../../../../constants/enums';

const NotificationDropdown = ({
    getUnreadNotificationsAction,
    editNotificationAction,
    notifications,
    open,
    currentUser
}) => {

    const translate = useTranslate();

    const userRoleName = currentUser?.roleName;
    const isUser = userRoleName === ROLES.USER.NAME;

    const [hasNotification, setHasNotification] = useState(false);

    useEffect(() => {
        const url = new URL(process.env.REACT_APP_MERCURE_HOST);
        url.searchParams.append('topic', getUserToken());

        const eventSource = new EventSource(url);
        eventSource.onmessage = () => {
            setHasNotification(true);
        }

        return () => {
            eventSource.close();
        }
    }, [])

    useEffect(() => {
        getUnreadNotificationsAction({ "recipient.id": currentUser.id, seen: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Display notification info if there are unseen notifications
    useEffect(() => {
        if (notifications.length > 0 && !open) {
            setHasNotification(true);
        } else {
            setHasNotification(false);
        }
    }, [notifications])

    const handleOpen = () => {
        if (!open) {
            getUnreadNotificationsAction({ "recipient.id": currentUser.id, seen: false });
        }
    }

    const handleNotification = (data) => {
        const { action } = data;
        const params = { seen: false };
        editNotificationAction({ ...data, seen: true }, params, true);
        setTimeout(() => {
            hitsNotificationAction(action, isUser);
        }, 1000);
    }

    const handleAllNotification = () => {
        if (history.location.pathname === `/notifications`) {
            history.go(0);
        } else {
            history.push('/notifications');
        }
    }

    return (
        <div className="m-notification-container">
            <div className={`m-notification-icon ${open ? '-active' : ''} ${hasNotification < 1 ? '' : '-show'}`} onClick={handleOpen}>
                <Icon name="bell" />
            </div>
            <ul className={`m-notification-list ${open ? '-active' : ''}`}>
                {notifications.map((item, index) => index < 5 &&
                    <li key={index} className="m-notification-list-item" onClick={() => handleNotification(item)}>
                        <p className="m-notification-list-item-title">{item?.creator?.firstName} {item?.creator?.lastName}</p>
                        <p className="m-notification-list-item-description">{translate(item.message)}</p>
                        <p className="m-notification-list-item-date">{formatDateTimeFromServer(item.created)}</p>
                    </li>)}
                <Button onClick={handleAllNotification} label="general.viewAllNotifications"></Button>
            </ul>
        </div >
    )
}

NotificationDropdown.propTypes = {
    getUnreadNotificationsAction: PropTypes.func.isRequired,
    editNotificationAction: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    notifications: getListSelector(state.unreadNotifications),
    currentUser: getItemSelector(state.currentUser)
});

const mapDispatchToProps = {
    getUnreadNotificationsAction,
    editNotificationAction
};

const NotificationDropdownHOC = dropdownHOC(NotificationDropdown);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDropdownHOC);