import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslate } from 'react-polyglot';

import { setAlert } from '../../redux/actions/alertActions';
import { ALERT_TYPES } from '../../constants/enums';
import Icon from '../Icon';

const Alert = ({
    type,
    title,
    message,
    setAlert,
    duration,
    shouldTranslate
}) => {
    const translate = useTranslate();

    useEffect(() => {
        if (type || title) {
            setTimeout(() => setAlert(null), duration * 5000)
        }
    })
    if (!type || !title) return null;

    return (
        <div className={`alert alert-${type}`} onClick={() => setAlert(null)}>
            {/* NOTE: Should translate added because of backend error messages 
            Maybe we should return error keys on backend too, and translate it here 
            */}
            <Icon name={`alert-${type}`} />
            <div className="alert-content">
                <span className="alert-content-title">{shouldTranslate ? translate(title) : title}</span>
                <span className="alert-content-message">
                    {(shouldTranslate && message) ? translate(message) : message}
                </span>
            </div>

        </div>
    )
}

Alert.propTypes = {
    type: PropTypes.oneOf([
        ALERT_TYPES.INFO,
        ALERT_TYPES.SUCCESS,
        ALERT_TYPES.WARNING,
        ALERT_TYPES.ERROR
    ]),
    title: PropTypes.string,
    message: PropTypes.string,
    setAlert: PropTypes.func.isRequired,
    duration: PropTypes.number.isRequired,
    shouldTranslate: PropTypes.bool
}

const mapStateToProps = state => ({
    type: state?.alert?.type,
    title: state?.alert?.title,
    message: state?.alert?.message,
    shouldTranslate: state?.alert?.shouldTranslate
})

const mapDispatchToProps = {
    setAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);