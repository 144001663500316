import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";

import { resetPasswordAction, checkPasswordTokenAction } from "../../../redux/actions/authActions";
import ConfirmForgotPasswordForm from "./ResetPasswordForm";

const ResetPassword = ({
    currentUser,
    checkPasswordTokenAction,
    resetPasswordAction,
    match,
}) => {
    const translate = useTranslate();

    useEffect(() => {
        checkPasswordTokenAction(match.params.token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onSubmit = data => {
        const { token } = match.params;
        const formData = { ...data, token };

        resetPasswordAction(currentUser.id, formData);
    }

    if (!currentUser) return null;

    return (
        <div className="no-auth-content">
            <div className="wrapper auth-container text-center">
                <div className="image -forgot-password" />
                <h1 className="mt-25">{translate("general.createNewPassword")}</h1>
                <p className="forgot-password-info-message mv-10">{translate("general.createNewPasswordInfoMsg")}</p>
                <ConfirmForgotPasswordForm
                    onSubmit={onSubmit}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
});

const mapDispatchToProps = {
    checkPasswordTokenAction,
    resetPasswordAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

