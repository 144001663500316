/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getLanguagesAction, setTranslation } from "../../redux/actions/translationActions"

import dropdownHOC from "../wrappers/dropdownHOC";
import { DEFAULT_LANGUAGE } from "../../constants/enums";
import { getItemSelector, getListSelector } from "../../utils/selectors";
import { getUserLang } from "../../services/localStorage.service";
import { editCurrentUser } from "../../redux/actions/currentUserActions";

const LanguagePicker = ({
    currentLanguage,
    languages,
    currentUser,
    getLanguagesAction,
    setTranslation,
    editCurrentUser,
    open,
}) => {

    useEffect(() => {
        getLanguagesAction();
    }, [])

    useEffect(() => {
        const prevLanguage = getUserLang();
        if (currentUser) {
            setTranslation(currentUser?.language?.code);
        } else if (prevLanguage) {
            setTranslation(prevLanguage);
        } else {
            setTranslation(DEFAULT_LANGUAGE)
        }
    }, [currentUser, setTranslation]);

    const handleLanguageChange = language => {
        if (currentUser) {
            editCurrentUser({
                id: currentUser.id,
                _id: currentUser.id,
                language: {
                    type: 'languages',
                    id: language.id
                }
            })
        };
        setTranslation(language.code);
    };

    if (languages.length === 0 || !currentLanguage) return null;

    return (
        <div className="language">
            <span className="value">{currentLanguage.toUpperCase()}</span>
            {
                open && <div className="language-content">
                    {languages.map(item => (
                        <span key={item.code} className="language-content-item" onClick={() => handleLanguageChange(item)}>
                            <span>{item.name}</span>
                        </span>
                    ))}
                </div>
            }

        </div>
    )
}

const mapStateToProps = state => ({
    currentUser: getItemSelector(state.currentUser),
    currentLanguage: state?.translation?.language,
    languages: getListSelector(state?.languages)
});

const mapDispatchToProps = {
    getLanguagesAction,
    setTranslation,
    editCurrentUser
};

const LanguagePickerHOC = dropdownHOC(LanguagePicker);

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePickerHOC);