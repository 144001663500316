export const GET_CURRENT_USER = '@USR/GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = '@USR/GET_CURRENT_USER_SUCCESS';
export const getCurrentUser = () => ({
    type: GET_CURRENT_USER,
});

export const EDIT_CURRENT_USER = '@USR/EDIT_CURRENT_USER';
export const editCurrentUser = data => ({
    type: EDIT_CURRENT_USER,
    data
});