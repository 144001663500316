import React, { Fragment } from 'react'

const TableContent = ({ columns, perPage = 10 }) => {

    const ROWS = Array.from(Array(perPage).keys());
    return (
        <Fragment>
            {
                ROWS.map(row => (
                    <tr key={row}>
                        {columns.map((column, index) => (
                            <td key={column.key}>
                                <svg width={index === 0 ? "80" : "100"} height="22">
                                    <rect width={index === 0 ? "60" : "100"} height="18" fill="#312f47" />
                                </svg>
                            </td>

                        ))}
                    </tr>))
            }
        </Fragment>
    )
}

export default TableContent
