/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react'
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";
import { getBookingAction } from '../../../../redux/actions/bookingsActions';
import { getIsLoading, getItemSelector } from "../../../../utils/selectors";
import { BASE_URL } from "../../../../utils/axiosClient";
import { formatDateTimeFromServer } from '../../../../services/moment.service';
import BookingItem from './BookingItem';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Loader from '../../../../components/Loader';
import AuthModalRoute from '../../../../components/routes/AuthModalRoute';
import EditStatus from './EditStatus';

const ViewBooking = ({
    match,
    history,
    getBookingAction,
    booking,
    isLoading
}) => {
    const translate = useTranslate();

    const { id } = match.params;
    
    useEffect(() => {
        getBookingAction(id, { include: "user,bookingItems" });
    }, [])

    const handleChangeStatus = bookingItemId => {
        history.push(`/bookings/view/${id}/${bookingItemId}`);
    };

    if (isLoading) return <Loader />;

    if (!booking) return null;

    return (
        <Fragment>
            <div className="booking">
                <div className="wrap header">
                    <div className="_12">
                        <Breadcrumbs label="general.backToBookings" />
                        <h1>{translate("general.viewBooking")}</h1>
                    </div>
                </div>
                <div className="wrap">
                    <div className="_12 booking-user">
                        <p className="title">{translate('general.bookingUser')}</p>
                        <div className="info">
                            <div className="img-name">
                                <img src={`${BASE_URL}${booking?.user?.avatarPath}`} alt="" className={booking?.user?.avatarPath ? "image -user" : "none"}/>
                                <div className="name">{`${booking?.user?.firstName} ${booking?.user?.lastName}`}</div>
                            </div>
                            <span className="email-title">{translate('general.email')}</span>
                            <span className="email">{booking?.user?.email}</span>
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="_12 booking-items">
                        <span className="a-line"></span>
                        <p className="title">{translate('general.bookingItems')}</p>
                        <div className="items">
                            {booking?.bookingItems.map((item, index) => <BookingItem item={item} key={index} handleChangeStatus={handleChangeStatus} />)}
                        </div>
                    </div>
                </div>
                <div className="wrap">
                    <div className="_12 booking-created">
                        <span className="created-container">
                            {translate('general.created')} <span  className="created">{formatDateTimeFromServer(booking?.createdDate)}</span>
                        </span>
                    </div>
                </div>
            </div>
            <AuthModalRoute
                path={`/bookings/view/:id/:bookingItemId`}
                component={EditStatus}
            />
        </Fragment>
    )
}


const mapStateToProps = state => ({
    booking: getItemSelector(state.booking),
    isLoading: getIsLoading(state.booking)
});

const mapDispatchToProps = {
    getBookingAction
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewBooking);