import React from 'react';
import PropTypes from 'prop-types';
import ServiceCard from './ServiceCard';

const ServiceCards = ({ services, handleBookAService }) => {
    return (
        <ul className="wrap m-list-container">
            {services.map(item => <ServiceCard key={item.id} serviceId={item.id} service={item} handleBookAService={handleBookAService} />)}
        </ul>
    )
}

ServiceCards.propTypes = {
    services: PropTypes.array.isRequired
}

export default ServiceCards
