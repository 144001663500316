/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useFetchOptionsFromApi from './hooks/fetchOptionsFromApi';
import ENTITY from '../../constants/entities';

const ITEMS_PER_PAGE = 100;

const SelectSearchField = ({
    input,
    label,
    entityType,
    params,
    searchable,
    relationship,
    required,
    customAction,
    meta: { touched, error },
}) => {
    const t = useTranslate()
    const hocRef = useRef(null)
    const searchRef = useRef(null)

    const [open, setOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [selectedValue, setSelectedValue] = useState({})
    const [page, setPage] = useState(1);

    const hasError = touched && error && required;
    
    const { options, isLoading } = useFetchOptionsFromApi(
        entityType, 
        { 
            ...params,
            page,
            itemsPerPage: ITEMS_PER_PAGE,
            search: searchValue
        }
    )
    useEffect(() => {
        if (input.value) {
            setSelectedValue({ ...input.value })
        }
    }, [])

    useEffect(() => {
        if (selectedValue.id) {
            relationship ?
                input.onChange({ id: selectedValue.id, type: entityType }) :
                input.onChange(selectedValue.id)
        }
    }, [selectedValue])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    // This is hook that does custom action on every value change
    useEffect(() => {
        if (customAction) {
            customAction(selectedValue)
        }
    }, [selectedValue])

    const handleClickOutside = (event) => {
        if (hocRef && hocRef.current && !hocRef.current.contains(event.target)) {
            setOpen(false)
            setSearchValue('')
        }
    }

    const focusSearchRef = () => {
        searchRef.current && searchRef.current.focus()
    }

    const handleFocus = () => {
        setOpen(true)
    }

    const handleSelect = (item) => {
        setSearchValue('')
        setSelectedValue(item)
        setOpen(false)
    }

    const handleSearch = (event) => {
        setPage(1)
        setSearchValue(event.target.value)
    }

    const handleOpen = () => {
        if (!open) {
            focusSearchRef()
        }
        setOpen(!open)
        setSearchValue('')
    }

    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        const bottom = Math.ceil(scrollHeight - scrollTop) === clientHeight;
        if (bottom && isLoading) {
            setPage(page + 1)
        }
    }

    const getOptionLabel = (item) => {
        switch (entityType) {
            case ENTITY.USER:
                return item.email || '';
            default:
                return item.name || '';
        }
    }

    const getOptions = () => {
        if (options.length > 0) {
            return options.map((item, index) => (
                <li key={index} onClick={() => handleSelect(item)}>
                    {getOptionLabel(item)}
                </li>
            ))
        }
        return <li>{t('general.noItems')}</li>
    }

    return (
        <div className='a-input-group m-selectSearch'>
            <div className='m-selectSearch__container' ref={hocRef}>
                <input
                    {...input}
                    value={getOptionLabel(selectedValue)}
                    onFocus={handleFocus}
                    className={`m-selectSearch__select a-select -dark ${getOptionLabel(selectedValue) || searchValue || open ? "-filled" : ""} ${open ? '-open' : ''} ${searchValue ? '-hide' : ''} ${hasError ? '-error' : ''}`}
                    readOnly
                    id={input.name}
                />
                <label htmlFor={input.name} className={`-dark ${required ? "-required" : ""}`}>{t(label)}</label>
                {searchable && <input
                    className='m-selectSearch__searchInput'
                    onClick={handleFocus}
                    onChange={handleSearch}
                    value={searchValue}
                    autoComplete='off'
                    ref={searchRef}
                />}
                <i className='select-arrow' onClick={handleOpen}></i>
                <div className={`m-selectSearch__options ${open ? '-open' : ''}`} onScroll={handleScroll} >
                    <ul>
                        {getOptions()}
                    </ul>
                </div>
            </div>
            {hasError && <span className="a-input-error">{t(error)}</span>}
        </div>
    )
}

SelectSearchField.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    searchable: PropTypes.bool,
    relationship: PropTypes.bool,
    entityType: PropTypes.string.isRequired,
    params: PropTypes.object,
    input: PropTypes.object,
    meta: PropTypes.object,
    required: PropTypes.bool,
    customAction: PropTypes.func
}

SelectSearchField.defaultProps = {
    searchable: false,
    relationship: false,
    input: {},
    meta: {},
    required: false
}

export default SelectSearchField
