import React from "react";
import { connect } from "react-redux";
import { Redirect, Router, Switch } from "react-router-dom";
import history from "./utils/history";

import AuthRoute from "./components/routes/AuthRoute";
import NoAuthRoute from "./components/routes/NoAuthRoute";
import InvitationRoute from "./components/routes/InvitationRoute";

import { I18n } from "react-polyglot";
import { DEFAULT_LANGUAGE, ROLES } from "./constants/enums";

import Alert from "./components/Alert";

import Login from "./screens/Auth/Login";
import RegisterEmail from "./screens/Auth/RegisterEmail";
import RegisterUser from "./screens/Auth/RegisterUser";
import ForgotPassword from "./screens/Auth/ForgotPassword";
import ResetPassword from "./screens/Auth/ResetPassword";
import UserProfile from "./screens/UserProfile";

import Dashboard from "./screens/Dashboard";
import BookingManagement from "./screens/admin/BookingManagement";
import CreateBooking from "./screens/admin/BookingManagement/CreateBooking";
import ViewBooking from "./screens/admin/BookingManagement/ViewBooking";
import UserManagement from "./screens/admin/UserManagement";
import UserInvoices from './screens/admin/UserManagement/UserInvoices';
import UserDocuments from './screens/admin/UserManagement/UserDocuments';
import TicketManagement from "./screens/admin/TicketManagement";
import CreateTicket from "./screens/admin/TicketManagement/CreateTicket";
import EditTicket from "./screens/admin/TicketManagement/EditTicket";
import DiscountManagement from "./screens/admin/DiscountManagement";
import InventoryManagement from "./screens/admin/InventoryManagement";
import PricesManagement from "./screens/admin/PricesManagement";
import DocumentTypesManagement from "./screens/admin/DocumentTypesManagement";
import SettingsManagement from "./screens/admin/SettingsManagement";

import PackageCatalog from "./screens/PackageCatalog";
import BookAPackage from "./screens/BookAPackage";
import ServiceCatalog from "./screens/ServiceCatalog";
import BookAService from "./screens/BookAService";
import AdditionCatalog from "./screens/AdditionCatalog";
import MyTickets from "./screens/admin/TicketManagement/MyTickets";
import UserEvents from "./screens/UserEvents";
import CreateAnEvent from "./screens/UserEvents/CreateAnEvent";
import EditEvent from './screens/UserEvents/EditEvent';
import Notifications from "./screens/Notifications";
import ServiceItemManagement from "./screens/admin/ServiceItemManagement";
import ServicePlanManagement from "./screens/admin/ServicePlanManagement";
import UserBookings from "./screens/UserBookings";
import ViewUserBooking from "./screens/UserBookings/ViewUserBooking";
import EventManagement from "./screens/admin/EventManagement";
import EditEventAdmin from "./screens/admin/EventManagement/EditEvent";
import EventInvitation from "./components/EventInvitation";
import Events from "./screens/Events";

const App = ({ translation }) => (
  <I18n locale={DEFAULT_LANGUAGE} messages={translation}>
    <Router history={history}>
      <Switch>
        <InvitationRoute 
          path="/event-invite/:id"
          component={EventInvitation}
        />
        <NoAuthRoute
          path="/login"
          component={Login}
        />
        <NoAuthRoute
          path="/register-email"
          component={RegisterEmail}
        />
        <NoAuthRoute
          path="/register-user/:token"
          component={RegisterUser}
        />
        <NoAuthRoute
          path="/forgot-password"
          component={ForgotPassword}
        />
        <NoAuthRoute
          path="/reset-password/:token"
          component={ResetPassword}
        />
        <AuthRoute
          path="/"
          component={Dashboard}
          allowedRoles={[ROLES.ADMIN, ROLES.USER]}
          exact={true}
        />
        <AuthRoute
          path="/user-profile"
          component={UserProfile}
          allowedRoles={[ROLES.ADMIN, ROLES.USER]}
        />
        <AuthRoute
          path="/notifications"
          component={Notifications}
          allowedRoles={[ROLES.ADMIN, ROLES.USER]}
        />
        <AuthRoute
          path="/bookings"
          component={BookingManagement}
          allowedRoles={[ROLES.ADMIN]}
          exact
        />
        <AuthRoute
          path="/bookings/create"
          component={CreateBooking}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/bookings/view/:id"
          component={ViewBooking}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/my-bookings"
          component={UserBookings}
          allowedRoles={[ROLES.USER]}
          exact
        />
        <AuthRoute
          path="/my-bookings/view/:id"
          component={ViewUserBooking}
          allowedRoles={[ROLES.USER]}
        />
        <AuthRoute
          path="/users"
          component={UserManagement}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/user/invoices/:id"
          component={UserInvoices}
          allowedRoles={[ROLES.ADMIN]}
          exact
        />
        <AuthRoute
          path="/user/invoices/:id/view/:id"
          component={ViewBooking}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/user/documents/:id"
          component={UserDocuments}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/service-item"
          component={ServiceItemManagement}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/service-plan"
          component={ServicePlanManagement}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/tickets"
          component={TicketManagement}
          allowedRoles={[ROLES.ADMIN]}
          exact
        />
        <AuthRoute
          path="/tickets/create"
          component={CreateTicket}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/tickets/edit/:id"
          component={EditTicket}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/my-tickets"
          component={MyTickets}
          allowedRoles={[ROLES.USER]}
          exact
        />
        <AuthRoute
          path="/my-tickets/create"
          component={CreateTicket}
          allowedRoles={[ROLES.USER]}
        />
        <AuthRoute
          path="/discounts"
          component={DiscountManagement}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/inventory"
          component={InventoryManagement}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/prices"
          component={PricesManagement}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/document-types"
          component={DocumentTypesManagement}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/settings"
          component={SettingsManagement}
          allowedRoles={[ROLES.ADMIN]}
        />
        <AuthRoute
          path="/package-catalog"
          component={PackageCatalog}
          allowedRoles={[ROLES.USER]}
          exact
        />
        <AuthRoute
          path="/package-catalog/book-a-package/:id/:priceLists"
          component={BookAPackage}
          allowedRoles={[ROLES.USER]}
        />
        <AuthRoute
          path="/service-catalog"
          component={ServiceCatalog}
          allowedRoles={[ROLES.USER]}
          exact
        />
        <AuthRoute
          path="/service-catalog/book-a-service/:id/:priceLists"
          component={BookAService}
          allowedRoles={[ROLES.USER]}
        />
        <AuthRoute
          path="/addition-catalog"
          component={AdditionCatalog}
          allowedRoles={[ROLES.USER]}
        />
        <AuthRoute
          path="/my-events"
          component={UserEvents}
          allowedRoles={[ROLES.USER]}
          exact
        />
        <AuthRoute
          path="/my-events/create"
          component={CreateAnEvent}
          allowedRoles={[ROLES.USER]}
        />
        <AuthRoute
          path="/my-events/edit/:id"
          component={EditEvent}
          allowedRoles={[ROLES.USER]}
        />
        <AuthRoute
          path="/events-catalog"
          component={Events}
          allowedRoles={[ROLES.USER]}
        />
        <AuthRoute
          path="/events"
          component={EventManagement}
          allowedRoles={[ROLES.ADMIN]}
          exact
        />
        <AuthRoute
          path="/events/edit/:id"
          component={EditEventAdmin}
          allowedRoles={[ROLES.ADMIN]}
        />
        <Redirect to="/" />
      </Switch>
      <Alert duration={1} />
    </Router>
  </I18n>
)


const mapStateToProps = state => ({
  translation: state.translation,
})

export default connect(mapStateToProps, null)(App);