import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";
import { Link } from "react-router-dom";

import RegisterEmailForm from "./RegisterEmailForm";
import { registerEmailAction } from "../../../redux/actions/authActions";


const RegisterEmail = ({ registerEmailAction }) => {
    const translate = useTranslate();

    const onSubmit = data => {
        registerEmailAction(data);
    }

    return (
        <Fragment>
            <Link to="/login" className="no-auth-breadcrumbs">
                <i className="m-avatar-chevron -left"></i>
                {translate("general.backToLogin")}
            </Link>
            <div className="no-auth-content">
                <div className="wrapper auth-container">
                    <h1>{translate("general.register")}</h1>
                    <RegisterEmailForm
                        onSubmit={onSubmit}
                    />
                </div>
            </div>
        </Fragment>
    )
}

const mapDispatchToProps = {
    registerEmailAction
}

export default connect(null, mapDispatchToProps)(RegisterEmail);