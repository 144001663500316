import React from 'react';
import PropTypes from 'prop-types';
import EventCard from './EventCard';

const EventCards = ({ 
    events, 
    history, 
    match 
}) => {
    return (
        <ul className="wrap m-list-container">
            {events.map(item => <EventCard key={item.id} event={item} history={history} match={match} />)}
        </ul>
    )
}

EventCards.propTypes = {
    events: PropTypes.array.isRequired,
    history: PropTypes.object,
    match: PropTypes.object
}

export default EventCards;
