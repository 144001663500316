import React, { Fragment } from "react"
import { connect } from "react-redux"

import { getInventoriesAction } from "../../../redux/actions/inventoryActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";
import AuthModalRoute from "../../../components/routes/AuthModalRoute";
import CreateInventory from "./CreateInventory";
import EditInventory from "./EditInventory";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "name",
        title: "table.header.name",
    },
    {
        key: "uid",
        title: "table.header.uid",
    },
    {
        key: "warrantyDate",
        title: "table.header.warrantyDate",
        valueType: "date",
    },
    {
        key: "purchaseDate",
        title: "table.header.purchaseDate",
        valueType: "date",
    }
];

export const InventoryManagement = ({
    getInventoriesAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleCreateModal = () => {
        history.push(`${match.url}/create`);
    };

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`)
    };

    return (
        <Fragment>
            <Table
                title="general.menuItems.inventory"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getInventoriesAction}
                actions={{
                    editAction: handleEditModal,
                    addAction: handleCreateModal,
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create`}
                component={CreateInventory}
            />
            <AuthModalRoute
                path={`${match.url}/edit/:id`}
                component={EditInventory}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    isLoading: state?.inventories?.isLoading,
    data: getListSelector(state.inventories),
    totalItems: getTotalItemsSelector(state.inventories)
})

const mapDispatchToProps = {
    getInventoriesAction
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryManagement)
