import React from "react";
import { connect } from "react-redux";

import UserForm from "../UserForm";

import { createUserAction } from "../../../../redux/actions/usersActions";

const CreateUser = ({
    createUserAction,
    tableParams,
    history
}) => {

    const onSubmit = data => {
        if (data.gender) {
            data.gender = Number(data.gender);
        }
        createUserAction(data, tableParams);
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <UserForm
            title="general.createUser"
            submitLabel="general.createUser"
            onSubmit={onSubmit}
            handleCancel={handleCancel}
        />
    )
};

const mapStateToProps = state => ({
    tableParams: state.tableParams
});

const mapDispatchToProps = {
    createUserAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);