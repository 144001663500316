export const GET_PRICES = '@PRC/GET_PRICES';
export const GET_PRICES_SUCCESS = '@PRC/GET_PRICES_SUCCESS';
export const getPricesAction = params => ({
    type: GET_PRICES,
    params
});

export const GET_PRICE = '@PRC/GET_PRICE';
export const GET_PRICE_SUCCESS = '@PRC/GET_PRICE_SUCCESS';
export const getPriceAction = (id, params) => ({
    type: GET_PRICE,
    id,
    params
})

export const CREATE_PRICE = '@PRC/CREATE_PRICE';
export const createPriceAction = (data, params) => ({
    type: CREATE_PRICE,
    data,
    params
});

export const EDIT_PRICE = '@PRC/EDIT_PRICE';
export const editPriceAction = (data, params) => ({
    type: EDIT_PRICE,
    data,
    params
});

export const GET_PRICE_TYPES = '@PRC/GET_PRICE_TYPES';
export const GET_PRICE_TYPES_SUCCESS = '@PRC/GET_PRICE_TYPES_SUCCESS';
export const getPriceTypesAction = params => ({
    type: GET_PRICE_TYPES,
    params
});