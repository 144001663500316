import React from 'react'
import PropTypes from 'prop-types';
import { SORT_TYPES } from '../../../constants/enums';
import Icon from '../../Icon';

const renderSortIcon = (sortValue) => {
    switch (sortValue) {
        case SORT_TYPES.ASC:
            return <Icon name="sort-asc" />
        case SORT_TYPES.DESC:
            return <Icon name="sort-desc" />
        default:
            return null
    }
}

const TableHeader = ({
    headerItems,
    displayDelete,
    displayEdit,
    displayView,
    displayFileAction,
    displayDocuments,
    displayCheckbox,
    setSortingParams,
    sortKey,
    sortValue,
    translate
}) => {
    return (
        <thead className="table-header">
            <tr>
                {/* Empty checkbox header item */}
                {displayCheckbox && <th />}
                {
                    headerItems.map(({
                        key,
                        title,
                        sortable = false
                    }) => (
                            <th
                                key={key}
                                style={{ cursor: sortable ? "pointer" : "default" }}
                                className="table-header-item"
                                onClick={sortable ? () => setSortingParams(key) : undefined}
                            >
                                {translate(title)}
                                {sortKey === key && renderSortIcon(sortValue)}
                            </th>
                        ))
                }
                {displayDelete && <th />}
                {displayEdit && <th />}
                {displayView && <th />}
                {displayFileAction && <th />}
                {displayDocuments && <th />}
            </tr>
        </thead>
    )
}

TableHeader.propTypes = {
    headerItems: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string,
        sortable: PropTypes.bool,
        valueType: PropTypes.string
    })).isRequired,
    displayDelete: PropTypes.bool,
    displayEdit: PropTypes.bool,
    displayView: PropTypes.bool,
    displayCheckbox: PropTypes.bool,
    displayFileAction: PropTypes.bool,
    displayDocuments: PropTypes.bool,
    setSortingParams: PropTypes.func ,
    sortKey: PropTypes.string,
    sortValue: PropTypes.number,
    translate: PropTypes.func.isRequired
}

export default TableHeader;
