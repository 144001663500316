//BOOKINGS
export const GET_BOOKINGS = '@BKG/GET_BOOKINGS';
export const GET_BOOKINGS_SUCCESS = '@BKG/GET_BOOKINS_SUCCESS';
export const getBookingsAction = params => ({
    type: GET_BOOKINGS,
    params
});

export const GET_BOOKING = '@BKG/GET_BOOKING';
export const GET_BOOKING_SUCCESS = '@BKG/GET_BOOKING_SUCCESS';
export const getBookingAction = (id, params) => ({
    type: GET_BOOKING,
    id,
    params
})

export const CREATE_BOOKING = '@BKG/CREATE_BOOKING';
export const CREATE_BOOKING_SUCCESS = '@BKG/CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAIL = '@BKG/CREATE_BOOKING_FAIL';
export const createBookingAction = (data, params) => ({
    type: CREATE_BOOKING,
    data,
    params
});

//BOOKING_STATUSES
export const GET_BOOKING_STATUSES = '@BKG_ST/GET_BOOKING_STATUSES';
export const GET_BOOKING_STATUSES_SUCCESS = '@BKG_ST/GET_BOOKING_STATUSES_SUCCESS';
export const getBookingStatusesAction = params => ({
    type: GET_BOOKING_STATUSES,
    params
});

//USER BOOKING ITEMS
export const GET_BOOKING_ITEMS = '@BKG/GET_BOOKING_ITEMS';
export const GET_BOOKING_ITEMS_SUCCESS = '@BKG/GET_BOOKING_ITEMS_SUCCESS';
export const getBookingItemsAction = params => ({
    type: GET_BOOKING_ITEMS,
    params
});

export const GET_BOOKING_ITEM = '@BKG/GET_BOOKING_ITEM';
export const GET_BOOKING_ITEM_SUCCESS = '@BKG/GET_BOOKING_ITEM_SUCCESS';
export const getBookingItemAction = (id, params) => ({
    type: GET_BOOKING_ITEM,
    id,
    params
})

export const CHANGE_BOOKING_ITEM_STATUS = '@SRV/CHANGE_BOOKING_ITEM_STATUS';
export const changeBookingItemStatusAction = (id, data, params) => ({
    type: CHANGE_BOOKING_ITEM_STATUS,
    id,
    data,
    params
});
