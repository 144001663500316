import { SET_INITIAL_TABLE_PARAMS, SET_PAGE, SET_ITEMS_PER_PAGE, SET_SORTING } from "../actions/tableParamsActions";

const initialState = {
    page: 1,
    itemsPerPage: 10,
    sortKey: "",
    sortValue: 0
};

const tableParamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INITIAL_TABLE_PARAMS:
            return {
                ...initialState
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        case SET_ITEMS_PER_PAGE:
            return {
                ...state,
                page: 1,
                itemsPerPage: action.itemsPerPage
            };
        case SET_SORTING:
            return {
                ...state,
                page: 1,
                sortKey: action.sortingParams.sortKey,
                sortValue: action.sortingParams.sortValue
            };
        default:
            return state;
    }
};

export { tableParamsReducer };