import React from "react";
import { connect } from "react-redux";

import DocumentForm from "../DocumentForm";

import { addDocumentToUserAction } from "../../../../../redux/actions/usersActions";
import ENTITY from "../../../../../constants/entities";

const CreateDocument = ({
    addDocumentToUserAction,
    tableParams,
    history,
    match
}) => {

    const onSubmit = data => {

        const { id } = match.params;
        addDocumentToUserAction({...data, 'user': {
            id,
            type: ENTITY.USER
        }},
        { include: "documentType,file,user", user: id, ...tableParams })
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <DocumentForm
            title="general.addDocument"
            submitLabel="general.addDocument"
            onSubmit={onSubmit}
            handleCancel={handleCancel}
        />
    )
};

const mapStateToProps = state => ({
    tableParams: state.tableParams
});

const mapDispatchToProps = {
    addDocumentToUserAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocument);