import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from "react-polyglot";

const NoContent = ({ label }) => {

    const translate = useTranslate();

    return (
        <div className="m-no-content">
            <div className="noImage"></div>
            <p className="title">{translate(label)}</p>
        </div>
    )
}

NoContent.propTypes = {
    label: PropTypes.string.isRequired
}

export default NoContent;
