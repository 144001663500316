import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import axiosClient, { BASE_URL, PATCH } from '../../utils/axiosClient';
import useFetchItemFromApi from '../../utils/fetchItemFromApi';
import { formatRequestData, formatResponseData } from '../../utils/jsonApiHelpers';
import { formatDateTime } from '../../services/moment.service';
import { BUTTON_TYPES } from '../../constants/enums';
import ENTITY from "../../constants/entities";

import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Loader from '../../components/Loader';

const INVITATION_PATH = "/booking_item_event_users";

const EventCard = ({ 
    event,
    answers
}) => {
    const translate = useTranslate();

    const [answer, setAnswer] = useState({});
    const [showAnswers, setShowAnswers] = useState(true);
    const [responseMessage, setResponseMessage] = useState();

    const { data, isLoading } = useFetchItemFromApi(
        ENTITY.EVENT_INVITE,
        event.id
    )
    useEffect(() => {
        setAnswer(data);
        if (data.answer) {
            setShowAnswers(false);
        }
    }, [data])

    const handleInvitationResponse = async (id) => {
        try {
            const preparedData = formatRequestData(ENTITY.EVENT_USERS, {
                answer: {id, type: ENTITY.EVENT_ANSWERS}
            });
            const response =  await axiosClient(PATCH, `${INVITATION_PATH}/${answer.id}`, preparedData);
            const { data } = formatResponseData(response.data);
            setAnswer(data);
            setResponseMessage("general.answerRecorded");
            setShowAnswers(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeAnswer = () => {
        setShowAnswers(true);
    }

    const renderAnswers = () => {
        if (isLoading) return <Loader type="mini" />;
        if (showAnswers) {
            return answers.map(({id, type}) => (
                <Button
                    key={id}
                    label={`${type}.${id}.name`} 
                    btnClass={id === answer?.answer?.id ? BUTTON_TYPES.FILLED : BUTTON_TYPES.SECONDARY}
                    onClick={() => handleInvitationResponse(id)} 
                />
            ))
        } else {
            return <p className="answer-response">
                        {responseMessage && <span className="response">{translate(responseMessage)}</span>}
                        <span className="change-answer" onClick={handleChangeAnswer}>{translate('general.changeAnswer')}</span>
                    </p>
        }
    }

    return (
        <li className="_12">
            <div className="m-list -event">
                <div className="item">
                    <img src={`${BASE_URL}${event?.image?.path}`} alt=""/>
                    <div className="info">
                        <div>
                            <h2>{event.name}</h2>
                            <h4>{event.description}</h4>
                            <div className="date-time">
                                <Icon name="events-mini" color="#ffffff86"/>
                                <span className="time">{`${formatDateTime(event.bookingItem.fromDate)}h - ${formatDateTime(event.bookingItem.toDate)}h`}</span>
                            </div>
                        </div>
                        <div className="answers">
                        {renderAnswers()}
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

EventCard.propTypes = {
    event: PropTypes.object.isRequired,
    answers: PropTypes.array
}

export default EventCard;
