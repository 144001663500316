import React from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon";

const SearchField = ({
    placeholder,
    onChange
}) => {
    return (
        <div className="table-search-field">
            <Icon name="search" />
            <input
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    )
}

SearchField.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default SearchField;