/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTranslate } from 'react-polyglot';
import { Field, reduxForm } from "redux-form";
import InputField from "../../../../components/formFields/InputField";
import TextAreaField from "../../../../components/formFields/TextAreaField";
import { required } from "../../../../utils/validation";
import ENTITY from "../../../../constants/entities";
import { BOOKING_ITEM_STATUSES, BUTTON_TYPES, FILE_TYPES } from "../../../../constants/enums";
import FileUploadDnD from '../../../../components/formFields/FileUploadDnD';
import SelectField from '../../../../components/formFields/SelectField';
import Button from '../../../../components/Button';
import useFetchItemFromApi from '../../../../utils/fetchItemFromApi';
import useFetchOptionsFromApi from '../../../../components/formFields/hooks/fetchOptionsFromApi';
import { formatDateTime } from '../../../../services/moment.service';
import { BASE_URL } from '../../../../utils/axiosClient';
import CheckboxField from '../../../../components/formFields/CheckboxField';
import Icon from '../../../../components/Icon';

const EventForm = ({
    handleSubmit,
    onSubmit,
    initialValues,
    change,
    submitLabel
}) => {
    const translate = useTranslate();

    const [bookingItem, setBookingItem] = useState(initialValues ? initialValues.bookingItem : null);

    const { data } = useFetchItemFromApi(
        ENTITY.SERVICE_PLAN,
        initialValues ? initialValues?.bookingItem?.relationships?.servicePlan?.data?.id : bookingItem?.servicePlanId,
        { include: "image" }
    )

    const { options } = useFetchOptionsFromApi(
        ENTITY.BOOKING_ITEM,
        {
            page: 1,
            itemsPerPage: 30,
            eventInvite: 1,
            include: "servicePlan",
            "status.id": BOOKING_ITEM_STATUSES.IN_PROGRESS,
            pagination: false
        }
    );

    const selectOptions = options.map(({ id, fromDate, toDate, servicePlan }) => (
        {
            id,
            fromDate,
            toDate,
            name: servicePlan.name,
            servicePlanId: servicePlan.id
        }
    ));

    const handleChangeBookingItem = (event) => {
        setBookingItem(selectOptions.find(item => item.id === Number(event.target.value)));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="wrap event-fields">
                <div className="_12">
                    <Field
                        name="approved"
                        label="general.approved"
                        component={CheckboxField}
                    />
                </div>
                <div className="_12">
                    <span className="a-line"></span>
                </div>
                <div className="_m6">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.eventName"
                        component={InputField}
                        readOnly={!!initialValues}
                        validate={required}
                        required
                    />
                    <Field
                        name="image"
                        component={FileUploadDnD}
                        onImageChange={change}
                        type={FILE_TYPES.IMAGE}
                    />
                </div>
                <div className="_m6">
                    <Field
                        name="description"
                        label="form.fieldLabel.describeYourEvent"
                        component={TextAreaField}
                        readOnly={!!initialValues}
                        validate={required}
                        required
                    />
                </div>
                <div className="_12">
                    <span className="a-line"></span>
                </div>
                <div className="_12">
                    <p className="title">{translate("general.chooseBookingItem")}</p>
                </div>
                <div className="_12 _mobile-m6 ">
                    <Field
                        name="bookingItem.id"
                        label="form.fieldLabel.bookingItem"
                        component={SelectField}
                        options={selectOptions}
                        readOnly={!!initialValues}
                        validate={required}
                        required
                        onChange={handleChangeBookingItem}
                    />
                </div>
                <div className="_12 _mobile-m6 ">
                    {bookingItem &&
                        <div className="booking-item">
                            <div>
                                <p className="title">{bookingItem?.name}</p>
                                <div className="date-time-container">
                                    <span className="row">
                                        {translate("general.fromDate")} <span className="date-time">{formatDateTime(bookingItem?.fromDate)}</span>
                                    </span>
                                    <span className="row">
                                        {translate("general.toDate")} <span className="date-time">{formatDateTime(bookingItem?.toDate)}</span>
                                    </span>
                                </div>
                            </div>
                            <img src={`${BASE_URL}${data?.image?.path}`} alt="" className="image" />
                        </div>
                    }
                </div>
                <div className="_12">
                    <span className="a-line"></span>
                </div>
                <div className="_12">
                    <p className="invitation">{translate('general.guests')}</p>
                    <p className="invitation-description">{translate('general.invitedGuests')}</p>
                    {initialValues?.bookingItemEventUsers?.length > 0 &&
                        <div className="guests">
                            {initialValues.bookingItemEventUsers.map((item, index) => (
                                <div className="guest" key={index}>
                                    <span className="email" >
                                        {item.userEmail}
                                        <Icon name="mail-opened" color={"#d8d8d8"} />
                                    </span>
                                    <span className="status">{translate(`general.${item.answerName || "pending"}`)}</span>
                                </div>
                            ))}
                        </div>
                    }
                    <span className="max-persons">{`${translate('general.maxPersonsNumber')} ${initialValues?.maxPersonsNumber}`}</span>
                </div>
            </div>
            { !!initialValues &&
                <div className="wrap event-btn">
                    <div className="_12">
                        <Button type="submit" btnClass={BUTTON_TYPES.SECONDARY} label={submitLabel} />
                    </div>
                </div>
            }
        </form>
    )
}

export default reduxForm({
    form: "EventManagementForm"
})(EventForm);
