import React from "react";
import { connect } from "react-redux";

import { createSettingAction } from "../../../../redux/actions/settingsActions";

import SettingsForm from "../SettingsForm";

const CreateService = ({
    createSettingAction,
    tableParams,
    history,
}) => {
    const onSubmit = data => {
        createSettingAction(data, tableParams);
        history.goBack();
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <SettingsForm
            title="general.createSettings"
            onSubmit={onSubmit}
            submitLabel="general.createSettings"
            handleCancel={handleCancel}
        />
    )
};

const mapStateToProps = state => ({
    tableParams: state.tableParams,
});

const mapDispatchToProps = {
    createSettingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateService);