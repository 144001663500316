import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getTicketsAction } from "../../../redux/actions/ticketsActions";
import { getListSelector, getTotalItemsSelector, getItemSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";

import { ROLES } from "../../../constants/enums";
import { Redirect } from "react-router-dom";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "subject",
        title: "table.header.subject",
    },
    {
        key: "author.email",
        title: "table.header.submitedBy"
    },
    {
        key: "createdDate",
        title: "table.header.dateCreated",
        valueType: "datetime"

    },
    {
        key: "status.name",
        title: "table.header.status"
    },
    {
        key: "category.name",
        title: "table.header.category"
    }
];

// TODO: Implement pagination throught query params
// Get data after every delete and edit user
const TicketManagement = ({
    currentUser,
    getTicketsAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const currentUserRole = currentUser?.roleName;
    const isUser = currentUserRole === ROLES.USER.NAME;

    if (isUser) {
        return <Redirect to="/my-tickets" />
    }

    const handleGoToCreate = () => {
        history.push(`${match.url}/create`);
    };

    const handleGoToEdit = id => {
        history.push(`${match.url}/edit/${id}`)
    };

    return (
        <Fragment>
            <Table
                title="general.tickets"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getTicketsAction}
                actions={{
                    editAction: handleGoToEdit,
                    addAction: handleGoToCreate,
                }}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    currentUser: getItemSelector(state.currentUser),
    isLoading: state.tickets.isLoading,
    data: getListSelector(state.tickets),
    totalItems: getTotalItemsSelector(state.tickets)
});

const mapDispatchToProps = {
    getTicketsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketManagement)