/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getSettingsAction } from "../../../redux/actions/settingsActions";

import Table from "../../../components/Table";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";


import AuthModalRoute from "../../../components/routes/AuthModalRoute";
import CreateSettings from "./CreateSettings";
import EditSettings from "./EditSettings";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "key.name",
        title: "table.header.key",
    },
    {
        key: "value",
        title: "table.header.value",
    },
];

const Settings = ({
    getSettingsAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleCreateModal = () => {
        history.push(`${match.url}/create`);
    };

    const handleEditModal = id => {
        history.push(`${match.url}/edit/${id}`)
    };

    return (
        <Fragment>
            <Table
                title="general.menuItems.settings"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getSettingsAction}
                actions={{
                    editAction: handleEditModal,
                    addAction: handleCreateModal
                }}
            />
            <AuthModalRoute
                path={`${match.url}/create`}
                component={CreateSettings}
            />
            <AuthModalRoute
                path={`${match.url}/edit/:id`}
                component={EditSettings}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    isLoading: state?.settings?.isLoading,
    data: getListSelector(state?.settings),
    totalItems: getTotalItemsSelector(state?.settings)
});

const mapDispatchToProps = {
    getSettingsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);