export const GET_SETTINGS = '@STG/GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = '@STG/GET_SETTINGS_SUCCESS';
export const getSettingsAction = params => ({
    type: GET_SETTINGS,
    params
});

export const GET_SETTING = '@STG/GET_SETTING';
export const GET_SETTING_SUCCESS = '@STG/GET_SETTING_SUCCESS';
export const getSettingsItemAction = id => ({
    type: GET_SETTING,
    id
});

export const CREATE_SETTING = '@STG/CREATE_SETTING';
export const createSettingAction = (data, params) => ({
    type: CREATE_SETTING,
    data,
    params
});

export const EDIT_SETTING = '@STG/EDIT_SETTING';
export const editSettingAction = (data, params) => ({
    type: EDIT_SETTING,
    data,
    params
});