export const GET_INVENTORIES = '@INV/GET_INVENTORIES';
export const GET_INVENTORIES_SUCCESS = '@INV/GET_INVENTORIES_SUCCESS';
export const getInventoriesAction = params => ({
    type: GET_INVENTORIES,
    params
});

export const GET_INVENTORY = '@INV/GET_INVENTORY';
export const GET_INVENTORY_SUCCESS = '@INV/GET_INVENTORY_SUCCESS';
export const getInventoryAction = id => ({
    type: GET_INVENTORY,
    id
})

export const CREATE_INVENTORY = '@INV/CREATE_INVENTORY';
export const createInventoryAction = (data, params) => ({
    type: CREATE_INVENTORY,
    data,
    params
});

export const EDIT_INVENTORY = '@INV/EDIT_INVENTORY';
export const editInventoryAction = (data, params) => ({
    type: EDIT_INVENTORY,
    data,
    params
});