import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";

import { BUTTON_TYPES, BUTTON_ICON_POSITIONS } from "../../constants/enums";
import Icon from "../Icon";

const Button = ({
    type,
    label,
    onClick,
    btnClass,
    btnSize,
    icon,
    iconPosition,
    disabled,
    isLoading
}) => {
    const translate = useTranslate();

    function getButtonInfo() {
        // const buttonClassName = disabled ? BUTTON_TYPES.DISABLED : btnClass;
        const buttonClassName = disabled ? `-${btnClass} -disabled`: `-${btnClass}`;
        const buttonLabel = translate(label);

        switch (iconPosition) {
            case BUTTON_ICON_POSITIONS.CENTER:
                return {
                    buttonClass: `a-btn ${buttonClassName} -rounded`,
                    buttonLabel: <Icon name={icon} />
                }
            case BUTTON_ICON_POSITIONS.LEFT:
                return {
                    buttonClass: `a-btn ${buttonClassName} -${btnSize} -icon`,
                    buttonLabel: <Fragment>{icon && <span className="icon-left"><Icon name={icon} /></span>}  <span>{buttonLabel}</span></Fragment>
                }
            case BUTTON_ICON_POSITIONS.RIGHT:
                return {
                    buttonClass: `a-btn ${buttonClassName} -${btnSize} -icon`,
                    buttonLabel: <Fragment><span>{buttonLabel}</span>{icon && <span className="icon-right"><Icon name={icon} /></span>}</Fragment>
                }
            default:
                return {
                    buttonClass: `a-btn ${buttonClassName} -${btnSize}`,
                    buttonLabel: buttonLabel
                }
        }
    }

    const { buttonClass, buttonLabel } = getButtonInfo();

    return (
        <button
            className={buttonClass}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            {buttonLabel}
        </button>

    )
};

Button.propTypes = {
    type: PropTypes.oneOf([
        "button",
        "submit"
    ]).isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    onClick: PropTypes.func,
    btnClass: PropTypes.oneOf([
        BUTTON_TYPES.PRIMARY,
        BUTTON_TYPES.SECONDARY,
        BUTTON_TYPES.FILLED,
        BUTTON_TYPES.DISABLED
        // BUTTON_TYPES.DANGER,
        // BUTTON_TYPES.SUCCESS,
    ]),
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf([
        BUTTON_ICON_POSITIONS.LEFT,
        BUTTON_ICON_POSITIONS.RIGHT,
        BUTTON_ICON_POSITIONS.CENTER
    ]),
    disabled: PropTypes.bool,
    btnSize: PropTypes.oneOf([
        "small",
        "regular",
    ])
}

Button.defaultProps = {
    type: "button",
    btnClass: BUTTON_TYPES.PRIMARY,
    btnSize: "regular",
    disabled: false
};

export default Button;