import React from 'react';
import PropTypes from 'prop-types';
import AvatarDropdown from '../common/AvatarDropdown';
import NotificationDropdown from '../common/NotificationDropdown';
import LanguagePicker from '../../LanguagePicker';
import Logo from '../../Logo';

const AdminDashboard = ({
    handleLogout,
    avatarImg,
    firstName,
    lastName,
    roleName,
}) => (
    <div className="navbar">
        <Logo />
        <div className="user-dashboard">
            <AvatarDropdown
                avatarImg={avatarImg}
                firstName={firstName}
                lastName={lastName}
                roleName={roleName}
                handleLogout={handleLogout}
            />
            <LanguagePicker />
            <NotificationDropdown />
        </div>
    </div>
)

AdminDashboard.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    avatarImg: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    roleName: PropTypes.string.isRequired
}

export default AdminDashboard;