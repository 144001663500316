/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

import Loader from '../Loader';
import useFetchOptionsFromApi from './hooks/fetchOptionsFromApi';

const ITEMS_PER_PAGE = 100;

const SelectMultipleFieldRelationship = ({
    input,
    label,
    onSelect,
    entityType,
    params,
    required,
    meta: { touched, error }
}) => {
    const [page, setPage] = useState(1);
    const { options, isLoading } = useFetchOptionsFromApi(
        entityType,
        {
            ...params,
            page,
            itemsPerPage: ITEMS_PER_PAGE,
        },
        true);

    const hasError = touched && error;
    const translate = useTranslate();

    const hocRef = useRef(null);
    const [open, setOpen] = useState(false);

    const [selectedValues, setSelectedValues] = useState([]);

    // Initialize page on entityType change
    useEffect(() => {
        return setPage(1);
    }, [entityType])

    useEffect(() => {
        if (input.value) {
            setSelectedValues([...input.value.map(item => ({ ...item, type: entityType }))]);
        };
    }, []);

    // Update selected values hook
    useEffect(() => {
        if (input.name) {
            const attributeName = input.name.split('.')[0];
            if (attributeName) {
                onSelect(attributeName, selectedValues);
            }
        }
    }, [selectedValues])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (hocRef && hocRef.current && !hocRef.current.contains(event.target)) {
            setOpen(false);
        }
    }

    const handleChange = id => {
        const value = Number(id);
        const exist = selectedValues.some(item => item.id === id);
        if (!exist) {
            setSelectedValues([...selectedValues, { id: value, type: entityType }]);
        }
    }

    const renderLabelById = (id) => {

        const findItemsIndex = (array) => {
            return array.findIndex(item => item._id === id);
        }

        const renderLabel = (index, array) => {
            if (array[index]) {
                if (array[index].firstName && array[index].lastName) {
                    return `${array[index].firstName} ${array[index].lastName}`;
                }
                if (array[index].email) {
                    return options[index].email;
                }
                return array[index].name;
            }
        }

        let index = findItemsIndex(options);
        if (options[index]) {
            return renderLabel(index, options)
        } else if (selectedValues.length > 0) {
            index = findItemsIndex(selectedValues);
            return renderLabel(index, selectedValues)
        }
    }

    const handleRemove = (index) => {
        let arr = [...selectedValues];
        arr.splice(index, 1);
        setSelectedValues(arr);
    }

    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        const bottom = Math.ceil(scrollHeight - scrollTop) === clientHeight;
        if (bottom && isLoading) {
            setPage((page) => page + 1);
        }
    }

    return (
        <div ref={hocRef} className='a-input-group'>
            <div className="m-multiselect">
                <div className={`multiselect ${open ? "focus" : hasError ? "error" : ""}`}>
                    <div className="multiselect-header">
                        <label className={`${required ? "-required" : ""}`} htmlFor={input.name}>{translate(label)}</label>
                        <span>{translate(label)}</span>
                        <i className={`multiselect-btn ${open ? "-up" : "-down"}`} onClick={() => setOpen(!open)}></i>
                    </div>
                    {selectedValues.length > 0 ?
                        <div className="multiselect-items">
                            {selectedValues.map(({ id }, index) => <div className="multiselect-item" key={id}>
                                <span className="remove" onClick={() => handleRemove(index)}></span>
                                <span className="label">{renderLabelById(id)}</span>
                            </div>)}
                        </div> : null}
                </div>
                {open &&
                    <div className="options-container">
                        <div className="multiselect-options" onScroll={handleScroll}>
                            <ul>
                                {options.map(({ _id }, index) => (
                                    <li className={selectedValues.some(item => item.id === _id) ? "-disable" : ""} key={index} onClick={() => handleChange(_id)}>
                                        <i className="check"></i>
                                        {renderLabelById(_id)}
                                    </li>
                                ))}
                                {isLoading &&
                                    <li>
                                        <Loader type="mini" />
                                    </li>}
                            </ul>
                        </div>
                    </div>}
            </div>
            {hasError && <span className="a-input-error">{translate(error)}</span>}
        </div>
    )
};

SelectMultipleFieldRelationship.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    entityType: PropTypes.string.isRequired,
    params: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    input: PropTypes.object,
    requred: PropTypes.bool

}

SelectMultipleFieldRelationship.defaultProps = {
    input: {},
    meta: {},
    requred: false
}

export default SelectMultipleFieldRelationship;