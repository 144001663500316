/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react';
import { useTranslate } from 'react-polyglot';
import { connect } from "react-redux";

import EventForm from "../EventForm";

import { getEventAction, editEventAction } from "../../../../redux/actions/eventsActions"
import { getItemSelector } from "../../../../utils/selectors";
import Breadcrumbs from '../../../../components/Breadcrumbs';


const EditEvent = ({
    getEventAction,
    editEventAction,
    initialValues,
    tableParams,
    match
}) => {

    const translate = useTranslate();

    useEffect(() => {

        getEventAction(match.params.id, { include: "image,bookingItem,bookingItemEventUsers" });
    }, []);

    const onSubmit = data => {
        editEventAction(data, tableParams);
    };

    if (!initialValues) return null;

    return (
        <Fragment>
            <div className="wrap ticket">
                <div className="_12">
                    <Breadcrumbs label="general.backToEvents" />
                    <h1>{translate("general.editEvent")}</h1>
                </div>
            </div>
            <EventForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitLabel="general.editEvent"
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.event),
    tableParams: state.tableParams
});

const mapDispatchToProps = {
    getEventAction,
    editEventAction
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
