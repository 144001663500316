import React, { Fragment } from 'react'
import { connect } from "react-redux";

import { getBookingsAction } from "../../../redux/actions/bookingsActions";
import { getListSelector, getTotalItemsSelector } from "../../../utils/selectors";

import Table from "../../../components/Table";

const TABLE_HEADER = [
    {
        key: "_id",
        title: "table.header.id",
    },
    {
        key: "user",
        title: "table.header.user",
    },
    {
        key: "bookingItems",
        title: "table.header.bookingItems",
        valueType: 'array'
    }
];


const BookingManagement = ({
    getBookingsAction,
    isLoading,
    data,
    totalItems,
    history,
    match
}) => {

    const handleGoToCreate = () => {
        history.push(`${match.url}/create`);
    };

    const handleGoToView = id => {
        history.push(`${match.url}/view/${id}`)
    };

    const handleGenerateInvoice = (id) => {
        const BASE_URL = process.env.REACT_APP_API_HOST;
        const { invoice } = data.find(item => item.id === id)
        if (invoice) {
            window.open(`${BASE_URL}${invoice}`, '_blank');
        }
    }

    return (
        <Fragment>
            <Table
                title="general.menuItems.bookings"
                headerItems={TABLE_HEADER}
                data={data}
                isLoading={isLoading}
                totalItems={totalItems}
                getData={getBookingsAction}
                filterByDate={true}
                filterByStatus={true}
                actions={{
                    addAction: handleGoToCreate,
                    viewAction: handleGoToView,
                    generateFileAction: handleGenerateInvoice
                }}
            />
        </Fragment>
    )
}


const mapStateToProps = state => ({
    isLoading: state.bookings.isLoading,
    data: getListSelector(state.bookings),
    totalItems: getTotalItemsSelector(state.bookings)
});

const mapDispatchToProps = {
    getBookingsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingManagement)