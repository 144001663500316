export const SET_INITIAL_TABLE_PARAMS = "@TABLE/SET_INITIAL_TABLE_PARAMS";
export const setInitialTableParamsAction = () => ({
    type: SET_INITIAL_TABLE_PARAMS
});

export const SET_PAGE = "@TABLE/SET_PAGE";
export const setPageAction = page => ({
    type: SET_PAGE,
    page
});

export const SET_ITEMS_PER_PAGE = "@TABLE/SET_ITEMS_PER_PAGE";
export const setItemsPerPageAction = itemsPerPage => ({
    type: SET_ITEMS_PER_PAGE,
    itemsPerPage
});

export const SET_SORTING = "@TABLE/SET_SORTING";
export const setSortingParamsAction = sortingParams => ({
    type: SET_SORTING,
    sortingParams
});