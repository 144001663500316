import React from 'react';
import { useTranslate } from "react-polyglot";
import AdditionalPerk from './AdditionalPerk';

const AdditionalPerks = ({ data, label }) => {

    const translate = useTranslate();

    if (data.length < 1) return null;
    
    return (
        <div className="m-active-package">
            <p className="title">{translate(label)}</p>
            { data.map((item, index) => <AdditionalPerk addition={item} key={index} />
            ) }
        </div>
    )
}

export default AdditionalPerks;
