import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";

import InputField from "../../../components/formFields/InputField";
import { required, isValidEmail } from "../../../utils/validation";

import Button from "../../../components/Button";
import { BUTTON_TYPES, INPUT_MODE } from "../../../constants/enums";

const LoginForm = ({ handleSubmit, onSubmit, translate }) => {
    return (
        <form className="mv-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap">
                <div className="_12 mv-10">
                    <Field
                        type="email"
                        name="email"
                        label="form.fieldLabel.email"
                        validate={[required, isValidEmail]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_12 mv-10">
                    <Field
                        type="password"
                        name="password"
                        label="form.fieldLabel.password"
                        validate={required}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_12">
                    <Link className="float-right" to="/forgot-password">{translate("general.forgotPassword")}</Link>
                </div>
                <div className="_12 text-center mv-10">
                    <Button
                        type="submit"
                        btnClass={BUTTON_TYPES.FILLED}
                        label="form.button.logInToAccount"
                    />
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: "LoginForm"
})(LoginForm)