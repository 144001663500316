export const GET_UNREAD_NOTIFICATIONS = '@NTF/GET_UNREAD_NOTIFICATIONS';
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = '@NTF/GET_UNREAD_NOTIFICATIONS_SUCCESS';
export const getUnreadNotificationsAction = params => ({
    type: GET_UNREAD_NOTIFICATIONS,
    params,
    isForDropdown: true
});


export const GET_NOTIFICATIONS = '@NTF/GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = '@NTF/GET_NOTIFICATIONS_SUCCESS';
export const getNotificationsAction = params => ({
    type: GET_NOTIFICATIONS,
    params
});

export const GET_NOTIFICATION = '@NTF/GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = '@NTF/GET_NOTIFICATION_SUCCESS';
export const getNotificationAction = id => ({
    type: GET_NOTIFICATION,
    id
});

export const EDIT_NOTIFICATION = '@NTF/EDIT_NOTIFICATION';
export const editNotificationAction = (data, params, isForDropdown) => ({
    type: EDIT_NOTIFICATION,
    data,
    params,
    isForDropdown
});

export const GET_NOTIFICATION_TYPES = "@NTF/GET_NOTIFICATION_TYPES";
export const GET_NOTIFICATION_TYPES_SUCCESS = "@NTF/GET_NOTIFICATION_TYPES_SUCCESS";
export const getNotificationTypes = (params) => ({
    type: GET_NOTIFICATION_TYPES,
    params
})