import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";

import Loader from "../Loader";

import { INPUT_MODE } from '../../constants/enums';
import useFetchOptionsFromApi from './hooks/fetchOptionsFromApi';

const RadioField = ({ input, disabled, value, label, mode }) => (
    <div className="a-radio">
        <input
            type="radio"
            {...input}
            id={value}
            checked={Number(input.value) === value}
            value={value}
            disabled={disabled}
        />
        <label htmlFor={value}>
            <span className={`radio -${mode}`}></span>
            <span className="label">{label}</span>
        </label>
    </div>
)

RadioField.propTypes = {
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
}

const ITEMS_PER_PAGE = 10;

const RadioGroupRelationship = ({
    input,
    entityType,
    label,
    mode
}) => {
    const translate = useTranslate();
    
    const { options, isLoading } = useFetchOptionsFromApi(
        entityType,
        {
            page: 1,
            itemsPerPage: ITEMS_PER_PAGE,
        });


    useEffect(() => {
        if (input.value || options.length > 0) {
            input.onChange(input.value ? input.value : options[0]._id)
        }
    }, [input, options])

    return (
        <div className={`m-radio-group -${mode}`}>
            <label>{translate(label)}</label>
            <div className="m-radio-group-options">
                {isLoading && <Loader type="mini" />}
                {options.map((item, index) => (
                    <RadioField
                        key={index}
                        input={input}
                        value={item._id}
                        label={item.name}
                        disabled={item.disabled}
                        mode={mode}
                    />
                )
                )}
            </div>
        </div>
    )
}

RadioGroupRelationship.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    entityType: PropTypes.string.isRequired,
    input: PropTypes.object,
    mode: PropTypes.oneOf([
        INPUT_MODE.DARK,
        INPUT_MODE.LIGHT
    ])
}

RadioGroupRelationship.defaultProps = {
    input: {},
    meta: {},
    mode: INPUT_MODE.DARK
}

export default RadioGroupRelationship;