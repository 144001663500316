import React from 'react';
import { useTranslate } from 'react-polyglot';
import { BASE_URL } from '../../../../utils/axiosClient';

const AdditionalPerk = ({ addition }) => {

    const translate = useTranslate();

    const { servicePlan, status } = addition;

    return (
        <div className="active-addition">
            <div className="image-container">
                <img src={`${BASE_URL}${addition?.servicePlan?.imagePath}`} alt="" className="image"/>
            </div>
            <div className="info">
                <span className="name">{translate(`${servicePlan.type}.${servicePlan.id}.name`)}</span>
                <span className="status">{translate(`${status.type}.${status.id}.name`)}</span>
            </div>
        </div>
    )
}

export default AdditionalPerk;
