import React from 'react';
import { Route } from "react-router-dom";
import LayoutInvitation from "../LayoutInvitation";

const InvitationRoute = ({ path, component }) => {
    
    return (
        <LayoutInvitation>
            <Route exact path={path} component={component} />
        </LayoutInvitation>
    )
}

export default InvitationRoute;
