/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from "react-polyglot";
import Button from '../../components/Button';
import { BUTTON_TYPES } from "../../constants/enums";
import EventCards from './EventCards';
import { getMyEventsAction, resetEventListsAction } from '../../redux/actions/eventsActions'
import Loader from '../../components/Loader';
import { getIsLoading, getListSelector } from '../../utils/selectors';

const UserEvents = ({
    history,
    match,
    getMyEventsAction,
    resetEventListsAction,
    events,
    isLoading
 }) => {
    const translate = useTranslate();

    useEffect(() => {
        getMyEventsAction({ include: "image,bookingItem,bookingItemEventUsers", pagination: false });
        return () => {
            resetEventListsAction();
        }
    }, [])

    const goToCreateAnEvent = () => {
        history.push(`${match.url}/create`)
    };

    if (isLoading) return <Loader />;

    return (
        <div>
            <div className="wrap">
                <div className="_12 content-container-header">
                    <div className="title">
                        <h1>{translate("general.menuItems.myEvents")}</h1>
                        <p className="-regular">{translate('general.eventsTitle')}</p>
                    </div>
                    <Button
                        onClick={goToCreateAnEvent}
                        label="general.createEvent"
                        btnClass={BUTTON_TYPES.SECONDARY}
                    />
                </div>
            </div>
            <EventCards events={events} history={history} match={match} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    events: getListSelector(state.myEvents),
    isLoading: getIsLoading(state.myEvents),
})

const mapDispatchToProps = {
    getMyEventsAction,
    resetEventListsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEvents);
