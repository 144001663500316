import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslate } from "react-polyglot";

import Checkbox from './Checkbox';
import ContentLoader from './ContentLoader';
import Icon from '../../Icon';

import { formatDateTime, formatDate, formatDateTimeFromServer } from '../../../services/moment.service';
import { renderRoleLabel } from '../../../utils/checkValue';

// returns value of objects property defined by key
const getValueWithKey = (key, item) => {
    let retVal = null;
    if (key.includes(".")) {
        const splitedKeyValues = key.split(".");
        splitedKeyValues.forEach(keyValue => {
            if (!retVal) {
                retVal = item[keyValue];
            } else {
                retVal = retVal[keyValue]
            }
        });
    } else {
        retVal = item[key];
    }
    return retVal;
}

const ActionButton = ({ item, action, icon, disableKey, disableValue, tooltip }) => {

    const shouldDisableButton = () => {
        if (disableKey) {
            const valueOfDisableProp = getValueWithKey(disableKey, item);
            if (!disableValue) {
                return !!valueOfDisableProp;
            } else {
                return valueOfDisableProp === disableValue;
            }
        }
        return;
    };

    const disableButton = shouldDisableButton();

    if (!action || disableButton) return null;
    return (
        <td className="text-center" title={tooltip} onClick={() => action(item._id)}><Icon name={icon} /></td>
    )
};

const CellItem = ({ headerKey, item, valueType, translate }) => {
    let value = getValueWithKey(headerKey, item);
    switch (valueType) {
        case 'datetime':
            value = formatDateTime(value);
            break;
        case 'notice-datetime':
            value = formatDateTimeFromServer(value);
            break;
        case 'date':
            value = formatDate(value);
            if (value === "Invalid date") value = "-";
            break;
        case 'array':
            value = value.map((item, index) => (
                <div key={index} className="td-div wrap _jcsb _aic m-0" >
                    <div>
                        <h4>{translate(item.transServicePlanTypePattern)}</h4>
                        <p className="-big">{item.servicePlan}</p>
                    </div>
                    <div className="table-array" >
                        <p className="-small">{translate(item.transStatusPattern)}</p>
                        <h4>{formatDateTime(item.fromDate)} - {formatDateTime(item.toDate)}</h4>
                    </div>
                </div>
            ))
            break;
        case 'roleName':
            value = renderRoleLabel(value);
            break;
        case 'boolean':
            // value = value.toString();
            value = <i className={`a-check ${value ? '-checked' : ''}`}></i>
            break;
        default:
            break;
    }
    return (
        <td key={`${headerKey} ${item._id} `}>
            {value}
        </td>
    )
};

const TableContent = ({
    headerItems,
    data,
    isLoading,
    actions: { deleteAction, editAction, viewAction, generateFileAction, showDocumentsAction },
    disableOptions: { disableKey, disableValue },
    handleSetSelectedItems,
    displayCheckbox
}) => {
    const translate = useTranslate();

    if (isLoading) return (
        <tbody>
            <ContentLoader columns={headerItems} />
        </tbody>
    )

    if (data && data.length > 0) return (
        <tbody>
            {data.map(item => (
                <tr key={item._id}>
                    <Checkbox
                        display={!!displayCheckbox}
                        value={item._id}
                        onClick={handleSetSelectedItems}
                    />
                    {headerItems.map(({ key, valueType }) => (
                        <CellItem
                            key={key}
                            headerKey={key}
                            item={item}
                            valueType={valueType}
                            translate={translate}
                        />
                    ))}
                    <ActionButton
                        item={item}
                        action={editAction}
                        icon="pencil"
                        disableKey={disableKey}
                        disableValue={disableValue}
                        tooltip={translate('general.edit')}
                    />
                    <ActionButton
                        item={item}
                        action={viewAction}
                        icon="eye"
                        tooltip={translate('general.view')}
                    />
                    <ActionButton
                        item={item}
                        action={generateFileAction}
                        icon="file-text"
                        tooltip={translate('general.invoices')}
                    />
                    <ActionButton
                        item={item}
                        action={showDocumentsAction}
                        icon="document-table"
                        tooltip={translate('general.document')}
                    />
                    <ActionButton
                        item={item}
                        action={deleteAction}
                        icon="trash"
                        disableKey={disableKey}
                        disableValue={disableValue}
                        tooltip={translate('general.delete')}
                    />
                </tr>
            ))
            }
        </tbody>)

    return (
        <tbody>
            <tr className="table-empty">
                <td colSpan={headerItems.length + !!editAction + !!deleteAction + !!viewAction + !!generateFileAction}>{translate("table.noItems")}</td>
            </tr>
        </tbody>
    )
}

TableContent.propTypes = {
    headerItems: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    actions: PropTypes.exact({
        addAction: PropTypes.func,
        editAction: PropTypes.func,
        viewAction: PropTypes.func,
        deleteAction: PropTypes.func,
        generateFileAction: PropTypes.func,
        showDocumentsAction: PropTypes.func
    }),
    disableOptions: PropTypes.exact({
        disableKey: PropTypes.string,
        disableValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
    }),
    displayCheckbox: PropTypes.bool,
    handleSetSelectedItems: PropTypes.func,
};

export default TableContent;
