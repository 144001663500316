/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from "react-polyglot";

import { getEventsAction, getEventAnswersRegistredAction, resetEventListsAction } from '../../redux/actions/eventsActions'
import { getIsLoading, getListSelector } from '../../utils/selectors';

import EventCards from './EventCards';
import Loader from '../../components/Loader';

const Events = ({
    getEventsAction,
    events,
    isLoading,
    getEventAnswersRegistredAction,
    resetEventListsAction,
    answers
 }) => {
    const translate = useTranslate();

    useEffect(() => {
        getEventsAction({ include: "image,bookingItem", invited: true, pagination: false });
        getEventAnswersRegistredAction();
        return () => {
            resetEventListsAction();
        }
    }, [])

    if (isLoading) return <Loader />;

    return (
        <div>
            <div className="wrap">
                <div className="_12 content-container-header">
                    <div className="title">
                        <h1>{translate("general.menuItems.events")}</h1>
                        <p className="-regular">{translate('general.eventsTitle')}</p>
                    </div>
                </div>
            </div>
            <EventCards 
                events={events} 
                answers={answers}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    events: getListSelector(state.events),
    isLoading: getIsLoading(state.events),
    answers: getListSelector(state.eventAnswers)
})

const mapDispatchToProps = {
    getEventsAction,
    getEventAnswersRegistredAction,
    resetEventListsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
