/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logoutAction } from '../../redux/actions/authActions';
import { getBookingItemsAction } from '../../redux/actions/bookingsActions';
import AdminDashboard from './AdminDashboard';
import SideBar from './SideBar';
import ContentWrapper from './common/ContentWrapper';
import useMenuItems from './SideBar/menuItems';
import { getItemSelector } from '../../utils/selectors';
import UserDashboard from './UserDashboard';
import ShoppingCart from '../ShoppingCart';
import { ROLES } from '../../constants/enums';
import CheckDateModal from '../CheckDateModal';

const Layout = ({
    children,
    user,
    logoutAction,
    location,
    getBookingItemsAction
}) => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const [dateForChecking, setDateForChecking] = useState('');

    const userRoleName = user?.roleName;
    const userImage = user?.avatar?.path;
    const isUser = userRoleName === ROLES.USER.NAME;

    useEffect(() => {
        if (user && isUser) {
            getBookingItemsAction({
                active: true,
                include: "servicePlan,status",
                pagination: false
            });
        }
    }, [user])

    const menuItems = useMenuItems(userRoleName);

    // TODO: Condition for inactive users
    // Display message and logout button
    if (!user || !menuItems) return null;

    if (!user.active) return "Inactive user";

    const handleCloseModal = () => {
        setDateForChecking("");
    }

    return (
        <Fragment>
            <div className="main-container">
                <SideBar
                    open={openSidebar}
                    setOpen={setOpenSidebar}
                    menuItems={menuItems}
                    location={location}
                    setDateForChecking={setDateForChecking}
                />
                <div className="wrapper wrap-container ">
                    {!isUser ?
                        <AdminDashboard
                            handleLogout={logoutAction}
                            avatarImg={userImage}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            roleName={userRoleName}
                        /> :
                        <UserDashboard
                            handleLogout={logoutAction}
                            avatarImg={userImage}
                            firstName={user.firstName}
                            lastName={user.lastName}
                            roleName={userRoleName}
                        />
                    }
                    <div className="wrap">
                        <ContentWrapper wrapperClassContainer={isUser ? 'content-container -user' : 'content-container -admin'} openSidebar={openSidebar} >
                            {children}
                            {isUser && <ShoppingCart />}
                        </ContentWrapper>
                    </div>

                    {!isUser && dateForChecking !== "" &&
                        <CheckDateModal 
                            cancelLabel='Cancel' 
                            submitLabel='Check package'
                            handleCancel={handleCloseModal} 
                            date={dateForChecking.toLocaleString('en-EU', { timeZone: 'Serbia/Belgrade' }) || ""}
                        />
                    }
                </div>
            </div>
        </Fragment>
    )
}

Layout.propTypes = {
    user: PropTypes.object,
    logoutAction: PropTypes.func
};

const mapStateToProps = state => ({
    user: getItemSelector(state.currentUser)
})

const mapDispatchToProps = {
    logoutAction,
    getBookingItemsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
