/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setPageAction, setItemsPerPageAction } from "../../redux/actions/tableParamsActions";

import Pagination from '../Pagination';
import TicketList from '../../screens/admin/TicketManagement/TicketList';
import Loader from '../Loader';
import NoContent from '../NoContent';

const List = ({
    type,
    data,
    getData,
    totalItems,
    isLoading,
    defaultPagination,
    actions: { editAction, commentAction },
    // redux props
    page,
    itemsPerPage,
    setPageAction,
    setItemsPerPageAction,
    label
}) => {

    useEffect(() => {
        setPageAction(1);
        setItemsPerPageAction(defaultPagination)
    }, [defaultPagination, setItemsPerPageAction, setPageAction]);

    useEffect(() => {
        getData({ page, itemsPerPage })
    }, [page, itemsPerPage]);

    const renderList = () => {
        switch (type) {
            case "tickets":
                return (
                    <TicketList
                        data={data}
                    />)
            default:
                return null;
        }
    }
    if (isLoading) return <Loader />;

    if (data.length < 1) return <NoContent label={label} />;

    return (
        <Fragment>
            <ul className="m-list-container -column">
                {renderList()}
            </ul>
            <Pagination
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPageAction}
                page={page}
                setPage={setPageAction}
                totalItems={totalItems}
            />
        </Fragment>
    )
};


List.propTypes = {
    type: PropTypes.oneOf(["tickets"]),
    data: PropTypes.array.isRequired,
    getData: PropTypes.func.isRequired,
    totalItems: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    defaultPagination: PropTypes.number,
    actions: PropTypes.exact({
        deleteAction: PropTypes.func,
        editAction: PropTypes.func,
        addAction: PropTypes.func,
        commentAction: PropTypes.func
    }),
    label: PropTypes.string
};

List.defaultProps = {
    defaultPagination: 10,
    actions: {},
    label: "No Items"
};

const mapStateToProps = state => ({
    page: state.tableParams.page,
    itemsPerPage: state.tableParams.itemsPerPage
});

const mapDispatchToProps = {
    setPageAction,
    setItemsPerPageAction
};

export default connect(mapStateToProps, mapDispatchToProps)(List);