/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import { getListSelector, getItemSelector } from "../../utils/selectors";
import { calculatePriceWithCurrency } from '../../utils/calculatePrice';
import { getServicePlanListsAction, getServicePlanListAction, resetServicePlanListAction, resetServicePlanListsAction } from "../../redux/actions/serviceActions";
import { addPackageToShoppingCartAction, addAdditionToShoppingCartAction } from "../../redux/actions/shoppingCartActions";
import { getPriceTypesAction } from "../../redux/actions/priceActions";
import { SERVICE_TYPES } from '../../constants/enums';
import DateAndTime from '../../components/DateAndTime';
import AdditionalOptions from './AdditionalOptions';
import AdditionModal from './AdditionModal';
import { DAILY_PRICELIST_ID } from '../../constants/constants';

const BookAPackage = ({
    additions,
    getServicePlanListsAction,
    getServicePlanListAction,
    getPriceTypesAction,
    addPackageToShoppingCartAction,
    addAdditionToShoppingCartAction,
    resetServicePlanListsAction,
    resetServicePlanListAction,
    activePackage,
    priceTypes,
    currentUser,
    isSuccess,
    match,
    isPackageInCart
}) => {

    const translate = useTranslate();
    const { id, priceLists } = match.params;
    const [date, setDate] = useState("");
    const [error, setError] = useState("");
    const [isInCard, setIsInCard] = useState(false);
    const [addition, setAddition] = useState(null);
    const [option, setOption] = useState(null);
    const [isSubmittedAddition, setSubmittedAddition] = useState(false);

    const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(activePackage);
    const { language } = currentUser;

    useEffect(() => {
        getServicePlanListAction(id, { include: 'userCurrency,discount,priceLists,planItems,planType,relatedServicePlans,image', priceLists: priceLists });
        getServicePlanListsAction({ include: 'userCurrency,image,discount', planType: SERVICE_TYPES.ADDITION });
        getPriceTypesAction();
        return () => {
            resetServicePlanListsAction();
            resetServicePlanListAction();
        }
    }, [language.code])

    useEffect(() => {
        if (isSuccess && isSubmittedAddition) {
            setAddition(null);
        }
    }, [isSuccess])

    useEffect(() => {
        if (isPackageInCart) {
            setIsInCard(true);
        }else{
            setIsInCard(false)
        }
    }, [isPackageInCart])

    const handleChangeDate = (value) => {
        setDate(value);
    }

    const handleAddToShoppingCart = () => {
        if (date) {
            addPackageToShoppingCartAction(
                { ...activePackage, fromDate: date, priceListId: activePackage.priceLists[0].data.id }
            );
        } else {
            setError('form.error.required');
        }
    }

    const handleCloseModal = () => {
        setAddition(null);
    }

    if (!activePackage) return null;

    if (activePackage?.priceLists[0] === null) {
        return <Redirect to="/package-catalog" />;
    }

    const disabled = Number(priceLists) === DAILY_PRICELIST_ID;

    return (
        <Fragment>
            <div className="book-a-package">
                <div className="wrap">
                    <div className="_12 content-container-header">
                        <div className="title">
                            <h1>{translate("general.bookAPackageTitle")}</h1>
                            <div className="package">
                                {translate(`${activePackage.type}.${activePackage.id}.name`)}
                                <span className="package-price">
                                    {discount ? priceWithDiscount : price}
                                </span>
                                {discount &&
                                    <div className="a-discount-container">
                                        <span className="price">{price}</span>
                                        <span className="a-discount">{`- ${discount}%`}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <DateAndTime
                    borderBottom={true}
                    date={date}
                    error={!date && error ? error : ""}
                    setError={setError}
                    handleChangeDate={handleChangeDate}
                    action={handleAddToShoppingCart}
                    btnLabel="general.bookAPackage" />
                {isInCard &&
                    <AdditionalOptions
                        disabled={disabled}
                        additions={additions}
                        options={priceTypes}
                        option={option}
                        setOption={setOption}
                        addToShoppingCart={setAddition} />
                }
            </div>
            { addition &&
                <AdditionModal
                    addition={addition}
                    filter={option || priceTypes[0]}
                    handleCancel={handleCloseModal}
                    handleSubmit={addAdditionToShoppingCartAction}
                    setSubmittedAddition={setSubmittedAddition}
                />
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    additions: getListSelector(state.servicePlanLists),
    activePackage: getItemSelector(state.servicePlanList),
    priceTypes: getListSelector(state.priceTypes),
    currentUser: getItemSelector(state.currentUser),
    isSuccess: state.shoppingCart.isSuccess,
    isPackageInCart: state.shoppingCart.isPackageInCart
})

const mapDispatchToProps = {
    getServicePlanListsAction,
    addAdditionToShoppingCartAction,
    addPackageToShoppingCartAction,
    getServicePlanListAction,
    resetServicePlanListsAction,
    resetServicePlanListAction,
    getPriceTypesAction
}

export default connect(mapStateToProps, mapDispatchToProps)(BookAPackage);
