import React, { useEffect } from "react"
import { connect } from "react-redux";

import DiscountForm from "../DiscountForm";

import { getDiscountAction, editDiscountAction } from "../../../../redux/actions/discountActions"
import { getItemSelector, getDiscountTypeSelector } from "../../../../utils/selectors";

const EditDiscount = ({
    getDiscountAction,
    editDiscountAction,
    initialValues,
    tableParams,
    match,
    history,
    selectedDiscountType
}) => {

    useEffect(() => {

        getDiscountAction(match.params.id, { include: "servicePlans,users,discountType" });
    }, [getDiscountAction, match.params.id]);

    const onSubmit = data => {
        data.percentDiscount = Number(data.percentDiscount);
        editDiscountAction(data, tableParams);
    };

    const handleCancel = () => {
        history.goBack();
    };

    if (!initialValues) return null;

    return (
        <DiscountForm
            title="general.editDiscount"
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitLabel="general.editDiscount"
            handleCancel={handleCancel}
            selectedDiscountType={selectedDiscountType}
        />
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.discount),
    tableParams: state.tableParams,
    selectedDiscountType: getDiscountTypeSelector(state)
});

const mapDispatchToProps = {
    getDiscountAction,
    editDiscountAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDiscount);
