import history from './history';

const NOTIFICATION_ACTIONS = {
    EVENTS: 'bookingitemevents',
    TICKETS: 'tickets',
    PACKAGE: 'bookingitems',
    INVITATION: 'booking_item_event_invite'
}

export const hitsNotificationAction = (action, isUser) => {

    const actionId = action.slice(action.lastIndexOf('/') + 1);
    if (action.includes(NOTIFICATION_ACTIONS.EVENTS)) {
        if (isUser) {
            if (history.location.pathname === `/my-events/edit/${actionId}`) {
                history.go(0);
            } else {
                history.push(`/my-events/edit/${actionId}`);
            }
        } else {
            if (history.location.pathname === `/events/edit/${actionId}`) {
                history.go(0);
            } else {
                history.push(`/events/edit/${actionId}`);
            }
        }
    } else if (action.includes(NOTIFICATION_ACTIONS.TICKETS)) {
        if (isUser) {
            if (history.location.pathname === `/my-tickets`) {
                history.go(0);
            } else {
                history.push(`/my-tickets`);
            }
        } else {
            if (history.location.pathname === `/tickets/edit/${actionId}`) {
                history.go(0);
            } else {
                history.push(`/tickets/edit/${actionId}`);
            }
        }
    } else if (action.includes(NOTIFICATION_ACTIONS.PACKAGE)) {
        if (isUser) {
            if (history.location.pathname === `/my-bookings/view/${actionId}`) {
                history.go(0);
            } else {
                history.push(`/my-bookings/view/${actionId}`);
            }
        }
    }
    else if (action.includes(NOTIFICATION_ACTIONS.INVITATION)) {
        if (isUser) {
            if (history.location.pathname === `/events-catalog`) {
                history.go(0);
            } else {
                history.push(`/events-catalog`);
            }
        }
    }
}