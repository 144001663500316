import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import { INPUT_MODE } from '../../constants/enums';

const CheckboxField = ({
    input,
    label,
    disabled,
    action,
    mode,
    meta: { error, touched }
}) => {
    const hasError = touched && error;
    const translate = useTranslate();
    return (
        <div className='a-checkbox'>
            <input
                {...input}
                id={input.name}
                type='checkbox'
                disabled={disabled}
                checked={input.value}
                onClick={action}
                className={`checkbox -${mode} ${hasError ? 'error' : ''}`}
            />
            <label htmlFor={input.name}>{translate(label)}</label>
        </div>
    )
}

CheckboxField.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    action: PropTypes.func,
    mode: PropTypes.oneOf([
        INPUT_MODE.DARK,
        INPUT_MODE.LIGHT
    ])
};

CheckboxField.defaultProps = {
    input: {},
    meta: {},
    mode: INPUT_MODE.DARK
}

export default CheckboxField;