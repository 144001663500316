/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";

import SideBarItem from "./SideBarItem";
import Calendar from "../../Calendar";

const SideBar = ({
    open,
    setOpen,
    menuItems,
    location,
    setDateForChecking
}) => {
    const translate = useTranslate();

    const hocRef = useRef(null);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (hocRef && hocRef.current && !hocRef.current.contains(event.target)) {
            setOpen(false);
        }
    }

    if (menuItems.length === 0) return null;

    return (
        <div ref={hocRef} className={`sidebar -left ${open ? "-open" : ""}`}>
            <span className={`sidebar-left-opener ${open ? '-open' : ''}`} onClick={() => setOpen(!open)} ></span>
            <div className="sidebar-main">
                <div className="m-sidenav">
                    <div className="m-sidenav-items">
                        {menuItems.map(({ key, to, action, label, icon }) => (
                            <SideBarItem
                                key={key}
                                id={key}
                                label={translate(label)}
                                icon={icon}
                                to={to}
                                active={location.startsWith(to)}
                                onClick={action}
                            />
                        )
                        )}
                    </div>
                </div>
                <Calendar size="small" label={translate("general.calendar")} onChange={() => { }} setDateForChecking={setDateForChecking}/>
            </div>
        </div>
    )
}

SideBar.propTypes = {
    open: PropTypes.bool.isRequired,
    menuItems: PropTypes.array
}

export default SideBar;