export const ADD_PACKAGE_TO_SHOPPING_CART = '@SRV/ADD_PACKAGE_TO_SHOPPING_CART';
export const ADD_PACKAGE_TO_SHOPPING_CART_SUCCESS = '@SRV/ADD_PACKAGE_TO_SHOPPING_CART_SUCCESS';
export const addPackageToShoppingCartAction = (data, params) => ({
    type: ADD_PACKAGE_TO_SHOPPING_CART,
    data,
    params
});

export const CHECK_PACKAGE = '@SRV/CHECK_PACKAGE';
export const CHECK_PACKAGE_SUCCESS = '@SRV/CHECK_PACKAGE_SUCCESS';
export const CHECK_PACKAGE_FAILED = '@SRV/CHECK_PACKAGE_FAILED';
export const checkPackageAction = (data, params) => ({
    type: CHECK_PACKAGE,
    data,
    params
});

export const REMOVE_PACKAGE_FROM_SHOPPING_CART = '@SRV/REMOVE_PACKAGE_FROM_SHOPPING_CART';
export const removePackageFromShoppingCartAction = (index) => ({
    type: REMOVE_PACKAGE_FROM_SHOPPING_CART,
    index
});

export const ADD_ADDITION_TO_SHOPPING_CART = '@SRV/ADD_ADDITION_TO_SHOPPING_CART';
export const ADD_ADDITION_TO_SHOPPING_CART_SUCCESS = '@SRV/ADD_ADDITION_TO_SHOPPING_CART_SUCCESS';
export const addAdditionToShoppingCartAction = (data, params) => ({
    type: ADD_ADDITION_TO_SHOPPING_CART,
    data,
    params
});

export const REMOVE_ADDITION_FROM_SHOPPING_CART = '@SRV/REMOVE_ADDITION_FROM_SHOPPING_CART';
export const removeAdditionFromShoppingCartAction = (index) => ({
    type: REMOVE_ADDITION_FROM_SHOPPING_CART,
    index
});

export const ADD_SERVICE_TO_SHOPPING_CART = '@SRV/ADD_SERVICE_TO_SHOPPING_CART';
export const ADD_SERVICE_TO_SHOPPING_CART_SUCCESS = '@SRV/ADD_SERVICE_TO_SHOPPING_CART_SUCCESS';
export const addServiceToShoppingCartAction = (data, params) => ({
    type: ADD_SERVICE_TO_SHOPPING_CART,
    data,
    params
});

export const REMOVE_SERVICE_FROM_SHOPPING_CART = '@SRV/REMOVE_SERVICE_FROM_SHOPPING_CART';
export const removeServiceFromShoppingCartAction = (index) => ({
    type: REMOVE_SERVICE_FROM_SHOPPING_CART,
    index
});

export const CREATE_BOOKING = '@SRV/CREATE_BOOKING';
export const CREATE_BOOKING_SUCCESS = '@SRV/CREATE_BOOKING_SUCCESS';
export const createBookingAction = (data) => ({
    type: CREATE_BOOKING,
    data
});

export const RESET_SHOPPING_CART = '@SRV/RESET_SHOPPING_CART';
export const resetShoppingCartAction = () => ({
    type: RESET_SHOPPING_CART
});
