import {
    GET_SERVICE_PLAN_ITEMS,
    GET_SERVICE_PLAN_ITEMS_SUCCESS,
    GET_SERVICE_PLAN_ITEM,
    GET_SERVICE_PLAN_ITEM_SUCCESS,
    GET_SERVICE_PLANS,
    GET_SERVICE_PLANS_SUCCESS,
    GET_SERVICE_PLAN,
    GET_SERVICE_PLAN_SUCCESS,
    GET_SERVICE_PLAN_LISTS,
    GET_SERVICE_PLAN_LISTS_SUCCESS,
    GET_SERVICE_PLAN_LIST,
    GET_SERVICE_PLAN_LIST_SUCCESS,
    RESET_SERVICE_PLAN_LISTS,
    RESET_SERVICE_PLAN_LIST
} from "../actions/serviceActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const servicePlanItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_PLAN_ITEMS:
            return { meta: null, isLoading: true };
        case GET_SERVICE_PLAN_ITEMS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const servicePlanItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_PLAN_ITEM:
            return { data: null, meta: null, isLoading: true };
        case GET_SERVICE_PLAN_ITEM_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const servicePlansReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_PLANS:
            return { data: [], isLoading: true };
        case GET_SERVICE_PLANS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const servicePlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_PLAN:
            return { data: null, isLoading: true };
        case GET_SERVICE_PLAN_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const servicePlanListsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_PLAN_LISTS:
            return { data: [], isLoading: true };
        case GET_SERVICE_PLAN_LISTS_SUCCESS:
            return { ...action.data, isLoading: false };
        case RESET_SERVICE_PLAN_LISTS:
            return { data: [], isLoading: false };
        default:
            return state;
    }
};

const servicePlanListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_PLAN_LIST:
            return { data: null, isLoading: true };
        case GET_SERVICE_PLAN_LIST_SUCCESS:
            return { ...action.data, isLoading: false };
        case RESET_SERVICE_PLAN_LIST:
            return { data: null, isLoading: false };
        default:
            return state;
    }
};

export {
    servicePlanItemsReducer,
    servicePlanItemReducer,
    servicePlansReducer,
    servicePlanReducer,
    servicePlanListsReducer,
    servicePlanListReducer
};