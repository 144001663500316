/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslate } from "react-polyglot";

import { setInitialTableParamsAction, setPageAction, setItemsPerPageAction, setSortingParamsAction } from "../../redux/actions/tableParamsActions";

import TableHeader from "./TableHeader";
import TableContent from "./TableContent";
import TableToolbar from "./TableToolbar";
import ActionTab from "./ActionTab";
import Pagination from "../Pagination";
import { SORT_TYPES } from "../../constants/enums";

const Table = ({
    title,
    headerItems,
    data,
    totalItems,
    getData,
    displayFooter,
    paginationOption,
    defaultPagination,
    searchable,
    actions,
    disableOptions,
    displayCheckbox,
    isLoading,
    // redux props
    page,
    itemsPerPage,
    sortValue,
    sortKey,
    setInitialTableParamsAction,
    setPageAction,
    setItemsPerPageAction,
    setSortingParamsAction,
    filterByDate,
    filterByStatus
}) => {
    const translate = useTranslate();

    const [selectedItems, setSelectedItems] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [filters, setFilters] = useState({})

    const { search, date, status } = filters

    useEffect(() => {
        setInitialTableParamsAction();
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            getData({
                page,
                itemsPerPage,
                sortOrder: {
                    sortKey,
                    sortValue,
                },
                ...filters
            });
        }
    }, [page, itemsPerPage, initialized, sortValue, sortKey, search, date, status]);

    const setSortingParams = (key) => {
        let typeOfSorting = null;
        const { ASC, DESC } = SORT_TYPES;
        if (!sortKey || (sortKey === key)) {
            switch (sortValue) {
                case ASC:
                    typeOfSorting = DESC;
                    break;
                case DESC:
                    typeOfSorting = null;
                    break;
                default:
                    typeOfSorting = ASC;
                    break;
            }
        } else {
            typeOfSorting = ASC;
        }
        setSortingParamsAction({ sortKey: key, sortValue: typeOfSorting });
    }

    const handleSetSelectedItems = event => {
        const itemId = Number(event.target.value);
        let items = [...selectedItems];
        if (items.includes(itemId)) {
            const index = items.findIndex(item => item === itemId);
            items.splice(index, 1);
        } else {
            items.push(itemId);
        }
        setSelectedItems(items);
    };

    return (
        <Fragment>
            <TableToolbar
                title={title}
                searchable={searchable}
                filterByDate={filterByDate}
                filterByStatus={filterByStatus}
                addAction={actions.addAction}
                getData={getData}
                page={page}
                itemsPerPage={itemsPerPage}
                filters={filters}
                setFilters={setFilters}
                setPage={setPageAction}
            />
            <ActionTab
                display={selectedItems.length > 0}
            />

            <div className="table">
                <table>
                    <TableHeader
                        headerItems={headerItems}
                        setSortingParams={setSortingParams}
                        displayDelete={!!actions.deleteAction}
                        displayEdit={!!actions.editAction}
                        displayView={!!actions.viewAction}
                        displayFileAction={!!actions.generateFileAction}
                        displayDocuments={!!actions.showDocumentsAction}
                        displayCheckbox={displayCheckbox}
                        translate={translate}
                        sortKey={sortKey}
                        sortValue={sortValue}
                    />
                    <TableContent
                        headerItems={headerItems}
                        data={data}
                        isLoading={isLoading}
                        actions={actions}
                        disableOptions={disableOptions}
                        handleSetSelectedItems={handleSetSelectedItems}
                        displayCheckbox={displayCheckbox}
                    />
                </table>
            </div>
            < Pagination
                display={displayFooter}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPageAction}
                paginationOption={paginationOption}
                page={page}
                setPage={setPageAction}
                totalItems={totalItems}
            />
        </Fragment >
    )
}

Table.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    headerItems: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    getData: PropTypes.func.isRequired,
    totalItems: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    paginationOption: PropTypes.array,
    defaultPagination: PropTypes.number,
    searchable: PropTypes.bool,
    actions: PropTypes.exact({
        addAction: PropTypes.func,
        editAction: PropTypes.func,
        viewAction: PropTypes.func,
        deleteAction: PropTypes.func,
        generateFileAction: PropTypes.func,
        showDocumentsAction: PropTypes.func
    }),
    disableOptions: PropTypes.exact({
        disableKey: PropTypes.string,
        disableValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
    }),
    displayCheckbox: PropTypes.bool,
    page: PropTypes.number.isRequired,
    setPageAction: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    setItemsPerPageAction: PropTypes.func.isRequired,
    filterByDate: PropTypes.bool,
    filterByStatus: PropTypes.bool
};

Table.defaultProps = {
    displayFooter: true,
    searchable: true,
    actions: {},
    disableOptions: {},
    displayCheckbox: false,
    defaultPagination: 10,
    filterByDate: false,
    filterByStatus: false
};

const mapStateToProps = state => ({
    page: state?.tableParams?.page,
    itemsPerPage: state?.tableParams?.itemsPerPage,
    sortKey: state?.tableParams?.sortKey,
    sortValue: state?.tableParams?.sortValue
});

const mapDispatchToProps = {
    setInitialTableParamsAction,
    setPageAction,
    setItemsPerPageAction,
    setSortingParamsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
