import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from "react-polyglot";
import Button from '../../components/Button';
import { BUTTON_TYPES } from '../../constants/enums';
import { calculatePriceWithCurrency } from '../../utils/calculatePrice';
import Icon from '../../components/Icon';
import { formatDateTime } from '../../services/moment.service';

const PackageCard = ({ 
    packageItem, 
    handleSeeMore, 
    handleBookAPackage, 
    mode
 }) => {
    const translate = useTranslate();

    const renderCard = () => {

        const { id, type } = packageItem;
        const { discount, price, priceWithDiscount } = calculatePriceWithCurrency(packageItem);

        switch (mode) {
            case 'large':
                return (
                    <li className="_l4 _s6 m-list -package-container">
                        <div className="m-list -package">
                            <div>
                                <div className="title">
                                    <h2>{translate(`${type}.${id}.name`)}</h2>
                                    <h2 className="bold price">{discount ? priceWithDiscount : price}</h2>
                                </div>
                                {discount &&
                                    <Fragment>
                                        <div className="a-discount-container">
                                            <h2 className="price">{price}</h2>
                                            <span className="a-discount">{`- ${discount}%`}</span>
                                        </div>
                                        <span className="a-discount-valid">{`${translate('general.validUntil')} ${formatDateTime(packageItem?.discount?.toDate)}h`}</span>
                                    </Fragment>
                                }
                                { packageItem?.planItems && packageItem.planItems.length > 0 ?
                                    <ul className="sublist">
                                        {packageItem?.planItems.map(({ data }, index) => {
                                            if (index < 5) {
                                                return (
                                                    <li key={data.id}>
                                                        <Icon name="check" />
                                                        <p className="-regular sublist-title">{translate(`${data.type}.${data.id}.name`)}</p>
                                                    </li>
                                                )
                                            }
                                            return null;
                                        })}
                                    </ul> : null 
                                }
                            </div>
                            <div className={`actions -space`}>
                                <Button label="general.book" btnClass={BUTTON_TYPES.PRIMARY} onClick={() => handleBookAPackage(packageItem)} />
                                <span title={translate('general.allPerks')} className="seemore" onClick={() => handleSeeMore(packageItem)} >
                                    <Icon name="info"/>
                                </span>
                            </div>
                        </div>
                    </li>
                )
            case 'small':
                return (
                    <li className="_l4 _s6 m-list -package-container">
                        <div className="m-list -package -small">
                            <div>
                                <div className="title">
                                    <h2>{`${packageItem.timeLimit} h `}<span className="period">{`/${translate('general.month')}`}</span></h2>
                                    <h2 className="bold price">{discount ? priceWithDiscount : price}</h2>
                                    {/* <h2>{packageItem.name}</h2> */}
                                </div>
                                {discount &&
                                    <Fragment>
                                        <div className="a-discount-container">
                                            <h2 className="price">{price}</h2>
                                            <span className="a-discount">{`- ${discount}%`}</span>
                                        </div>
                                        <span className="a-discount-valid">{`${translate('general.validUntil')} ${formatDateTime(packageItem?.discount?.toDate)}h`}</span>
                                    </Fragment>
                                }
                            </div>
                            <div className={`actions -space`}>
                                <Button label="general.book" btnClass={BUTTON_TYPES.PRIMARY} onClick={() => handleBookAPackage(packageItem)} />
                                <span title={translate('general.allPerks')} className="seemore" onClick={() => handleSeeMore(packageItem)} >
                                    <Icon name="info"/>
                                </span>
                            </div>
                        </div>
                    </li>
                )
            default:
                return null;
        }
    }
    
    return (
        <Fragment>
            { renderCard() }
        </Fragment>
    )
}

PackageCard.propTypes = {
    packageItem: PropTypes.object.isRequired,
    handleSeeMore: PropTypes.func,
    handleBookAPackage: PropTypes.func,
    mode: PropTypes.string
}

PackageCard.defaultProps = {
    mode: "large"
};

export default PackageCard;
