import React from "react";
import { Field, reduxForm } from "redux-form";

import InputField from "../../../components/formFields/InputField";
import { required, isPasswordValid } from "../../../utils/validation";
import Button from "../../../components/Button";
import { BUTTON_TYPES, INPUT_MODE } from "../../../constants/enums";

const ResetPasswordForm = ({ handleSubmit, onSubmit }) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wrap mt-25">
                <div className="_m12 mv-10">
                    <Field
                        type="password"
                        name="password"
                        label="form.fieldLabel.newPassword"
                        validate={[required, isPasswordValid]}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        type="password"
                        name="confirmPassword"
                        label="form.fieldLabel.repeatNewPassword"
                        validate={required}
                        component={InputField}
                        mode={INPUT_MODE.LIGHT}
                        required
                    />
                </div>
                <div className="_m12 mv-10">
                    <Button
                        type="submit"
                        btnClass={BUTTON_TYPES.FILLED}
                        label="form.button.createNewPassword"
                    />
                </div>
            </div>
        </form>
    )
};

const validate = values => {
    const errors = {}
    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "form.error.unmatchingPassword"
    }
    return errors
}

export default reduxForm({
    form: "ResetPasswordForm",
    validate
})(ResetPasswordForm);