import { GET_SETTINGS, GET_SETTINGS_SUCCESS, GET_SETTING, GET_SETTING_SUCCESS } from "../actions/settingsActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTINGS:
            return { data: null, meta: null, isLoading: true };
        case GET_SETTINGS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTING:
            return { data: null, meta: null, isLoading: true };
        case GET_SETTING_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

export {
    settingsReducer,
    settingReducer,
};