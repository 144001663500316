import React from 'react'
import { Field, reduxForm } from "redux-form";
import { useTranslate } from "react-polyglot";
import ModalForm from "../../../../components/ModalForm";

import InputField from "../../../../components/formFields/InputField";
import SelectFieldRelationship from "../../../../components/formFields/SelectFieldRelationship";

import { required } from "../../../../utils/validation";
import ENTITY from "../../../../constants/entities";
import { isAccessType } from '../../../../utils/validation';
import TextAreaField from '../../../../components/formFields/TextAreaField';

const ACCESS_ID = 2;

const ServiceItemForm = ({
    title,
    change,
    handleSubmit,
    submitLabel,
    onSubmit,
    handleCancel,
    defaultLanguage
}) => {

    const translate = useTranslate();

    return (
        <ModalForm
            open
            title={title}
            handleSubmit={handleSubmit(onSubmit)}
            handleCancel={handleCancel}
            submitLabel={submitLabel}
            cancelLabel="form.button.cancel"
        >
            <div className="wrap service-item-form">
                <div className="_m4 mv-10">
                    <Field
                        name="itemType.id"
                        label="form.fieldLabel.serviceType"
                        onSelect={change}
                        validate={required}
                        entityType={ENTITY.SERVICE_PLAN_ITEM_TYPE}
                        component={SelectFieldRelationship}
                        required
                    />
                </div>
                <div className="_m4 mv-10">
                    <Field
                        type="text"
                        name="name"
                        label="form.fieldLabel.name"
                        component={InputField}
                        validate={required}
                    />
                </div>
                <div className="_m4 mv-10">
                    <Field
                        type="text"
                        name="value"
                        label="form.fieldLabel.value"
                        component={InputField}
                        validate={required}
                    />
                </div>
                <div className="_m12 mv-10">
                    <Field
                        type="text"
                        name="description"
                        label="form.fieldLabel.description"
                        component={TextAreaField}
                    />
                </div>
                {defaultLanguage &&
                    <div className="_m12 mv-10">
                        <p className="default">{translate('general.defaultLanguage')}<span className="value">{`${defaultLanguage?.value}`}</span></p>
                    </div>
                }
            </div>
        </ModalForm>
    )
}

const validate = (values, props) => {
    const errors = {};

    if (values["itemType"]) {

        if (Number(values["itemType"]["id"]) === ACCESS_ID) {
            errors.value = isAccessType(values.value);
        }
    }

    return errors;
}

export default reduxForm({ form: "ServiceItemForm", enableReinitialize: true, validate })(ServiceItemForm);
