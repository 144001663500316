import React from "react"
import moment from "moment";

const CalendarCells = ({
    currentMonth,
    selectedDate,
    setSelectedDate,
}) => {
    const rows = [];

    const monthStart = moment(currentMonth).startOf("month");
    const monthEnd = moment(monthStart).endOf("month");

    const startDate = moment(monthStart).startOf('isoWeek');
    const endDate = moment(monthEnd).endOf('isoWeek');

    let days = [];
    let day = startDate;
    let formattedDate = "";

    const dayFormat = "D";
    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            formattedDate = moment(day).format(dayFormat);
            const cloneDay = day;
            days.push(
                <div
                    className={`col cell ${
                        !moment(day).isSame(monthStart, "month")
                            ? "disabled"
                            : moment(day).isSame(selectedDate, "day") ? "selected" : ""
                        }`}
                    key={day}
                    onClick={() => setSelectedDate(moment(cloneDay))
                    }
                >
                    <span className="number">{formattedDate}</span>
                </div>
            );
            day = moment(day).add(1, "day");
        }
        rows.push(
            <div className="row" key={day}>
                {days}
            </div>
        );
        days = [];
    }

    return <div className="body">{rows}</div>;
}

export default CalendarCells;
