/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

import CalendarPicker from '../Calendar/CalendarPicker';
import Icon from '../Icon';

import moment from 'moment';
import { DEFAULT_MOMENT_FORMATS } from '../../services/moment.service';
import { CALENDAR_YEARS_PERIOD, INPUT_MODE } from '../../constants/enums';
import { isEmptyOrWhiteSpaces } from '../../utils/checkValue';
import TimePicker from './TimePicker';

const DateTimePicker = ({
    input,
    label,
    err,
    mode,
    required,
    yearsPeriod,
    meta: { error, warning, touched }
}) => {

    const hasError = err || (touched && error);
    const translate = useTranslate();

    const hocRef = useRef(null);
    const [open, setOpen] = useState(false);

    const [dateLabel, setDateLabel] = useState(translate(label));

    useEffect(() => {
        if (input.value) {
            const newLabel = formatDateLabel(input.value);
            setDateLabel(newLabel);
        }
    }, [input.value])

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (hocRef && hocRef.current && !hocRef.current.contains(event.target)) {
            setOpen(false);
        }
    }

    const formatDateLabel = (value) => {
        return moment.utc(value).format(DEFAULT_MOMENT_FORMATS.DATETIME);
    }

    return (
        <div ref={hocRef} className="a-input-group-date">
            <div className="a-input-group" onClick={() => setOpen(!open)}>
                <div className={`a-input -datetime -${mode} ${!isEmptyOrWhiteSpaces(input.value) ? "-filled" : ""} ${open ? "-active" : ""} ${hasError ? "-error" : ""}`} onClick={setOpen}>
                    <span className="datetime-value">{`${dateLabel}h`}</span>
                </div>
                <label className={`-${mode} ${required ? "-required" : ""}`}>{translate(label)}</label>
                <div className="a-input-icon"><Icon name="calendar" color={open ? "#ea68a8" : "#615F7C"} /></div>
                {hasError && <span className="a-input-error">{translate(err || error)}</span>}
            </div>
            {open &&
                <div className="date-time-picker">
                    <CalendarPicker
                        input={input}
                        label={label}
                        display={open}
                        yearsPeriod={yearsPeriod}
                    />
                    <span className="a-line -mini"></span>
                    <TimePicker input={input} />
                </div>
            }
        </div>
    )
}

DateTimePicker.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    err: PropTypes.string,
    meta: PropTypes.object,
    mode: PropTypes.oneOf([
        INPUT_MODE.DARK,
        INPUT_MODE.LIGHT
    ]),
    yearsPeriod: PropTypes.oneOf([
        CALENDAR_YEARS_PERIOD.NEXT,
        CALENDAR_YEARS_PERIOD.PREVIOUS
    ]),
    required: PropTypes.bool
}

DateTimePicker.defaultProps = {
    input: {},
    meta: {},
    label: 'general.selectDateAndTime',
    err: "",
    mode: INPUT_MODE.DARK,
    required: false,
    yearsPeriod: CALENDAR_YEARS_PERIOD.NEXT
}

export default DateTimePicker;
