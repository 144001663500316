import { GET_DISCOUNTS, GET_DISCOUNTS_SUCCESS, GET_DISCOUNT, GET_DISCOUNT_SUCCESS } from "../actions/discountActions"

const initialState = {
    data: null,
    meta: null,
    isLoading: false
};

const discountsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DISCOUNTS:
            return { data: null, meta: null, isLoading: true };
        case GET_DISCOUNTS_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

const discountReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DISCOUNT:
            return { data: null, meta: null, isLoading: true };
        case GET_DISCOUNT_SUCCESS:
            return { ...action.data, isLoading: false };
        default:
            return state;
    }
};

export {
    discountsReducer,
    discountReducer
};