import React, { useEffect } from "react"
import { connect } from "react-redux";
import { getSettingsItemAction, editSettingAction } from "../../../../redux/actions/settingsActions"
import { getItemSelector } from "../../../../utils/selectors";

import SettingsForm from "../SettingsForm";

const EditSettings = ({
    getSettingsItemAction,
    editSettingAction,
    initialValues,
    tableParams,
    match,
    history
}) => {

    useEffect(() => {
        getSettingsItemAction(match.params.id);
    }, [getSettingsItemAction, match.params.id]);

    const onSubmit = data => {
        editSettingAction(data, tableParams);
        history.goBack();
    };

    const handleCancel = () => {
        history.goBack();
    };

    if (!initialValues) return null;

    return (
        <SettingsForm
            title="general.editSettings"
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitLabel="general.editSettings"
            handleCancel={handleCancel}
        />
    )
}

const mapStateToProps = state => ({
    initialValues: getItemSelector(state.settingsItem),
    tableParams: state.tableParams,
});

const mapDispatchToProps = {
    getSettingsItemAction,
    editSettingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSettings);