import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from "react-polyglot";
import ButtonGroup from "../../../components/ButtonGroup";
import AdditionalOption from './AdditionalOption';
import { MONTHLY_PRICELIST_TYPE_ID } from '../../../constants/constants';

const AdditionalOptions = ({ additions, options, option, setOption, addToShoppingCart, disabled }) => {
    const translate = useTranslate();

    if (additions.length < 1) return null;

    const getIsDisabled = (optionId) => disabled && optionId === MONTHLY_PRICELIST_TYPE_ID;

    return (
        <Fragment>
            <div className="wrap">
                <div className="_l12 content-container-header">
                    <p className="additional-options-title">{translate('general.additionalOptions')}</p>
                </div>

                <div className="_m12 content-container-header">
                    <ButtonGroup 
                        getIsDisabled={getIsDisabled}
                        options={options}
                        selectedOption={option || options[0]} 
                        setOption={setOption}
                        btnGroupClass="mini"  />
                </div>
            </div>
            <div className="wrap">
                {additions.map(item => <AdditionalOption key={item.id} addition={item} addToShoppingCart={addToShoppingCart} filter={option || options[0]} />)}
            </div>
        </Fragment>
    )
}

AdditionalOptions.propTypes = {
    additions: PropTypes.array.isRequired,
    addToShoppingCart: PropTypes.func.isRequired
}

export default AdditionalOptions
