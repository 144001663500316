import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getUserToken } from "../../services/localStorage.service";
import LayoutNoAuth from "../LayoutNoAuth";

const NoAuthRoute = ({ path, component }) => {

    const token = getUserToken();

    if (token) {
        return <Redirect to="/" />
    }
    
    return (
        <LayoutNoAuth>
            <Route exact path={path} component={component} />
        </LayoutNoAuth>
    )
}

export default NoAuthRoute;