/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import axiosClient, { POST } from '../../utils/axiosClient';
import { useTranslate } from "react-polyglot";
import ENTITY from '../../constants/entities';
import { FILE_TYPES, IMAGE_TYPES } from '../../constants/enums';
import Image from '../Image';
import Icon from '../Icon';

const FileUploadDnD = ({ input, onImageChange, imageClass, type }) => {
    const translate = useTranslate();

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData();
        const acceptedData = acceptedFiles[0];
        formData.append('file', acceptedData);
        axiosClient(POST, '/files', formData).then(({ data }) => {
            const { id, attributes } = data.data;
            setImagePath(attributes.path);
            onImageChange(input.name, { id, type: ENTITY.FILE });
            setFile(acceptedData.name);
        }).catch(err => {
            console.error(err)
        })
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const [imagePath, setImagePath] = useState(input.value ? input.value.path : null);
    const [file, setFile] = useState(input.value ? input.value.name : null);

    return (
        <div className="m-image-drag-n-drop">
            <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                    { type === FILE_TYPES.IMAGE ?
                        <Image image={imagePath} imageClass={imageClass} /> :
                        <div className="file">
                            <Icon name="file-upload" />
                            {file}
                        </div>    
                    }
                </div>
                <div className="text">
                    <p>{translate(type === FILE_TYPES.IMAGE ? 'general.dndAddPhoto' : 'general.dndAddFile')}</p>
                    <span>{translate('general.browsePhoto')}</span>
                </div>
            </div>
        </div>
    )
}

FileUploadDnD.propTypes = {
    onImageChange: PropTypes.func,
    imageClass: PropTypes.oneOf([
        IMAGE_TYPES.REGULAR,
        IMAGE_TYPES.AVATAR,
        IMAGE_TYPES.USER_UPLOAD
    ]),
    type: PropTypes.oneOf([
        FILE_TYPES.IMAGE,
        FILE_TYPES.FILE
    ])
}

FileUploadDnD.defaultProps = {
    imageClass: IMAGE_TYPES.REGULAR,
    type: FILE_TYPES.FILE,
};

export default FileUploadDnD
