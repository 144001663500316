import React from 'react'
import { useTranslate } from 'react-polyglot';
import PropTypes from 'prop-types';

const ButtonGroup = ({ options, selectedOption, setOption, btnGroupClass, getIsDisabled }) => {

    const translate = useTranslate();
    
    if (options.length < 1) return null;
    return (
        <div className={`m-button-group -${btnGroupClass}`}>
            {options.map(option => (
                <div key={option.id}>
                    <input type="radio"
                        name="btn-group"
                        id={option.id}
                        value={option.id}
                        defaultChecked={selectedOption?.id === option.id}
                        onChange={() => setOption(option)}
                        disabled={getIsDisabled ? getIsDisabled(option.id) : false}
                         />
                    <label htmlFor={option.id}>
                        {translate(`${option.type}.${option.id}.name`).toLowerCase()}
                        {btnGroupClass === "regular" && <span>{option.priceCurrency}</span>}
                    </label>
                </div>
            ))}
        </div>
    )
}

ButtonGroup.propTypes = {
    options: PropTypes.array.isRequired,
    selectedOption: PropTypes.object,
    setOption: PropTypes.func.isRequired,
    btnGroupClass: PropTypes.oneOf([
        "regular",
        "mini"
    ])
}

ButtonGroup.defaultProps = {
    btnGroupClass: "regular"
};

export default ButtonGroup
